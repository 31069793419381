/**
 * @file Gigya RaaS SDK
 * @version 1.0.0
 * @since 1.0.0
 * @module SDK
 * @namespace SDK
 * @description SDK variables
 * @see {@link}
 * @date 2025-01-21T16:24:04.082Z
 */

// Environment
var _aena_gig_raas_environment = "pre";

// 0. Build constants
var _aena_gig_sdk_version = "1.0.0";
var _aena_gig_sdk_build = "1";
var _aena_gig_sdk_buildDate = "2021-09-28 10:00:00";

// 1. Git and date constants
var _aena_gig_raas_generationDate = "2025-01-21T16:24:04.082Z";
var _aena_gig_raas_lastCommitHash = "5e7debf37e7683a7cd220f2d73d1c546c6ef6e5e";
var _aena_gig_raas_lastCommitMessage = "BUILD PRE 21/01/2025";

// 2. Gigya RaaS SDK Constants
var _aena_gig_raas_loginDomain = 'usuarios.aena.es';
var _aena_gig_raas_applicationDomain = _aena_gig_raas_environment === 'prod' ? _aena_gig_raas_loginDomain : `${_aena_gig_raas_environment}-${_aena_gig_raas_loginDomain}`;
var _aena_gig_raas_loginUrl = `https://${_aena_gig_raas_applicationDomain}/`;
var _aena_gig_raas_applicationLog = true;
/**
 * @file Gigya RaaS SDK
 * @version 1.0.0
 * @since 1.0.0
 * @module SDK
 * @namespace SDK
 * @description SDK variables
 * @see {@link}
 * @date 2025-01-21T16:24:04.082Z
 */

// Environment
var _aena_gig_raas_environment = "pre";

// 0. Build constants
var _aena_gig_sdk_version = "1.0.0";
var _aena_gig_sdk_build = "1";
var _aena_gig_sdk_buildDate = "2021-09-28 10:00:00";

// 1. Git and date constants
var _aena_gig_raas_generationDate = "2025-01-21T16:24:04.082Z";
var _aena_gig_raas_lastCommitHash = "5e7debf37e7683a7cd220f2d73d1c546c6ef6e5e";
var _aena_gig_raas_lastCommitMessage = "BUILD PRE 21/01/2025";

// 2. Gigya RaaS SDK Constants
var _aena_gig_raas_loginDomain = 'usuarios.aena.es';
var _aena_gig_raas_applicationDomain = _aena_gig_raas_environment === 'prod' ? _aena_gig_raas_loginDomain : `${_aena_gig_raas_environment}-${_aena_gig_raas_loginDomain}`;
var _aena_gig_raas_loginUrl = `https://${_aena_gig_raas_applicationDomain}/`;
var _aena_gig_raas_applicationLog = true;

// 3. Clubcliente default urls
var _aena_clubcliente_url = _aena_gig_raas_environment === 'prod' ? 'https://clubcliente.aena.es/' : _aena_gig_raas_environment === 'pre' ? 'https://clubclienteqa.aena-aeropuertos.es/' : 'https://clubclientedev.aena-aeropuertos.es/';

// -- 4. Logs Configuration
var showLog = _aena_gig_raas_environment === 'prod' ? false : true;
var showEventLog = _aena_gig_raas_environment === 'prod' ? false : true;

const DOWNLOAD_REPORT_TEMPLATE_FILE_NAME = "DOWNLOAD_REPORT_TEMPLATE_FILE";
const DOWNLOAD_REPORT_TEMPLATE_FILE_CONSTANTS = {
  DOWNLOAD_REPORT_TEMPLATE_FILE_ES: {
    created: "Creado",
    lastLogin: "Último inicio de sesión",
    lastUpdated: "Última actualización",
    registered: "Registrado",
    verified: "Verificado",
    firstName: "Nombre",
    lastName: "Apellidos",
    nickname: "Alias",
    age: "Edad",
    birthDay: "Día de nacimiento",
    birthMonth: "Mes de nacimiento",
    birthYear: "Año de nacimiento",
    city: "Ciudad",
    country: "País",
    email: "Correo electrónico",
    gender: "Género",
    locale: "Localidad",
    state: "Provincia",
    zip: "Código postal",
    tipoTarjeta: "Tipo de tarjeta",
    service_AenaClub: "Servicio Aena Club",
    service_AresParking: "Servicio Parking",
    service_GSV: "Servicio Gestión de Servicios VIP",
    service_ComunidadAeroportuaria: "Servicio Comunidad Aeroportuaria",
    service_ShopToFly: "Servicio Shop to Fly",
    service_ASPA_ACC: "Servicio Aena Club - App de Aena",
    service_ASPA_PARKING: "Servicio Parking - App de Aena",
    service_ASPA_VIP: "Servicios VIP - App de Aena",
    service_ServiciosVIP: "Servicios VIP",
    service_TravelTurism: "Servicio Aena Travel",
    service_WIFI: "Servicio Wifi",
    service_VentaPublicaciones: "Servicio Venta de Publicaciones",
    service_FoodToFly: "Servicio Food to Fly",
    service_AenaTravel: "Servicio Aena Travel",
    inicioRelacion_AenaClub: "Inicio de relación Aena Club",
    inicioRelacion_AresParking: "Inicio de relación Parking",
    inicioRelacion_GSV: "Inicio de relación Gestión de Servicios VIP",
    inicioRelacion_ComunidadAeroportuaria:
      "Inicio de relación Comunidad Aeroportuaria",
    inicioRelacion_ShopToFly: "Inicio de relación Shop To Fly",
    inicioRelacion_ASPA_ACC: "Inicio de relación Aena Club - App de Aena",
    inicioRelacion_ASPA_PARKING: "Inicio de relación Parking - App de Aena",
    inicioRelacion_ASPA_VIP: "Inicio de relación Servicios VIP - App de Aena",
    inicioRelacion_ServiciosVIP: "Inicio de relación Servicios VIP",
    inicioRelacion_WIFI: "Inicio de relación Wifi",
    inicioRelacion_VentaPublicaciones:
      "Inicio de relación Venta de Publicaciones",
    inicioRelacion_FoodToFly: "Inicio de relación Food To Fly",
    inicioRelacion_AenaTravel: "Inicio de relación Aena Travel",
    phones_number: "Teléfono móvil",
    phones_prefix: "Prefijo",
    vehicles: "Vehículo",
    color: "Color",
    carRegistration: "Matrícula",
    model: "Modelo",
    vehicles_id: "ID Vehíchulo",
    brand: "Marca",
    ECOLabel: "Etiqueta ECO",
    isMainVehicle: "Vehículo principal",
    PPM: "Pago Por Matrícula (PPM)",
    communications_Terceros_isConsentGranted:
      "Consentimiento de comunicaciones de terceros aceptado",
    communications_Terceros_lastConsentModified:
      "Consentimiento de comunicaciones de terceros - Última modificación",
    privacy_Aena_isConsentGranted: "Política de privacidad de Aena aceptada",
    privacy_Aena_lastConsentModified:
      "Política de privacidad de Aena - Última modificación",
    communications_Parking_isConsentGranted:
      "Consentimiento de comunicaciones de Parking aceptado",
    communications_Parking_lastConsentModified:
      "Consentimiento de comunicaciones de Parking - Última modificación",
    communications_VIP_isConsentGranted:
      "Consentimiento de comunicaciones de Servicios VIP aceptado",
    communications_VIP_lastConsentModified:
      "Consentimiento de comunicaciones de Servicios VIP - Última modificación",
    terms_AenaClub_isConsentGranted:
      "Términos y condiciones de Aena Club aceptados",
    terms_AenaClub_lastConsentModified:
      "Términos y condiciones de Aena Club - Última modificación",
    terms_Wifi_isConsentGranted:
      "Términos y condiciones del servicio Wifi aceptados",
    terms_Wifi_lastConsentModified:
      "Términos y condiciones del servicio Wifi - Última modificación",
    communications_MarketPlaces_isConsentGranted:
      "Consentimiento de comunicaciones de Marketplaces aceptado",
    communications_MarketPlaces_lastConsentModified:
      "Consentimiento de comunicaciones de Marketplaces - Última modificación",
    communications_AenaClub_isConsentGranted:
      "Consentimiento de comunicaciones de Aena Club aceptado",
    communications_AenaClub_lastConsentModified:
      "Consentimiento de comunicaciones de Aena Club - Última modificación",
    true: "Sí",
    false: "No",
    addresses: "Dirección",
    zipCode: "Código postal",
    address: "Dirección",
    address_id:"Alias",
    country: "País",
    city: "Localidad",
    docNumber: "Número de documento",
    phone: "Teléfono fijo o móvil",
    province: "Provincia",
    prefix: "Prefijo",
    docType: "Tipo de documento",
    fullName: "Nombre o razón social",
    isMainAddress: "Dirección principal",
    docID_number: "Número de documento",
    docID_type: "Tipo de documento",
    terms_FoodToFly_isConsentGranted:	"Términos y condiciones de Food To Fly aceptados",
    terms_FoodToFly_lastConsentModified:	"Términos y condiciones de Food To Fly - Última modificación",
    terms_ShopToFly_isConsentGranted:	"Términos y condiciones de Shop To Fly aceptados",
    terms_ShopToFly_lastConsentModified:	"Términos y condiciones de Shop To Fly - Última modificación",
    terms_AenaTravel_isConsentGranted:	"Términos y condiciones de Aena Travel aceptados",
    terms_AenaTravel_lastConsentModified:	"Términos y condiciones de Aena Travel - Última modificación",
    terms_ServiciosVIP_isConsentGranted:	"Términos y condiciones de Servicios VIP aceptados",
    terms_ServiciosVIP_lastConsentModified:	"Términos y condiciones de Servicios VIP - Última modificación",
    terms_AresParking_isConsentGranted:	"Términos y condiciones de Parking aceptados",
    terms_AresParking_lastConsentModified:	"Términos y condiciones de Parking - Última modificación"
  },
  DOWNLOAD_REPORT_TEMPLATE_FILE_CA: {
    created: "Creat",
    lastLogin: "Últim inici de sessió",
    lastUpdated: "Última actualització",
    registered: "Registrat",
    verified: "Verificat",
    firstName: "Nom",
    lastName: "Cognoms",
    nickname: "Àlies",
    age: "Edat",
    birthDay: "Dia de naixement",
    birthMonth: "Mes de naixement",
    birthYear: "Any de naixement",
    city: "Ciutat",
    country: "País",
    email: "Correu electrònic",
    gender: "Gènere",
    locale: "Localitat",
    state: "Província",
    zip: "Codi postal",
    tipoTarjeta: "Tipus de targeta",
    service_AenaClub: "Servei Aena Club",
    service_AresParking: "Servei Pàrquing",
    service_GSV: "Servei Gestió de Serveis VIP",
    service_ComunidadAeroportuaria: "Servei Comunitat Aeroportuària",
    service_ShopToFly: "Servei Shop to Fly",
    service_ASPA_ACC: "Servei Aena Club - App d’Aena",
    service_ASPA_PARKING: "Servei Pàrquing - App d’Aena",
    service_ASPA_VIP: "Serveis VIP - App d’Aena",
    service_ServiciosVIP: "Serveis VIP",
    service_TravelTurism: "Servei Aena Travel",
    service_WIFI: "Servei Wifi",
    service_VentaPublicaciones: "Servei Venda de Publicacions",
    service_FoodToFly: "Servei Food to Fly",
    service_AenaTravel: "Servei Aena Travel",
    inicioRelacion_AenaClub: "Inici de relació Aena Club",
    inicioRelacion_AresParking: "Inici de relació Pàrquing",
    inicioRelacion_GSV: "Inici de relació Gestió de Serveis VIP",
    inicioRelacion_ComunidadAeroportuaria:
      "Inici de relació Comunitat Aeroportuària",
    inicioRelacion_ShopToFly: "Inici de relació Shop To Fly",
    inicioRelacion_ASPA_ACC: "Inici de relació Aena Club - App d’Aena",
    inicioRelacion_ASPA_PARKING: "Inici de relació Pàrquing - App d’Aena",
    inicioRelacion_ASPA_VIP: "Inici de relació Serveis VIP - App d’Aena",
    inicioRelacion_ServiciosVIP: "Inici de relació Serveis VIP",
    inicioRelacion_WIFI: "Inici de relació Wifi",
    inicioRelacion_VentaPublicaciones: "Inici de relació Venda de Publicacions",
    inicioRelacion_FoodToFly: "Inici de relació Food To Fly",
    inicioRelacion_AenaTravel: "Inici de relació Aena Travel",
    phones_number: "Telèfon mòbil",
    phones_prefix: "Prefix",
    vehicles: "Vehicle",
    color: "Color",
    carRegistration: "Matrícula",
    model: "Model",
    vehicles_id: "ID Vehicle",
    brand: "Marca",
    ECOLabel: "Etiqueta ECO",
    isMainVehicle: "Vehicle principal",
    PPM: "Pagament Per Matrícula (PPM)",
    communications_Terceros_isConsentGranted:
      "Consentiment de comunicacions de tercers acceptat",
    communications_Terceros_lastConsentModified:
      "Consentiment de comunicacions de tercers - Última modificació",
    privacy_Aena_isConsentGranted: "Política de privadesa d’Aena acceptada",
    privacy_Aena_lastConsentModified:
      "Política de privadesa d’Aena - Última modificació",
    communications_Parking_isConsentGranted:
      "Consentiment de comunicacions de Pàrquing acceptat",
    communications_Parking_lastConsentModified:
      "Consentiment de comunicacions de Pàrquing - Última modificació",
    communications_VIP_isConsentGranted:
      "Consentiment de comunicacions de serveis VIP acceptat",
    communications_VIP_lastConsentModified:
      "Consentiment de comunicacions de serveis VIP - Última modificació",
    terms_AenaClub_isConsentGranted:
      "Termes i condicions d’Aena Club acceptats",
    terms_AenaClub_lastConsentModified:
      "Termes i condicions d’Aena Club - Última modificació",
    terms_Wifi_isConsentGranted:
      "Termes i condicions del servei Wifi acceptats",
    terms_Wifi_lastConsentModified:
      "Termes i condicions del servei Wifi - Última modificació",
    communications_MarketPlaces_isConsentGranted:
      "Consentiment de comunicacions de Marketplaces acceptat",
    communications_MarketPlaces_lastConsentModified:
      "Consentiment de comunicacions de Marketplaces - Última modificació",
    communications_AenaClub_isConsentGranted:
      "Consentiment de comunicacions d’Aena Club acceptat",
    communications_AenaClub_lastConsentModified:
      "Consentiment de comunicacions d’Aena Club - Última modificació",
    true: "Sí",
    false: "No",
    addresses: "Adreça",
    zipCode: "Codi postal",
    address: "Adreça",
    address_id:"Àlies",
    country: "País",
    city: "Localitat",
    docNumber: "Nombre document",
    phone: "Telèfon fix o mòbil",
    province:"Província",
    prefix: "Prefix",
    docType: "Tipus de document",
    fullName: "Nom o raó social",
    isMainAddress: "Adreça principal",
    docID_number: "Nombre document",
    docID_type: "Tipus de document",
    terms_FoodToFly_isConsentGranted:"Termes i condicions de Food To Fly acceptats",
    terms_FoodToFly_lastConsentModified:"Termes i condicions de Food To Fly - Última modificació",
    terms_ShopToFly_isConsentGranted:"Termes i condicions de Shop To Fly acceptats",
    terms_ShopToFly_lastConsentModified:"Termes i condicions de Shop To Fly - Última modificació",
    terms_AenaTravel_isConsentGranted:"Termes i condicions d'Aena Travel acceptats",
    terms_AenaTravel_lastConsentModified:"Termes i condicions d'Aena Travel - Última modificació",
    terms_ServiciosVIP_isConsentGranted:"Termes i condicions de Serveis VIP acceptats",
    terms_ServiciosVIP_lastConsentModified:"Termes i condicions de Serveis VIP - Última modificació",
    terms_AresParking_isConsentGranted:"Termes i condicions de Parking acceptats",
    terms_AresParking_lastConsentModified:"Termes i condicions de Parking - Última modificació"

  },
  DOWNLOAD_REPORT_TEMPLATE_FILE_EN: {
    created: "Created",
    lastLogin: "Last login",
    lastUpdated: "Last update",
    registered: "Sign up",
    verified: "Verified",
    firstName: "Name",
    lastName: "Surname(s)",
    nickname: "Alias",
    age: "Age",
    birthDay: "Place of birth",
    birthMonth: "Date of birth",
    birthYear: "Year of birth",
    city: "City",
    country: "Country",
    email: "Email",
    gender: "Gender",
    locale: "Locality",
    state: "Province",
    zip: "Postcode",
    tipoTarjeta: "Card type",
    service_AenaClub: "Aena Club Service",
    service_AresParking: "Parking Service",
    service_GSV: "VIP Service Management Service",
    service_ComunidadAeroportuaria: "Airport Community Service",
    service_ShopToFly: "Shop to Fly Service",
    service_ASPA_ACC: "Aena Club Service - Aena App",
    service_ASPA_PARKING: "Parking Service - Aena App",
    service_ASPA_VIP: "VIP Services - Aena App",
    service_ServiciosVIP: "VIP Services",
    service_TravelTurism: "Aena Travel Service",
    service_WIFI: "WiFi Service",
    service_VentaPublicaciones: "Publications Sales Service",
    service_FoodToFly: "Food to Fly Service",
    service_AenaTravel: "Aena Travel Service",
    inicioRelacion_AenaClub: "Start of Aena Club relationship",
    inicioRelacion_AresParking: "Start of Parking relationship",
    inicioRelacion_GSV: "Start of VIP Services Management relationship",
    inicioRelacion_ComunidadAeroportuaria:
      "Start of Airport Community relationship",
    inicioRelacion_ShopToFly: "Start of Shop To Fly Relationship",
    inicioRelacion_ASPA_ACC: "Start of Aena Club - Aena App relationship",
    inicioRelacion_ASPA_PARKING: "Start of Parking - Aena App relationship",
    inicioRelacion_ASPA_VIP: "Start of VIP Services - Aena App relationship",
    inicioRelacion_ServiciosVIP: "Start of VIP Services relationship",
    inicioRelacion_WIFI: "Start of WiFi relationship",
    inicioRelacion_VentaPublicaciones:
      "Start of Publications Sale relationship",
    inicioRelacion_FoodToFly: "Start of Food To Fly relationship",
    inicioRelacion_AenaTravel: "Start of Aena Travel relationship",
    phones_number: "Mobile no.",
    phones_prefix: "Prefix",
    vehicles: "Vehicle",
    color: "Colour",
    carRegistration: "Number plate",
    model: "Model",
    vehicles_id: "Vehicle ID",
    brand: "Make",
    ECOLabel: "ECO Label",
    isMainVehicle: "Main vehicle",
    PPM: "Payment Per Number plate (PPN)",
    communications_Terceros_isConsentGranted:
      "Third party communications consent accepted",
    communications_Terceros_lastConsentModified:
      "Third party communications consent - Last modified",
    privacy_Aena_isConsentGranted: "Aena Privacy Policy accepted",
    privacy_Aena_lastConsentModified: "Aena Privacy Policy - Last modified",
    communications_Parking_isConsentGranted:
      "Parking communications consent accepted",
    communications_Parking_lastConsentModified:
      "Parking communications consent - Last Modified",
    communications_VIP_isConsentGranted:
      "VIP Services communications consent accepted",
    communications_VIP_lastConsentModified:
      "VIP Services communications consent - Last Modified",
    terms_AenaClub_isConsentGranted: "Aena Club terms and conditions accepted",
    terms_AenaClub_lastConsentModified:
      "Aena Club terms and conditions - Last modified",
    terms_Wifi_isConsentGranted: "WiFi service terms and conditions accepted",
    terms_Wifi_lastConsentModified:
      "WiFi service terms and conditions - Last modified",
    communications_MarketPlaces_isConsentGranted:
      "Marketplaces communications consent accepted",
    communications_MarketPlaces_lastConsentModified:
      "Marketplaces communications consent - Last modified",
    communications_AenaClub_isConsentGranted:
      "Aena Club communications consent accepted",
    communications_AenaClub_lastConsentModified:
      "Aena Club communications consent - Last modified",
    true: "Yes",
    false: "No",
    addresses: "Address",
    zipCode: "Postcode",
    address: "Address",
    address_id:"Alias",
    country: "Country",
    city: "City",
    docNumber: "Document number",
    phone: "Land line or mobile phone",
    province:"Region",
    prefix: "Prefix",
    docType: "Document type",
    fullName: "Name or company name",
    isMainAddress: "Main address",
    docID_number: "Document number",
    docID_type: "Document type",
    terms_FoodToFly_isConsentGranted:"Food To Fly terms and conditions accepted",
    terms_FoodToFly_lastConsentModified:"Food To Fly terms and conditions - Last modified",
    terms_ShopToFly_isConsentGranted:"Shop To Fly terms and conditions accepted",
    terms_ShopToFly_lastConsentModified:"Shop To Fly terms and conditions - Last modified",
    terms_AenaTravel_isConsentGranted:"Aena Travel terms and conditions accepted",
    terms_AenaTravel_lastConsentModified:"Aena Travel terms and conditions - Last modified",
    terms_ServiciosVIP_isConsentGranted:"VIP Services terms and conditions accepted",
    terms_ServiciosVIP_lastConsentModified:"VIP Services terms and conditions - Last modified",
    terms_AresParking_isConsentGranted:"Parking terms and conditions accepted",
    terms_AresParking_lastConsentModified:"Parking terms and conditions - Last modified",

  },
  DOWNLOAD_REPORT_TEMPLATE_FILE_EU: {
    created: "Sortua",
    lastLogin: "Azkeneko saio hasiera",
    lastUpdated: "Azken eguneraketa",
    registered: "Izena eman da",
    verified: "Egiaztatuta",
    firstName: "Izena",
    lastName: "Abizenak",
    nickname: "Ezizena",
    age: "Adina",
    birthDay: "Jaiotza-eguna",
    birthMonth: "Jaiotza-hilabetea",
    birthYear: "Jaiotza-urtea",
    city: "Hiria",
    country: "Herrialdea",
    email: "Helbide elektronikoa",
    gender: "Generoa",
    locale: "Herria",
    state: "Probintzia",
    zip: "Posta-kodea",
    tipoTarjeta: "Txartel mota",
    service_AenaClub: "Aena Club zerbitzua",
    service_AresParking: "Aparkaleku zerbitzua",
    service_GSV: "VIP zerbitzuen kudeaketarako zerbitzua",
    service_ComunidadAeroportuaria: "Aireportuko komunitateko zerbitzua",
    service_ShopToFly: "Shop to Fly zerbitzua",
    service_ASPA_ACC: "Aena Club zerbitzua - Aenaren aplikazioa",
    service_ASPA_PARKING: "Aparkaleku-zerbitzua - Aenaren aplikazioa",
    service_ASPA_VIP: "VIP zerbitzuak - Aenaren aplikazioa",
    service_ServiciosVIP: "VIP zerbitzuak",
    service_TravelTurism: "Aena Travel zerbitzua",
    service_WIFI: "Wifi zerbitzua",
    service_VentaPublicaciones: "Argitalpenen salmentarako zerbitzua",
    service_FoodToFly: "Food to Fly zerbitzua",
    service_AenaTravel: "Aena Travel zerbitzua",
    inicioRelacion_AenaClub: "Harreman hasiera Aena Club",
    inicioRelacion_AresParking: "Harreman hasiera aparkalekua",
    inicioRelacion_GSV: "Harreman hasiera VIP zerbitzuen kudeaketa",
    inicioRelacion_ComunidadAeroportuaria:
      "Harreman hasiera Aireportuko komunitatea",
    inicioRelacion_ShopToFly: "Harreman hasiera Shop To Fly",
    inicioRelacion_ASPA_ACC: "Harreman hasiera Aena Club - Aenaren aplikazioa",
    inicioRelacion_ASPA_PARKING:
      "Harreman hasiera Aparkalekua- Aenaren aplikazioa",
    inicioRelacion_ASPA_VIP:
      "Harreman hasiera VIP zerbitzuak - Aenaren aplikazioa",
    inicioRelacion_ServiciosVIP: "Harreman hasiera VIP zerbitzuak",
    inicioRelacion_WIFI: "Harreman hasiera Wifi",
    inicioRelacion_VentaPublicaciones: "Harreman hasiera Argitalpenen salmenta",
    inicioRelacion_FoodToFly: "Harreman hasiera Food To Fly",
    inicioRelacion_AenaTravel: "Harreman hasiera Aena Travel",
    phones_number: "Telefono mugikorra",
    phones_prefix: "Aurrezenbakia",
    vehicles: "Ibilgailua",
    color: "Kolorea",
    carRegistration: "Matrikula",
    model: "Eredua",
    vehicles_id: "Ibilgailuaren IDa",
    brand: "Marka",
    ECOLabel: "ECO etiketa",
    isMainVehicle: "Ibilgailu nagusia",
    PPM: "Matrikula bidezko ordainketa",
    communications_Terceros_isConsentGranted:
      "Hirugarrenen komunikazioen baimena onartua",
    communications_Terceros_lastConsentModified:
      "Hirugarrenen komunikazioen baimena - Azken aldaketa",
    privacy_Aena_isConsentGranted: "Aenaren pribatutasun-politika onartua",
    privacy_Aena_lastConsentModified:
      "Aenaren pribatutasun politika - Azken aldaketa",
    communications_Parking_isConsentGranted:
      "Aparkaleku-komunikazioetarako baimena onartua",
    communications_Parking_lastConsentModified:
      "Aparkaleku-komunikazioetarako baimena - Azken aldaketa",
    communications_VIP_isConsentGranted:
      "VIP zerbitzuen komunikazioetarako baimena onartua",
    communications_VIP_lastConsentModified:
      "VIP zerbitzuen komunikazioetarako baimena - Azken aldaketa",
    terms_AenaClub_isConsentGranted:
      "Aena Cluben terminoak eta baldintzak onartuak",
    terms_AenaClub_lastConsentModified:
      "Aena Cluben terminoak eta baldintzak - Azken aldaketa",
    terms_Wifi_isConsentGranted:
      "Wifi zerbitzuaren terminoak eta baldintzak onartuak",
    terms_Wifi_lastConsentModified:
      "Wifi zerbitzuaren terminoak eta baldintzak - Azken aldaketa",
    communications_MarketPlaces_isConsentGranted:
      "Marketplaces komunikazioak baimentzea onartua",
    communications_MarketPlaces_lastConsentModified:
      "Marketplaces komunikazioak baimentzea - Azken aldaketa",
    communications_AenaClub_isConsentGranted:
      "Aena Clubaren komunikazioak baimentzea onartua",
    communications_AenaClub_lastConsentModified:
      "Aena Clubaren komunikazioak baimentzea - Azken aldaketa",
    true: "Bai",
    false: "Ez",
    addresses: "Helbidea",
    zipCode: "Posta-kodea",
    address: "Helbidea",
    address_id:"Ezizena",
    country: "Herrialdea",
    city: "Herria",
    docNumber: "Dokumentu kopurua",
    phone: "Telefono finkoa edo mugikorra",
    province:"Probintzia",
    prefix: "Aurrezenbakia",
    docType: "Dokumentu mota",
    fullName: "Izena edo arrazoi soziala",
    isMainAddress: "Helbide nagusia",
    docID_number: "Dokumentu kopurua",
    docID_type: "Dokumentu mota",
    terms_FoodToFly_isConsentGranted:"Food To Fly terminoak eta baldintzak onartuak",
    terms_FoodToFly_lastConsentModified:"Food To Fly terminoak eta baldintzak - Azken aldaketa",
    terms_ShopToFly_isConsentGranted:"Shop To Fly terminoak eta baldintzak onartuak",
    terms_ShopToFly_lastConsentModified:"Shop To Fly terminoak eta baldintzak - Azken aldaketa",
    terms_AenaTravel_isConsentGranted:"Aena Travel terminoak eta baldintzak onartuak",
    terms_AenaTravel_lastConsentModified:"Aena Travel terminoak eta baldintzak - Azken aldaketa",
    terms_ServiciosVIP_isConsentGranted:"VIP Zerbitzuen terminoak eta baldintzak onartuak",
    terms_ServiciosVIP_lastConsentModified:"VIP Zerbitzuen terminoak eta baldintzak - Azken aldaketa",
    terms_AresParking_isConsentGranted:"Aparkalekuaren terminoak eta baldintzak onartuak",
    terms_AresParking_lastConsentModified:"Aparkalekuaren terminoak eta baldintzak - Azken aldaketa"
  },
  DOWNLOAD_REPORT_TEMPLATE_FILE_GL: {
    created: "Creado",
    lastLogin: "Último inicio de sesión",
    lastUpdated: "Última actualización",
    registered: "Rexistrado",
    verified: "Verificado",
    firstName: "Nome",
    lastName: "Apelidos",
    nickname: "Alias",
    age: "Idade",
    birthDay: "Data de nacemento",
    birthMonth: "Mes de nacemento",
    birthYear: "Ano de nacemento",
    city: "Cidade",
    country: "País",
    email: "Correo electrónico",
    gender: "Xénero",
    locale: "Localidade",
    state: "Provincia",
    zip: "Código postal",
    tipoTarjeta: "Tipo de tarxeta",
    service_AenaClub: "Servizo Club Aena",
    service_AresParking: "Servizo Aparcadoiro",
    service_GSV: "Servizo Xestión de Servizos VIP",
    service_ComunidadAeroportuaria: "Servicio Comunidade Aeroportuaria",
    service_ShopToFly: "Servizo Shop to Fly",
    service_ASPA_ACC: "Servizo Aena Club - App de Aena",
    service_ASPA_PARKING: "Servizo Aparcadoiro - App de Aena",
    service_ASPA_VIP: "Servizos VIP - App de Aena",
    service_ServiciosVIP: "Servizos VIP",
    service_TravelTurism: "Servizo Aena Travel",
    service_WIFI: "Servizo Wifi",
    service_VentaPublicaciones: "Servizo Venda de Publicacións",
    service_FoodToFly: "Servizo Food to Fly (Comida para Voar)",
    service_AenaTravel: "Servizo Aena Travel",
    inicioRelacion_AenaClub: "Inicio de relación Aena Club",
    inicioRelacion_AresParking: "Inicio de relación Aparcadoiro",
    inicioRelacion_GSV: "Inicio de relación Xestión do Servizos VIP",
    inicioRelacion_ComunidadAeroportuaria:
      "Inicio de relación Comunidade Aeroportuaria",
    inicioRelacion_ShopToFly: "Inicio de relación Shop To Fly",
    inicioRelacion_ASPA_ACC: "Inicio de relación Aena Club - App de Aena",
    inicioRelacion_ASPA_PARKING: "Inicio de relación Aparcadoiro - App de Aena",
    inicioRelacion_ASPA_VIP: "Inicio de relación Servizos VIP - App de Aena",
    inicioRelacion_ServiciosVIP: "Inicio de relación Servizos VIP",
    inicioRelacion_WIFI: "Inicio de relación Wifi",
    inicioRelacion_VentaPublicaciones:
      "Inicio de relación Venda de Publicacións",
    inicioRelacion_FoodToFly: "Inicio de relación Food To Fly",
    inicioRelacion_AenaTravel: "Inicio de relación de Aena Travel",
    phones_number: "Teléfono móbil",
    phones_prefix: "Prefixo",
    vehicles: "Vehículo",
    color: "Cor",
    carRegistration: "Matrícula",
    model: "Modelo",
    vehicles_id: "ID Vehíchulo",
    brand: "Marca",
    ECOLabel: "Etiqueta ECO",
    isMainVehicle: "Vehículo principal",
    PPM: "Pago Por Matrícula (PPM)",
    communications_Terceros_isConsentGranted:
      "Consentimento de comunicacións de terceiros aceptado",
    communications_Terceros_lastConsentModified:
      "Consentimento de comunicacións de terceiros - Última modificación",
    privacy_Aena_isConsentGranted: "Política de privacidade de Aena aceptada",
    privacy_Aena_lastConsentModified:
      "Política de privacidade de Aena - Última modificación",
    communications_Parking_isConsentGranted:
      "Consentimento de comunicacións de Aparcadoiro aceptado",
    communications_Parking_lastConsentModified:
      "Consentimento de comunicacións de Aparcadoiro - Última modificación",
    communications_VIP_isConsentGranted:
      "Consentimento de comunicacións de Servizos VIP aceptado",
    communications_VIP_lastConsentModified:
      "Consentimiento de comunicacións de Servicios VIP - Última modificación",
    terms_AenaClub_isConsentGranted:
      "Termos e condicións de Aena Club aceptados",
    terms_AenaClub_lastConsentModified:
      "Termos e condicións de Aena Club - Última modificación",
    terms_Wifi_isConsentGranted:
      "Termos e condicións do servizo Wifi aceptados",
    terms_Wifi_lastConsentModified:
      "Termos e condicións do servizo Wifi - Última modificación",
    communications_MarketPlaces_isConsentGranted:
      "Consentimento de comunicacións de Marketplaces aceptado",
    communications_MarketPlaces_lastConsentModified:
      "Consentimento de comunicacións de Marketplaces - Última modificación",
    communications_AenaClub_isConsentGranted:
      "Consentimento de comunicacións de Aena Club aceptado",
    communications_AenaClub_lastConsentModified:
      "Consentimento de comunicacións de Aena Club - Última modificación",
    true: "Sí",
    false: "Non",
    addresses: "Enderezo",
    zipCode: "Código postal",
    address: "Enderezo",
    address_id:"Alias",
    country: "País",
    city: "Localidade",
    docNumber: "Número de documento",
    phone: "Teléfono fixo ou móbil",
    province:"Provincia",
    prefix: "Prefixo",
    docType: "Tipo de documento",
    fullName: "Nome ou razón social",
    isMainAddress: "Enderezo principal",
    docID_number: "Número de documento",
    docID_type: "Tipo de documento",
    terms_FoodToFly_isConsentGranted:"Termos e condicións de Food To Fly aceptados",
    terms_FoodToFly_lastConsentModified:"Termos e condicións de Food To Fly - Última modificación",
    terms_ShopToFly_isConsentGranted:"Termos e condicións de Shop To Fly aceptados",
    terms_ShopToFly_lastConsentModified:"Termos e condicións de Shop To Fly - Última modificación",
    terms_AenaTravel_isConsentGranted:"Termos e condicións de Aena Travel aceptados",
    terms_AenaTravel_lastConsentModified:"Termos e condicións de Aena Travel - Última modificación",
    terms_ServiciosVIP_isConsentGranted:"Termos e condicións de Servizos VIP aceptados",
    terms_ServiciosVIP_lastConsentModified:"Termos e condicións de Servizos VIP - Última modificación",
    terms_AresParking_isConsentGranted:"Termos e condicións de Aparcadoiro aceptados",
    terms_AresParking_lastConsentModified:"Termos e condicións de Aparcadoiro - Última modificación"
  },
  DOWNLOAD_REPORT_TEMPLATE_FILE_VA: {
    created: "Creat",
    lastLogin: "Últim inici de sessió",
    lastUpdated: "Última actualització",
    registered: "Registrat",
    verified: "Verificat",
    firstName: "Nom",
    lastName: "Cognoms",
    nickname: "Àlies",
    age: "Edat",
    birthDay: "Dia de naixement",
    birthMonth: "Mes de naixement",
    birthYear: "Any de naixement",
    city: "Ciutat",
    country: "País",
    email: "Correu electrònic",
    gender: "Gènere",
    locale: "Localitat",
    state: "Província",
    zip: "Codi postal",
    tipoTarjeta: "Tipus de targeta",
    service_AenaClub: "Servici Aena Club",
    service_AresParking: "Servici Pàrquing",
    service_GSV: "Servici Gestió de Servicis VIP",
    service_ComunidadAeroportuaria: "Servici Comunitat Aeroportuària",
    service_ShopToFly: "Servici Shop to Fly",
    service_ASPA_ACC: "Servici Aena Club - App d'Aena",
    service_ASPA_PARKING: "Servici Pàrquing - App d'Aena",
    service_ASPA_VIP: "Servicis VIP - App d'Aena",
    service_ServiciosVIP: "Servicis VIP",
    service_TravelTurism: "Servici Aena Travel",
    service_WIFI: "Servici Wifi",
    service_VentaPublicaciones: "Servici Venda de Publicacions",
    service_FoodToFly: "Servici Food to Fly",
    service_AenaTravel: "Servici Aena Travel",
    inicioRelacion_AenaClub: "Inici de relació Aena Club",
    inicioRelacion_AresParking: "Inici de relació Pàrquing",
    inicioRelacion_GSV: "Inici de relació Gestió de Servicis VIP",
    inicioRelacion_ComunidadAeroportuaria:
      "Inici de relació Comunitat Aeroportuària",
    inicioRelacion_ShopToFly: "Inici de relació Shop To Fly",
    inicioRelacion_ASPA_ACC: "Inici de relació Aena Club - App d'Aena",
    inicioRelacion_ASPA_PARKING: "Inici de relació Pàrquing - App d'Aena",
    inicioRelacion_ASPA_VIP: "Inici de relació Servicis VIP - App d'Aena",
    inicioRelacion_ServiciosVIP: "Inici de relació Servicis VIP",
    inicioRelacion_WIFI: "Inici de relació Wifi",
    inicioRelacion_VentaPublicaciones: "Inici de relació Venda de Publicacions",
    inicioRelacion_FoodToFly: "Inici de relació Food To Fly",
    inicioRelacion_AenaTravel: "Inici de relació Aena Travel",
    phones_number: "Telèfon mòbil",
    phones_prefix: "Prefix",
    vehicles: "Vehicle",
    color: "Color",
    carRegistration: "Matrícula",
    model: "Model",
    vehicles_id: "ID Vehicle",
    brand: "Marca",
    ECOLabel: "Etiqueta ECO",
    isMainVehicle: "Vehicle principal",
    PPM: "Pagament Per Matrícula (PPM)",
    communications_Terceros_isConsentGranted:
      "Consentiment de comunicacions de tercers acceptat",
    communications_Terceros_lastConsentModified:
      "Consentiment de comunicacions de tercers - Última modificació",
    privacy_Aena_isConsentGranted: "Política de privacitat d'Aena acceptada",
    privacy_Aena_lastConsentModified:
      "Política de privacitat d'Aena - Última modificació",
    communications_Parking_isConsentGranted:
      "Consentiment de comunicacions de Pàrquing acceptat",
    communications_Parking_lastConsentModified:
      "Consentiment de comunicacions de Pàrquing - Última modificació",
    communications_VIP_isConsentGranted:
      "Consentiment de comunicacions de Servicis VIP acceptat",
    communications_VIP_lastConsentModified:
      "Consentiment de comunicacions de Servicis VIP - Última modificació",
    terms_AenaClub_isConsentGranted:
      "Termes i condicions d'Aena Club acceptats",
    terms_AenaClub_lastConsentModified:
      "Termes i condicions d'Aena Club - Última modificació",
    terms_Wifi_isConsentGranted:
      "Termes i condicions del servici Wifi acceptats",
    terms_Wifi_lastConsentModified:
      "Termes i condicions del servici Wifi - Última modificació",
    communications_MarketPlaces_isConsentGranted:
      "Consentiment de comunicacions de Marketplaces acceptat",
    communications_MarketPlaces_lastConsentModified:
      "Consentiment de comunicacions de Marketplaces - Última modificació",
    communications_AenaClub_isConsentGranted:
      "Consentiment de comunicacions d'Aena Club acceptat",
    communications_AenaClub_lastConsentModified:
      "Consentiment de comunicacions d'Aena Club - Última modificació",
    true: "Sí",
    false: "No",
    addresses: "Adreça",
    zipCode: "Codi postal",
    address: "Adreça",
    addresses_id:"Àlies",
    country: "País",
    city: "Localitat",
    docNumber: "Número de document",
    phone: "Telèfon fix o mòbil",
    province:"Província",
    prefix: "Prefix",
    docType: "Tipus de document",
    fullName: "Nom o raó social",
    isMainAddress: "Adreça principal",
    docID_number: "Número de document",
    docID_type: "Tipus de document",
    terms_FoodToFly_isConsentGranted:"Termes i condicions de Food To Fly acceptats",
    terms_FoodToFly_lastConsentModified:"Termes i condicions de Food To Fly - Última modificació",
    terms_ShopToFly_isConsentGranted:"Termes i condicions de Shop To Fly acceptats",
    terms_ShopToFly_lastConsentModified:"Termes i condicions de Shop To Fly - Última modificació",
    terms_AenaTravel_isConsentGranted:"Termes i condicions d'Aena Travel acceptats",
    terms_AenaTravel_lastConsentModified:"Termes i condicions d'Aena Travel - Última modificació",
    terms_ServiciosVIP_isConsentGranted:"Termes i condicions de Servicis VIP acceptats",
    terms_ServiciosVIP_lastConsentModified:"Termes i condicions de Servicis VIP - Última modificació",
    terms_AresParking_isConsentGranted:"Termes i condicions de Pàrquing acceptats",
    terms_AresParking_lastConsentModified:"Termes i condicions de Pàrquing - Última modificació",
  },
};

const PROFILE_FIELDS_TO_DELETE = [
  "callId",
  "errorCode",
  "apiVersion",
  "time",
  "registeredTimestamp",
  "UIDSignature",
  "signatureTimestamp",
  "createdTimestamp",
  "lastLoginTimestamp",
  "lastUpdatedTimestamp",
  "loginProvider",
  "locale",
  "oldestDataUpdatedTimestamp",
  "verifiedTimestamp",
  "requestParams",
  "operation",
  "status",
  "errorMessage",
  "statusMessage",
  "isActive",
  "isRegistered",
  "isVerified",
  "context",
  "emails",
  "loginIDs",
  "oldestDataUpdated",
  "password",
  "socialProviders",
  "subscriptions",
  "UID",
  "profile",
  "data",
  "preferences",
  "statusCode",
  "statusReason",
];

const DATA_FIELDS_TO_DELETE = [
  "bp_number",
  "userType",
  "subscriptionAENA",
  "userCRMRegisterDate",
  "isLogicDeleted",
  "isLogicDeletedDate",
  "aenaCommunicationScreenShownDate",
  "isLiteVerified",
  "service"
];

const PREFERENCE_FIELDS_TO_DELETE = [
  "isConsentRequired",
  "docDate",
  "lang",
  "actionTimestamp",
  "tags",
  "customData",
  "entitlements",
  "locales",
  "docVersion",
  "lang",
  "actionTimestamp",
];
let communicationAenaGigyaName = 'preferences.communications_AenaClub.isConsentGranted';
let communicationWifiGigyaName = 'preferences.communications_WiFi.isConsentGranted'

/** *****************************************************/
//  8. Screensets conditional functions
/** *****************************************************/
/**
 * @deprecated Since JUL 24.
 */
function isConsentRequired(consent, schema) {

    if (schema.preferencesSchema) {
        // Look for the specific field and check if required or not
        for (const [key, value] of Object.entries(schema.preferencesSchema.fields)) {

            // cdcLog(`${key}: ${value}`);

            if (key === consent) {
                // Log the consent 
                // cdcLog(`${key}: ${value}`);
                // cdcLog('consent:', consent);

                return value.required;
            }
        }
    }
    return false;
}
function isProfileFieldRequired(profile, schema) {

    if (schema.profileSchema) {
        // Look for the specific field and check if required or not
        for (const [key, value] of Object.entries(schema.profileSchema.fields)) {

            // cdcLog(`${key}: ${value}`);

            if (key === profile) {
                // Log the consent 
                // cdcLog(`${key}: ${value}`);
                // cdcLog('consent:', consent);

                return value.required;
            }
        }
    }
    return false;
}
function isDataFieldRequired(data, schema) {

    // Look for the specific field and check if required or not
    if (schema.dataSchema && schema.dataSchema.fields) {
        for (const [key, value] of Object.entries(schema.dataSchema.fields)) {

            // cdcLog(`${key}: ${value}`);

            if (key === data) {
                // Log the consent 
                // cdcLog(`${key}: ${value}`);
                // cdcLog('consent:', consent);

                return value.required;
            }
        }
    }
    return false;
}
function isConsentAccepted(consent, preferences) {

    // cdcLog('consent: %o, preferences: %o', consent, preferences);

    // Look for the specific field and check if required or not
    for (const [key, value] of Object.entries(preferences)) {

        // cdcLog(`${key}: ${value}`);

        if (key === consent) {
            // Log the consent 
            // cdcLog(`${key}: ${value}`);
            // cdcLog('consent:', consent);

            return value.isConsentGranted;
        }
    }
}
/**
 * @deprecated Since JUL 24.
 */
function isProfileFieldFilled(profileKeyAsString, profile) {
    

    // Remove the 'profile.' part from the profile parameters
    const profileKey = profileKeyAsString.replace('profile.', '');
    if (profile && profileKey) {
        // Look for the specific field and check if required or not
        for (const [key, value] of Object.entries(profile)) {

            // cdcLog(`${key}: ${value}`);

            if (key === profileKey) {
                // Log the consent 
                // cdcLog(`${key}: ${value}`);
                // cdcLog('consent:', consent);

                return value !== null && value !== '';
            }
        }
    }
    return false;
}
function isDataFieldFilledOld(dataKeyAsString, data) {

    // Remove the 'data.' part from the data parameters
    const dataKey = dataKeyAsString.replace('data.', '');
    if (data && dataKey) {
        // Look for the specific field and check if required or not
        for (const [key, value] of Object.entries(data)) {

            // cdcLog(`${key}: ${value}`);
            if (key === dataKey) {
                // Log the consent
                // cdcLog(`${key}: ${value}`);
                // cdcLog('consent:', consent);

                return value !== null && value !== '';
            }
        }
    }
    return false;
}
function isDataFieldFilled(dataKeyAsString, data) {
    // Remove the 'data.' part from the data parameters
    const dataKeys = dataKeyAsString.replace('data.', '').split('.');

    let currentData = data;

    // Iterate over the properties, moving down the object tree
    for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i];

        // If the current level of data exists and has the key we're looking for, move down
        if (currentData && dataKey in currentData) {
            currentData = currentData[dataKey];
        } else {
            // The key wasn't found, so the field isn't filled
            return false;
        }
    }

    // At the end of the loop, currentData will be the value of the field we're looking for
    return currentData !== null && currentData !== '';
}
function isPhoneFilledAndValid(data) {

    cdcLog('data: %o', data);

    // Check if the phone is filled
    if (data.phones &&
        data.phones.prefix && data.phones.prefix !== null && data.phones.prefix !== '' &&
        data.phones.number && data.phones.number !== null && data.phones.number !== ''
    ) {

        console.log('data.phones.prefix: %o', data.phones.prefix);
        console.log('data.phones.number: %o', data.phones.number);

        // Check if the phone is valid
        const isValidPhone = checkPhoneValidityOnly(data.phones.prefix, data.phones.number);
        return isValidPhone;
    }
}
/**
 * @deprecated Since JUL 24.
 */
function isConsentFieldFilled (consent, schema, preferences) {
    // isConsentFieldFilled('communications_Terceros', schema, preferences)
    if (schema.preferencesSchema && schema.preferencesSchema.fields) {
        for (const [key, value] of Object.entries(schema.preferencesSchema.fields)) {

            // cdcLog(`${key}: ${value}`);

            if (key === consent) {
                // Log the consent 
                // cdcLog(`${key}: %o`, value);
                // cdcLog('consent:', consent);

                // Check the value in the preferences array

                var isFilled = false;
                
                if (preferences[key] && preferences[key].lang && preferences[key].lang !== null)
                    isFilled = true;

                return isFilled;
            }
        }
    }

    return false;
}
/**
 * @deprecated Since JUL 24.
 */
function isConsentFieldUpdated (consent, consentStatements, preferences) {
    // isConsentFieldFilled('communications_Terceros', schema, preferences)
    if (consentStatements) {
        for (const [key, value] of Object.entries(consentStatements)) {

            // cdcLog(`${key}: ${value}`);

            if (key === consent) {
                // Log the consent 
                // cdcLog(`${key}: %o`, value);
                // cdcLog('consent:', consent);

                // Check the value in the preferences array

                var isUpdated = false;

                if (preferences[key] && preferences[key].docDate && preferences[key].docDate !== null)

                    // Log values and log result of the if
                    cdcLog ('[key]: ' + key);
                    cdcLog ('preferences[key].docDate: ' + preferences[key].docDate);
                    cdcLog ('consentStatements[key].currentDocDate: ' + consentStatements[key].currentDocDate);
                    cdcLog ('preferences[key].docDate >= consentStatements[key].currentDocDate: ' + preferences[key].docDate >= consentStatements[key].currentDocDate);

                    if (preferences[key].docDate >= consentStatements[key].currentDocDate) {
                        isUpdated = true;
                    }
                return isUpdated;
            }
        }
    }

    return false;
}

/**
 * @deprecated Since JUL 24.
 */
function areAllMandatoryFiedsFilled(profile, data) {

    

    // Console
    const allFieldsAreFilled = ((profile.firstName !== null && profile.firstName !== "") &&
        (profile.lastName !== null && profile.lastName !== "") &&
        (profile.country !== null && profile.country !== "") &&
        (profile.email !== null && profile.email !== "") &&
        (profile.zip !== null && profile.zip !== "") &&
        (data.phones.prefix !== null && data.phones.prefix !== "") &&
        (data.phones.number !== null && data.phones.number !== ""));
    
    cdcLog('allFieldsAreFilled: ' + allFieldsAreFilled);
 
    return allFieldsAreFilled;
}
function checkIfAllCommunicationsAreChecked(preferences) {
    
    // Check if all communications are checked
    const allCommunicationsAreChecked = (preferences.communications_AenaClub.isConsentGranted &&
        preferences.communications_MarketPlaces.isConsentGranted &&
        preferences.communications_Parking.isConsentGranted &&
        preferences.communications_Terceros.isConsentGranted &&
        preferences.communications_VIP.isConsentGranted);

    return allCommunicationsAreChecked;
}
function checkIfAllCommunicationsAreNotChecked(preferences) {
    
    return !checkIfAllCommunicationsAreChecked(preferences);
}
function checkIfAnyCommnunicationIsChecked(preferences) {

    // Check if all communications are checked
    const anyCommunicationIsChecked = (preferences.communications_AenaClub.isConsentGranted ||
        preferences.communications_MarketPlaces.isConsentGranted ||
        preferences.communications_Parking.isConsentGranted ||
        preferences.communications_Terceros.isConsentGranted ||
        preferences.communications_VIP.isConsentGranted);

    return anyCommunicationIsChecked;
}
/**
 * @deprecated Since JUL 24.
 */
function isConsentRequiredAndAccepted(consent, schema, preferences) {
    return isConsentRequired(consent, schema) && isConsentAccepted(consent, preferences);
}
function isConsentRequiredAndNotAccepted(consent, schema, preferences) {
    const result = isConsentRequired(consent, schema) && !isConsentAccepted(consent, preferences);

    // if (isConsentRequired(consent, schema)) {
        // cdcLog('consent: %o, schema: %o, preferences: %o', consent, schema, preferences);
    // }
    return result;
}
/**
 * @deprecated Since JUL 24.
 */
function isAnyConsentRequiredAndNotAccepted(schema, preferences) {
    return isConsentRequiredAndNotAccepted('terms_AenaClub', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_AenaTravel', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_AresParking', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_FoodToFly', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_ServiciosVIP', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_ShopToFly', schema, preferences) ||
        isConsentRequiredAndNotAccepted('terms_Wifi', schema, preferences);
}
/**
 * @deprecated Since JUL 24.
 */
function isAnyCommunicationSet(preferences) {

    // To detect if communication is set, we check inside the preferences object,
    // in any communication, and look for the 'docDate' field. If set, it is because
    // the user was already registered, therefore, we return false in this method 
    // to avoid showing the communications modal again.

    // Check if all communications are checked
    const anyCommunicationIsSet = (preferences.communications_AenaClub.docDate ||
        preferences.communications_MarketPlaces.docDate ||
        preferences.communications_Parking.docDate ||
        preferences.communications_Terceros.docDate ||
        preferences.communications_VIP.docDate);


    return typeof anyCommunicationIsSet !== 'undefined';
}

/** *****************************************************/
//             9. DOWNLOAD FUNCTIONS
/** *****************************************************/
function downloadUserData() {
    const regTokenFromUrl = getRegTokenFromUrl();
    const params = {
        include: "loginIDs,emails,profile,data,subscriptions,preferences,",
        extraProfileFields: "firstName,lastName,email,samlData,locale",
        regToken: regTokenFromUrl,
        lang: getCurrentLocale(),
        includeCommunications: "all",
        callback: generateDownloadReport
    };
    // Get user data from Gigya
    gigya.accounts.getAccountInfo(params);
  }
  
  function generateDownloadReport(response) {
    const userData = [];
    userData.push(getDownloadUserDataFromResponse(response));
  
    const translation = getDownloadReportTemplateFile(getCurrentLocale());
  
    let flattenedUserData = flattenObject(userData[0]);
  
    // Convert boolean values to strings to prevent XLSX from converting them to 0 and 1
    Object.entries(flattenedUserData).forEach(([key, value]) => {
        // Translate boolean values or "true"/"false" strings in top-level properties
        flattenedUserData[key] = translateBooleanValue(value, translation);
    
        // Process arrays of objects if the value is an array
        if (Array.isArray(value) && value.length > 0) {
            value.forEach((item) => {
                if (typeof item === "object" && item !== null) {
                    // Translate boolean properties within the object
                    translateObjectBooleanValues(item, translation);
                }
            });
        }
    });
  
    const translatedJson = translateDownloadUserDataKeys(flattenedUserData, translation);
  
    // Convert the object to a vertical format: headers in column 1, values in column 2
    const verticalUserData = Object.entries(translatedJson).map(
      ([key, value]) => [key, value]
    );
  
    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(verticalUserData);
  
    // Set the column widths
    worksheet['!cols'] = [
      { wch: Math.max(...verticalUserData.map((row) => row[0].length)) + 1 },
      {
        wch:
          Math.max(...verticalUserData.map((row) => (row[1]?.length ?? 0))) + 1,
      }, // Width for column 2
    ];
  
    // Create a workbook from the data
    const workbook = XLSX.utils.book_new();
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data');
  
    // Generate the file name using the user's email
    const email = flattenedUserData.email;
    const emailParts = email.split('@');
    const fileName = `${emailParts[0]}.xlsx`;
  
    // Download the file with the information
    XLSX.writeFile(workbook, fileName, { compression: false });
  }
  
/**
 * Translates a boolean value or a string representation of "true"/"false" 
 * based on the provided translation dictionary.
 * If the value is not translatable, it returns the original value.
 * 
 * @param {any} value - The value to be translated.
 * @param {Object} translation - Translation dictionary with keys "true" and "false".
 * @returns {any} - The translated value or the original if not applicable.
 */
function translateBooleanValue(value, translation) {
    if (typeof value === 'boolean' || value === "true" || value === "false") {
        return getTranslatedKey(value.toString(), translation);
    }
    return value;
}

/**
 * Iterates over an object's properties and translates boolean values or 
 * "true"/"false" strings based on the provided translation dictionary.
 * 
 * @param {Object} targetObject - The object to process.
 * @param {Object} translation - Translation dictionary with keys "true" and "false".
 */
function translateObjectBooleanValues(targetObject, translation) {
    Object.entries(targetObject).forEach(([propertyKey, propertyValue]) => {
        // Translate the property's value if it's a boolean or translatable string
        targetObject[propertyKey] = translateBooleanValue(propertyValue, translation);
    });
}
  
  /**
   * Translates the keys of a user data object using a translation template.
   * Handles indexed keys by adding the corresponding index to the translated key.
   *
   * @param {Object} userData - The object containing user data to translate.
   * @param {Object} translationTemplate - The template for translating keys.
   * @returns {Object} - The translated user data object.
   */
  function translateDownloadUserDataKeys(userData, translationTemplate, parentKey = '') {
    const translatedUserData = {};
  
    Object.entries(userData).forEach(([key, value]) => {
      let translatedKey = '';
      
      if (
        parentKey.startsWith(getTranslatedKey('vehicles', translationTemplate)) ||
        parentKey.startsWith(getTranslatedKey('addresses', translationTemplate))
      ) {
        // Casos especiales para 'vehicles' y 'addresses'
        if (key === 'id') {
          key = parentKey.startsWith(getTranslatedKey('vehicles', translationTemplate))
            ? 'vehicles_id'
            : 'address_id';
        }
        const translatedField = translationTemplate[key];
        translatedKey = `${parentKey} ${translatedField}`;
      } else {
        translatedKey = getTranslatedKey(
          parentKey ? `${parentKey}_${key}` : key,
          translationTemplate
        );
      }
  
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (isPlainObject(item)) {
            Object.assign(
              translatedUserData,
              translateDownloadUserDataKeys(item, translationTemplate, `${translatedKey} (${index + 1})`)
            );
          }
        });
      } else if (isPlainObject(value)) {
        Object.assign(
          translatedUserData,
          translateDownloadUserDataKeys(value, translationTemplate, translatedKey)
        );
      } else {
        translatedUserData[translatedKey] = value;
      }
    });
  
    return translatedUserData;
  }
  
  /**
   * Gets the translated key based on the translation template.
   * Handles keys with an indexed pattern like 'phones_2_number'.
   *
   * @param {string} key - The key to translate.
   * @param {Object} translationTemplate - The template for translating keys.
   * @returns {string} - The translated key.
   */
  function getTranslatedKey(key, translationTemplate) {
    return translationTemplate[key] || key;
  }
  
  /**
   * Checks if a value is a plain object (not null, not an array).
   *
   * @param {*} value - The value to check.
   * @returns {boolean} - True if the value is a plain object, false otherwise.
   */
  function isPlainObject(value) {
    return (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value) &&
      Object.prototype.toString.call(value) === '[object Object]'
    );
  }
  
  //Get donwload report template constant from current languaje
  function getDownloadReportTemplateFile(locale) {
      const constantName = `${DOWNLOAD_REPORT_TEMPLATE_FILE_NAME}_${locale.toUpperCase()}`;
  
      // Return the value of the constant using the global object
      return DOWNLOAD_REPORT_TEMPLATE_FILE_CONSTANTS[constantName];
  }
  
  // Function to flatten the Object
  function flattenObject(obj, parent = '', delimiter = '_') {
    return Object.keys(obj).reduce((acc, key) => {
      const finalKey = parent ? `${parent}${delimiter}${key}` : key;
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        Object.assign(acc, flattenObject(obj[key], finalKey, delimiter));
      } else {
        acc[finalKey] = obj[key];
      }
      return acc;
    }, {});
  }
  
  function cleanPreferences(preferences) {
    for (const key in preferences) {
      if (preferences.hasOwnProperty(key)) {
        const element = preferences[key];
        if (typeof element === 'object' && element !== null) {
          // Si el elemento es un objeto, llamamos recursivamente
          cleanPreferences(element);
        }
        
        // Eliminar los campos no deseados si existen en el objeto actual
        PREFERENCE_FIELDS_TO_DELETE.forEach((field) => {
          if (element && element.hasOwnProperty(field)) {
            delete element[field];
          }
        });
      }
    }
  }
  
  function getDownloadUserDataFromResponse(response) {
    let userData = JSON.parse(JSON.stringify(response));
  
    cleanPreferences(userData.preferences);
  
    DATA_FIELDS_TO_DELETE.forEach((field) => {
      delete userData.data[field];
    });
  
    userData = {
      ...userData,
      ...userData.profile,
      ...userData.data,
      ...userData.preferences,
    };
  
    PROFILE_FIELDS_TO_DELETE.forEach((field) => {
      delete userData[field];
    });
  
    // Iterate through the entire object, find dates (as strings), and convert them to Date objects
    const convertDates = (obj) => {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const element = obj[key];
          const isDateAsString =
            typeof element === "string" &&
            element.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
          if (isDateAsString) {
            obj[key] = toSpanishDate(element);
          }
  
          if (typeof element === "object" && element !== null) {
            convertDates(element);
          }
        }
      }
    };
  
    convertDates(userData);
    return userData;
  }
  
  function getRegTokenFromUrl() {
      var url = window.location.href;
      var regToken = url.split('?gig_regToken=')[1];
  
      if (regToken) {
          // Remove last character if it is a #
          regToken = regToken.slice(-1) === '#' ? regToken.slice(0, -1) : regToken;
      } else {
          regToken = null;
      }
  
      return regToken;
  }

// New logic for the all communications checkbox
function initializeMasterCheck () {
    // Get all communications checkbox from the active form
    const allCommunicationsCheckbox = document.querySelector('.all-communications-checkbox input[type="checkbox"][id^="gigya"]');
    
        cdcLog("initializeMasterCheck");
    
        // assign a trigger for the click event
        allCommunicationsCheckbox.addEventListener('click', (event) => {
           // Launch communication checks update function
            // checkCommunicationsCheck(event, screen, field, value);
    
            updateAllCommunicationCheckboxes (event.target.checked);
        });
    
        
        // Check if all the checkboxes are checked
        const communicationCheckBoxes = document.querySelectorAll('.gigya-screen[id] form .communications-checkbox input[type="checkbox"][id^="gigya"]');
        const allChecked = Array.from(communicationCheckBoxes).every(checkbox => checkbox.checked);
    
        // If all the checkboxes are checked, check the master checkbox
        if (allChecked) {
            allCommunicationsCheckbox.checked = true;
        }
}
function updateAllCommunicationCheckboxes(checked) {
    cdcLog("update all communication checkboxes to " + checked);

    // Take the current value to set to the checkboxes and set them.
    const communicationCheckBoxes = document.querySelectorAll('.gigya-screen[id] form .communications-checkbox input[type="checkbox"][id^="gigya"]');

        // Update the all communications checkbox
        // If  value is true, set all checkboxes to true
        communicationCheckBoxes.forEach(checkbox => {
            if (checkbox.checked !== checked && 
                (checkbox.dataset.gigyaName != communicationAenaGigyaName &&
                    checkbox.dataset.gigyaName != communicationWifiGigyaName)) {
                checkbox.click();
            }
        });

}
function checkCommunicationsChecks(event, screen, field, value) {

    cdcLog("checkCommunicationsChecks");

    // If fieldname start with "preferences.communication_", we need to check the all communications checkbox
    if (event.field.startsWith("preferences.communications_")) {



    // Get the all communications checkbox
    const allCommunicationsCheckbox = document.querySelector('.all-communications-checkbox input[type="checkbox"][id^="gigya"]');

    // Get all the checkboxes
    const communicationCheckBoxes = document.querySelectorAll('.gigya-screen[id] form .communications-checkbox input[type="checkbox"][id^="gigya"]');


        // If the current checkbox is not the all communications checkbox, check if all the checkboxes are checked
        const allChecked = Array.from(document.querySelectorAll('.gigya-screen[id] form .communications-checkbox input[type="checkbox"][id^="gigya"]')).every(
          (checkbox) =>
            (checkbox.checked && (checkbox.dataset.gigyaName != communicationAenaGigyaName ||
                checkbox.dataset.gigyaName != communicationWifiGigyaName)) ||
            (checkbox.dataset.gigyaName == communicationAenaGigyaName ||
            checkbox.dataset.gigyaName == communicationWifiGigyaName)
        );
       
       

        // If all the checkboxes are checked, check the master checkbox
        if (allChecked) {
            allCommunicationsCheckbox.checked = true;
        } else {
            allCommunicationsCheckbox.checked = false;
        }
    

    } else {
        cdcLog("field does not start with preferences.communication_");
    }
}
/** *****************************************************/
//    11. ADDITIONAL DATA REGISTRATION FUNCTIONS
/** *****************************************************/
function getServiceFieldForSite(originSite) {
    // Const API Keys
    const APIKeys = {
        dev: {
            clubCliente: "4_rln6YGS_tj5QxlvQE_viVQ",
            aspa: "4_Wn4RBHTWAyTQ4vW8IdN-4g",
            foodToFly: "4_OD9UwFMo0rL-56bTz9K2SQ",
            shopToFly: "4_uGjAJenc0--Qj2819OKkQw",
            travel: "4_9403pBNI7Xmtpw9VUXQqPw",
            parking: "4_ZIJU6nGs8WVqO2u5H6rvVg",
            ventaPublicaciones: "4_13_IXQwbLT1Axxpw_eculQ",
            serviciosVIP: "4_-iC_Hy2g1VMKtazVfut4oQ",
            preferencias: "4_fr9F8lzL5hGQrmfIys4Pfw",
            wifi: "4_RuxFY_-17mz3YNE2jQ4N4g",
            parent: "4_YxyopV0TUPBCy9Rwpv8QMg",
        },
        pre: {
            clubCliente: "4_I9gecXnD5Vc1NEXhZcwuHQ",
            aspa: "4_dwvlaJ-a3sWKI6DRhhiBPA",
            foodToFly: "4_PbOJz27gDAD527_i6RvvYA",
            shopToFly: "4_LbHZ2rLfkdZ4bO00JlFm8Q",
            travel: "4_5uBwHAulvy8zTy4y15-ooQ",
            parking: "4_6qrN_Tb8atA8ig6Bks4oUA",
            ventaPublicaciones: "4_4w2ZBI2N25z6U4GoKbL2LA",
            serviciosVIP: "4_FIGRDpghV8vjiXEfDUmDzg",
            preferencias: "4_MsPLMHpqB9Z2Mfi91Yu4Sw",
            wifi: "4_U7KJvNPeIFUIM_wkGGtYbw",
            parent: "4_M0RR-FbWwT_NvUI6LVII7A",
        },
        pro: {
            clubCliente: "4_E_bmMkgvUgLgtSnVBXIy9w",
            aspa: "4_4njJ9DbrhpHaDFJHcM70QQ",
            foodToFly: "4_dQDE-hYyx05Dnidu0SYLUA",
            shopToFly: "4_PNnH3K6Co1ZuVyGlIf38tw",
            travel: "4_dQDE-hYyx05Dnidu0SYLUA",
            parking: "4_6WC22QtsMKBqdYcxv0fTyw",
            ventaPublicaciones: "4_HKQDj9KL8CNyMNMHnQRggw",
            serviciosVIP: "4_QLTD8zfq3xIGfB-u3h_rJw",
            preferencias: "4_9Mt4rfZQDVQ99qFNtaSBVQ",
            wifi: "4_0acIUmA92tsS4wBLcnoWCw",
            parent: "4_K9TEQklgv7rSKFuNDzkV0A",
        }
    };

    let apiKey;
    if (originSite){
        apiKey = APIKeys.pro[originSite];
    }else {
        apiKey = gigya.thisScript.APIKey; // Get API Key
    }

    // Set the service field name in function of the API Key
    let serviceField = null;
    switch (apiKey) {
        case APIKeys.dev.clubCliente:
        case APIKeys.pre.clubCliente:
        case APIKeys.pro.clubCliente:
        case APIKeys.dev.preferencias:
        case APIKeys.pre.preferencias:
        case APIKeys.pro.preferencias:
            serviceField = 'AenaClub';
            break;

        case APIKeys.dev.aspa:
        case APIKeys.pre.aspa:
        case APIKeys.pro.aspa:
            serviceField = 'ASPA_ACC';
            break;

        case APIKeys.dev.foodToFly:
        case APIKeys.pre.foodToFly:
        case APIKeys.pro.foodToFly:
            serviceField = 'FoodToFly';
            break;
        
        case APIKeys.dev.shopToFly:
        case APIKeys.pre.shopToFly:
        case APIKeys.pro.shopToFly:
            serviceField = 'ShopToFly';
            break;

        case APIKeys.dev.travel:
        case APIKeys.pre.travel:
        case APIKeys.pro.travel:
            serviceField = 'AenaTravel';
            break;
        
        case APIKeys.dev.parking:
        case APIKeys.pre.parking:
        case APIKeys.pro.parking:
            serviceField = 'ASPA_PARKING';
            break;
        
        case APIKeys.dev.ventaPublicaciones:
        case APIKeys.pre.ventaPublicaciones:
        case APIKeys.pro.ventaPublicaciones:
            serviceField = 'VentaPublicaciones';
            break;
        
        case APIKeys.dev.serviciosVIP:
        case APIKeys.pre.serviciosVIP:
        case APIKeys.pro.serviciosVIP:
            serviceField = 'ServiciosVIP';
            break;
        
        case APIKeys.dev.wifi:
        case APIKeys.pre.wifi:
        case APIKeys.pro.wifi:
            serviceField = 'WIFI';
            break;
        
        case APIKeys.dev.parent:
        case APIKeys.pre.parent:
        case APIKeys.pro.parent:
            serviceField = 'parent';
            break;
        
        default:
            serviceField = 'unknown';
            break;
    }

    // Log the service field
    cdcLog('Service Field: ' + serviceField);
    return serviceField;

}
function addServiceFieldFieldToFormData(formData, event) {

    const context = event.context != null && event.context.originSite != null ? event.context.originSite : window.gigya.getUrlParam('originSite');
    const serviceField = getServiceFieldForSite(context);

    if(serviceField !== "parent" && serviceField !== "unknown"){
        if (!event.accountInfo.data || !event.accountInfo.data.service || !event.accountInfo.data.service[serviceField]){
            if (formData.data){
                formData.data = {...formData.data, service: {[serviceField] : "true"}}
            }else{
                formData['data']= {service: {[serviceField] : "true"}};
            }
        }
    }
}
function addInicioRelacionFieldToFormData(formData, event) {

    //const context = event.context;
    const context = event.context != null && event.context.originSite != null ? event.context.originSite : window.gigya.getUrlParam('originSite');
    const inicioRelacionField = getServiceFieldForSite(context);

    if(inicioRelacionField !== "parent" && inicioRelacionField !== "unknown"){
        if (!event.accountInfo.data || !event.accountInfo.data.inicioRelacion || !event.accountInfo.data.inicioRelacion[inicioRelacionField]){
            if (formData.data){
                formData.data = {...formData.data, inicioRelacion: {[inicioRelacionField] : new Date().toISOString()}, service: {[inicioRelacionField] : "true"}}
            }else{
                formData['data']= {inicioRelacion: {[inicioRelacionField] : new Date().toISOString()}, service: {[inicioRelacionField] : "true"}};
            }
        }
    }

}
function addSubscriptionAENAFieldToFormData(formData) {

    // Add the subscriptionAENA field to the form data, except for the parent service (when we are in login reg-comp.).
    // With this you control the service field in the form data, therefore if it's not a registered one, the update will fail.
    if (!formData.data.subscriptionAENA) {
        formData.data.subscriptionAENA = {};
    }

    // Create a subscriptionAENA record with basic data
    const subscriptionBasicAENA = {
        "autoRenewal": false,
        "initDate": null,
        "finishDate": null,
        "type": "01"
    };

    // Add the subscriptionAENA field to the form data.
    formData.data.subscriptionAENA = subscriptionBasicAENA;
}
function resendVerificationCode(callback) {

    // Check if we have a reg_token in the local storage
    const regToken = localStorage.getItem("gig_regToken");

    // Callback function to be called when the resend verification code is called
    const callbackWithEmail = function (event) {
        cdcLog("resendVerificationCode callback");
        const emailFromHTML = document.querySelector(".reg-email");
        const email = emailFromHTML ? emailFromHTML.innerHTML : "-";
        const eventWithEmail = Object.assign({}, event, { regEmail: email });
        cdcLog(event, email);

        // Call the callback function
        callback(eventWithEmail);

    }

    // If we have a reg_token, we can call the resend verification code
    if (regToken) {            
        // Call to resend verification code
        gigya.accounts.resendVerificationCode({
            regToken: regToken,
            callback: callbackWithEmail
        });
    } else {
        cdcLog("No regToken found in local storage");
    }
}
function gotoExpiredLinkPage (event) {
    window.location.href = `${_aena_gig_raas_loginUrl}/resetPassword?errorCode=403002&lang=es`;
}

// Report
function toSpanishDate (dateAsString) {
    // Convert the date string to a date object
    const date = new Date(dateAsString);
    const formattedDate = date.toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',      
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return `${formattedDate}`;
}

// Fix image domain for profile update screens
function fixImageDomain(event) {
              
    // Check the domain of the current URL
    const domain = window.location.hostname;

    // Look for the words "pre" or "qa" in the domain
    const isPre = domain.includes("pre-");
    const isQa = domain.includes("qa.");

    const isDev = domain.includes("dev.");
    const isDes = domain.includes("des-");
    let isProd = false;

    // If it's not des or dev or pre or qa, then, we are in production
    if (!isPre && !isQa && !isDev && !isDes) {
        isProd = true;
    }

    // Console log
    cdcLog(`fixImageDomain. Current domain: ${domain}`);

        
    // Get the image element
    const imageElement = document.querySelector(".gigya-screen[id] .gigya-image");

    // If the image URL is not empty
    if (imageElement) {

            // Take the url of the image element 
            let imageElementURL = imageElement.getAttribute('data-src');

            // If the domain is pre or qa
            if (isPre || isQa) {
               
                // Change des-usuarios to pre-usuarios
                imageElementURL = imageElementURL.replace("des-usuarios", "pre-usuarios");

            };
            
            if (isDev || isDes) { 

                // Change pre-usuarios to des-usuarios
                imageElementURL = imageElementURL.replace("pre-usuarios", "des-usuarios");

            }
            if (isProd) {
                // Change des-usuarios to usuarios
                imageElementURL = imageElementURL.replace("des-usuarios", "usuarios");
                imageElementURL = imageElementURL.replace("pre-usuarios", "usuarios");
            }
            
            // Set the image element URL
            imageElement.setAttribute('data-src', imageElementURL);
            const imageElementStyle = imageElement.getAttribute('style');
            imageElement.setAttribute('style', imageElementStyle + " background-image: url('" + imageElementURL + "');");
    }
        
}
function getThisFieldIsRequiredTranslation(message, siteLang) {
    // Lastly, if message is still empty, or null, or undefined, and it's "THIS_FIELD_IS_REQUIRED", try to get it from  gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][siteLang]["this_field_is_required"]
    if (message === "THIS_FIELD_IS_REQUIRED") {
        try {
            message = gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][siteLang]["this_field_is_required"];
            // cdcLog("Getting THIS_FIELD_IS_REQUIRED from gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][siteLang]['this_field_is_required']. SiteLang: " + siteLang);
        }
        catch (e) {
            cdcError("Error trying to get THIS_FIELD_IS_REQUIRED from gigya.i18n['gigya.services.accounts.plugins.screenSet.js'][siteLang]['this_field_is_required']. SiteLang: " + siteLang);
        }
    }
    // As cache from gigya fails, check the error message exit, and if it's 'THIS_FIELD_IS_REQUIRED', then translate it in a manual way
    // TODO - Remove this when cache from gigya works
    if (message === 'THIS_FIELD_IS_REQUIRED') {

        cdcWarn("Cache from gigya failed, translating manually. Current locale: " + siteLang);
        
        // Get the translation for the current language
        if (siteLang === 'es') {
            message = 'Este campo es obligatorio';
        } else if (siteLang === 'en') {
            message = 'This field is required';
        } else if (siteLang === 'cat') {
            message = 'Aquest camp és obligatori';
        } else if (siteLang === 'eu') {
            message = 'Eremu hau nahitaezkoa da';
        } else if (siteLang === 'gl') {
            message = 'Este campo é obrigatorio';
        } else if (siteLang === 'va') {
            message = 'Este camp és obligatori';
        } else if (siteLang === 'fr') {
            message = 'Ce champ est obligatoire';
        } else if (siteLang === 'de') {
            message = 'Pflichtfeld';
        } else if (siteLang === 'zh') {
            message = '必填字段';
        }else if (siteLang === 'zh-cn') {
            message = '必填字段';
        }else {
            message = 'Este campo es obligatorio';
        }
    }

    return message;
}
function getInvalidFieldnameTranslation(message, siteLang) {

    if (message === 'INVALID_FIELDNAME') {

        cdcWarn("Cache from gigya failed, translating manually. Current locale: " + siteLang);
        
        // Get the translation for the current language
        if (siteLang === 'es') {
            message = 'Este valor no es válido';
        } else if (siteLang === 'en') {
            message = 'This value is not valid';
        } else if (siteLang === 'cat') {
            message = 'Aquest valor no és vàlid';
        } else if (siteLang === 'eu') {
            message = '	Balio hau ez da baliozkoa';
        } else if (siteLang === 'gl') {
            message = 'Este valor non é válido';
        } else if (siteLang === 'va') {
            message = 'Este valor no és vàlid';
        } else if (siteLang === 'fr') {
            message = 'Cette valeur n’est pas valable';
        } else if (siteLang === 'de') {
            message = 'Dieser Wert ist nicht gültig';
        } else if (siteLang === 'zh') {
            message = '无效';
        }else if (siteLang === 'zh-cn') {
            message = '无效';
        }else {
            message = 'Este valor no es válido';
        }
    }

    return message;
}
function getMessagesTranslationFor(element, event) {
    // Trying to get the current language from the local storage
    // let siteLang = getCurrentLocale();

    // Get the screen set data and store it in the session storage if it is not already stored
    const screensetID = event.screenSetID;
    // const currentScreensetLanguage = gigya.thisScript.lang && gigya.thisScript.lang.langCode ? gigya.thisScript.lang.langCode : 'en';
    const siteLang = getCurrentLocale();
    const translationsSessionVarName = `_aena_gig_raas_screensets_${screensetID}_translations_${siteLang}`;
    // cdcLog("current gigya locale: " + siteLang);
    

    const languageFromSessionStorage = getObjectFromSessionStorage(translationsSessionVarName);
    const IS_VALID_LANG_FROM_SESSION = languageFromSessionStorage && languageFromSessionStorage[element];
    if (!IS_VALID_LANG_FROM_SESSION) {
        
        // Check if the message is "THIS_FIELD_IS_REQUIRED", and try to translate it
        if (element === "THIS_FIELD_IS_REQUIRED") {
            element = getThisFieldIsRequiredTranslation(element, siteLang);
        }

        // Check if the message is "INVALID_FIELDNAME", and try to translate it
        if (element === "INVALID_FIELDNAME") {
            element = getInvalidFieldnameTranslation(element, siteLang);
        }
        

        return element;
    }

    // Get current language to take the proper translations
    let message = languageFromSessionStorage[element] ? languageFromSessionStorage[element] : element;

    // If message is still empty, or null, or undefined, return element
    if (message === null || message === undefined || message === '') {
        message = element;

        // Check if the message is "THIS_FIELD_IS_REQUIRED", and try to translate it
        if (message === "THIS_FIELD_IS_REQUIRED") {
            message = getThisFieldIsRequiredTranslation(message, siteLang);
        }

        // Check if the message is "INVALID_FIELDNAME", and try to translate it
        if (message === "INVALID_FIELDNAME") {
            message = getInvalidFieldnameTranslation(message, siteLang);
        }

    }

    return message;
}
function getCurrentLocale() {

    // Get the current url
    const currentURL = window.location.href;
    let currentSite = '';

    // If the url contains usuarios.aena.es, current site is usuarios
    if (currentURL.includes('usuarios.aena.es')) {
        currentSite = 'usuarios';
    }

    // If the url contains preferencias.aena.es, current site is preferencias
    if (currentURL.includes('preferencias.aena.es')) {
        currentSite = 'preferencias';
    }

    if (currentURL.includes('freewifi')) {
        currentSite = 'wifi';
    }

    // Else, current site is a commerce site or a java site
    if (currentSite === '') {
        currentSite = 'commerce';
    }

    // cdcLog("currentSite: " + currentSite);
    
    // 1) Check ACC variable
    // ReferenceError: ACC is not defined
    if (typeof ACC === 'undefined') {
        cdcWarn('ACC variable is not defined');
    } else if (ACC && ACC['config.gigya.language']) {
        return ACC['config.gigya.language'];
    }
    // 3) Check from CLP_lang
    var clpLocalStoreLang = getFromLocalStorage ('CLP_lang');
    if (clpLocalStoreLang) {
        return clpLocalStoreLang;
    }

    // 4) Check from LPC_lang
    var lpcLocalStoreLang = getFromLocalStorage ('LPC_lang');
    if (lpcLocalStoreLang) {
        return lpcLocalStoreLang;
    }
    
    // 2) Try get lang from local storage
    var localStoreLang = getFromLocalStorage('_aena_pocs_language');
    if (localStoreLang) {
        return localStoreLang;
    }

    // 3) Check HTML lang attribute(For Parking)
    var htmlLang = document.querySelector('html').getAttribute('lang');
    if (htmlLang) {

        if (currentSite === 'wifi' ) {
            return htmlLang;
        }
        // We are here in Parking case, were we haven't been able to get the language from any other place.
        // This case is ok as well, but we need to manage 2 exceptions, here: 'fr', will be changed to 'es', and 'ga' will be changed to 'gl'
        if (htmlLang === 'fr') {
            return 'es';
        }
        if (htmlLang === 'ga') {
            return 'gl';
        }
        return htmlLang;
    }
    
    // 4) Check browser language
    var browserLang = navigator.language || navigator.userLanguage;
    if (browserLang) {
        return browserLang;
    }
    
    // Console
    cdcWarn('No language found, returning default language: es');
    // If all else fails, return Spanish
    return "es";
}
function initializeHalfSizeFields () {
    // Initialize the half size fields
    var halfSizeFields = document.querySelectorAll('.gigya-screen[id] form .is-half-size');
    for (var i = 0; i < halfSizeFields.length; i++) {
        // Check if the parent has the class 'gigya-visible-with', if so, we need to add a class also to the parent
        if (halfSizeFields[i].parentElement.classList.contains('gigya-visible-when')) {
            halfSizeFields[i].parentElement.classList.add('half-size-parent');
        }
    }
}



function checkIfConfirmationEmailIsFilled(event) {
    const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");

    // Check if it is filled
    if (emailConfirmField && emailConfirmField.value) {
        return true;
    }

    // Add error message
    const errorMessageSpan = emailConfirmField.parentElement.querySelector("span.gigya-error-msg");

    // Get appropiate error message (from translations)
    let errorMessage = getMessagesTranslationFor ('THIS_FIELD_IS_REQUIRED', event);

    errorMessageSpan.innerHTML = errorMessage;
    errorMessageSpan.classList.add("email-confirm-error-message");

    // Add error class to the confirmation email field with setTimeout to avoid the error message to be removed
    setTimeout(() => {
        emailConfirmField.classList.add("gigya-error");
    }, 100);

    return false;
    
}

// This function is for REGISTRATION SCREEN
function checkIfPasswordFieldsAreFilled (event) {


    // Look for the password field
    const passwordField = document.querySelector(".gigya-screen[id] form .gigya-input-password[name='password']");
    const passwordConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-password[name='passwordRetype']");

    const isPasswordFieldEmpty = passwordField.value === '';
    const isPasswordConfirmFieldEmpty = passwordConfirmField.value === '';

    // Get eyes element
    const eyeElement = passwordField.parentElement.querySelector(".eye-password-icon");
    const eyeConfirmElement = passwordConfirmField.parentElement.querySelector(".eye-password-icon");

    // Check if the password is filled
    if (isPasswordFieldEmpty) {        
        eyeElement.classList.add("error-eye");
    } else {
        eyeElement.classList.remove("error-eye");
    }

    // Check if the confirmation password is filled
    if (isPasswordConfirmFieldEmpty) {
        eyeConfirmElement.classList.add("error-eye");
    } else {
        eyeConfirmElement.classList.remove("error-eye");
    }

    // Return if both fields are filled
    return !isPasswordFieldEmpty && !isPasswordConfirmFieldEmpty;
}

// This function is for LOGIN SCREEN
function checkIfPasswordFieldIsFilled (event) {
    
        // Look for the password field
        const passwordField = document.querySelector(".gigya-screen[id] form .gigya-input-password[name='password']");
    
        // Get eyes element
        const eyeElement = passwordField.parentElement.querySelector(".eye-password-icon");
    
        // Check if the password is filled
        if (passwordField.value === '') {
            eyeElement.classList.add("error-eye");
            return false;
        } else {
            eyeElement.classList.remove("error-eye");
            return true;
        }
}

function checkIfNamesAreFilled (event) {
    // Look for the first and last name fields
    const firstNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.firstName']");
    const lastNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.lastName']");

    // Check if they are filled
    if (firstNameField.value === '' || lastNameField.value === '') {
        return false;
    } else {
        return true;
    }
}

function checkIfNamesAreGreaterThan40Chars(event) {
    // Look for the first and last name fields
    const firstNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.firstName']");
    const lastNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.lastName']");

    // Check if they are filled
    if (firstNameField.value.length > 40 || lastNameField.value.length > 40) {
        return false;
    } else {
        return true;
    }

}

function getWifiEnvironment(env){
    const wifiEnvironment = {
        'des':'https://freewifi-dev.aena.es/',
        'pre':'https://freewifi-pre.aena.es/',
        'pro':'https://freewifi.aena.es/'
    }
    return wifiEnvironment[env];
}
/** **************************************************/
//  1. Gigya Registration-Login Event Functions
/** **************************************************/
// Called before a new screen is rendered. This event gives you an opportunity to cancel the navigation by returning false.
/**
 * Event handler function that will be called Called after a new screen is rendered.
 * @param  {object} event Form Event object
 */
function onBeforeScreenLoadRegistrationLogin(event) {

    cdcEventsLog("Registration/Login - On Before Screen Load", "REGISTRATION-LOGIN");

    // Get Screenset language and store it in a session variable to be used in the login screen
    storeScreenSetLanguage(event);

    // Check if we are in the login screen without the gig_ssoToken.
    // If so, we need to redirect to the login screen with the gig_ssoToken, calling the central login function
    /*if (event.nextScreen === "gigya-login-screen") {

        // Get the gig_ssoToken from the url
        const urlParams = new URLSearchParams(window.location.search);
        const gig_ssoToken = urlParams.get('gig_ssoToken') || event.response.gig_ssoToken || urlParams.get('gig_events');

        // If there is no gig_ssoToken, we need to redirect to the login screen with the gig_ssoToken
        if (!gig_ssoToken) {
            cdcLog("No gig_ssoToken. Redirecting...", "REGISTRATION-LOGIN");
            ssoAenaLogin();
            return false;
        }
    }*/

    if (event.nextScreen ==='gigya-link-account-screen' && (event.response.errorCode === '409003' || event.response.errorCode === '403043') && event.response.gig_originSite === 'wifi'){

        gigya.accounts.showScreenSet({
            startScreen: 'unable-link-account-screen',
            screenSet: 'Aena-LinkAccounts',
            containerID: 'screensetContainer',
            context: event.response
        });
        return false;
    }
}

function onBeforeValidationRegistrationLogin(event) {
    if (event.screen === "gigya-register-screen"){

        var errorEmailConfirmationText = document.querySelectorAll('.error-email-confirmation-text')[0].innerHTML || document.querySelectorAll(".error-email-confirmation-text")[1].innerHTML;

        var errors = [
            {
              "local.confirmEmail": errorEmailConfirmationText,
            }
        ];

        if(event.formData["profile.email"] && event.formData["profile.email"] !== event.formData["local.confirmEmail"]) {
            return errors[0];
        }
    }
}

/**
 * Event handler function that will be called Called after a new screen is rendered.
 * @param  {object} event Form Event object
 */
function onAfterScreenLoadRegistrationLogin(event) {

    cdcEventsLog("Registration/Login - On After Screen Load", "REGISTRATION-LOGIN");

    // Fix image domain for profile update screens
    fixImageDomain(event);


    // Initializing custom elements in screens
    if (event.currentScreen === "gigya-register-screen" ||
        event.currentScreen === "gigya-lite-account-progression-screen" ||
        event.currentScreen === "gigya-complete-registration-screen" ||
        event.currentScreen === "gigya-login-screen" ||
        event.currentScreen === "gigya-reset-password-screen" || 
        event.currentScreen === "gigya-password-change-required-screen"
    ) {
        // Initialize phone prefix and DocID type selectors
        initializeSelectors();

        // Start eyes widget on screen
        initializeEyesComponent(event)

        // Attach events to password fields
        setTimeout(() => {
            let passwordFields = document.querySelectorAll(".gigya-screen[id] form .gigya-composite-control-password input");

            // Check if we are on mandatory change password screen. If yes, remove the first password field, as it's the current password for the user, and it shouldn't be part of this validation

            if (event.currentScreen === "gigya-password-change-required-screen") {

                // Make the querySelectorAll again, but removing the input whose name is "password"
                passwordFields = document.querySelectorAll(".gigya-screen[id] form .gigya-composite-control-password input:not([name='password'])");

            }

            const showFields = (valor, indice, array) => {


                cdcLog("showing.....");
                cdcLog("En el índice " + indice + " hay este valor: " + valor);
                // Show password elements if hidden in the screen
                showPasswordElements(true);

            };

            const hideFields = (valor, indice, array) => {

                cdcLog("hiding......");
                cdcLog("En el índice " + indice + " hay este valor: " + valor);

                setTimeout(() => {

                    // Check if the focused field is the password field, if so, don't hide the password elements, and hide in all other cases
                    const focusedField = document.activeElement;
                    cdcLog("Focused Field:" + focusedField);
                    if (focusedField.classList.contains("gigya-input-password")) {
                        cdcLog("focused field is password, don't hide");
                    } else {
                        cdcLog("focused field is not password, hide");
                        // Hide password elements if hidden in the screen
                        showPasswordElements(false);
                    }

                }, 200);

            };

            if (passwordFields) {

                // We are in the registration screen, so we need to hide the password widget and init the events associated to register 

                cdcLog("Initializing password fields....");
                passwordFields.forEach(passwordField => { passwordField.addEventListener("focus", showFields) });
                passwordFields.forEach(passwordField => { passwordField.addEventListener("blur", hideFields) });
            }
        }, 100);

    }

    // Registration
    if (event.currentScreen === "gigya-register-screen") {

        // Initialize country selector
        initializeCountrySelector();

        // Initializing registration trigger in demo (if function exists)
        if (typeof autoFillRegistrationTrigger === 'function') {
            cdcLog ("Initializing autoFillRegistrationTrigger");
            autoFillRegistrationTrigger();
        }

    }
    // Remove helpBox box if present
    if (event.currentScreen === "gigya-login-screen") {
        removeHelpBoxIfPresent();
    }
    // Eventos para las pantallas de consentimientos
    if (event.currentScreen === 'gigya-complete-registration-screen') {
        // Show the conditions box if we are in popup mode
        showConditionsBoxIfInPopupMode(event);

        // Initialize the check for the communications
        initializeMasterCheck();

        // Initialize the fields with the class "half-size" on it
        initializeHalfSizeFields();

        // Check if all checkboxes are checked. If so, check the "all" checkbox
        storeRegTokenInLocalStorage(event);
    }
    // Eventos para las pantallas de lite account progression
    if (event.currentScreen === 'gigya-lite-account-progression-screen') {

        // Get Screenset language and store it in a session variable to be used in the login screen
        // We do it onAfterScreenLoad because we don't have the power of beforeScreenLoad for this screen (It's already used by LPC)
        storeScreenSetLanguage(event);

        // Check if all checkboxes are checked. If so, check the "all" checkbox
        prefillFormWithUserData();

    }
    // Eventos para las pantallas de verificación de email
    if (event.currentScreen === 'gigya-verification-sent-screen') {

        // Check if we have a parameter "email" in the variable "context" and if so, prefill the email field
        prefillEmailField(event);

        // Store the regToken in the localStorage
        storeRegTokenInLocalStorage(event);

        var originSite = window.gigya.getUrlParam('originSite');
        var urlWifiRedirect = window.gigya.getUrlParam('urlWifiRedirect');

        if ((event.context && event.context.originSite && event.context.originSite === 'wifi') ||
            (originSite && originSite === 'wifi' && urlWifiRedirect)){
            var envUrl = [];
            if (urlWifiRedirect !== undefined && urlWifiRedirect !== null ){
                envUrl = urlWifiRedirect.split('_');
            }
            var wifiUIToShow = document.querySelectorAll('.verification-sent-only-wifi');
            wifiUIToShow.forEach(wifiUI => {
                if (wifiUI.classList.contains('is-aena-green-button') && event.response && event.response.requestParams && event.response.requestParams.regToken){
                    wifiUI.setAttribute('href', getWifiEnvironment(envUrl[0]) + envUrl[1] +'/login?regToken='+ event.response.requestParams.regToken);
                }
                wifiUI.classList.remove("is-hidden");
            });
        }
    }

    // Eventos para las pantallas de envío de reset password ok
    if (event.currentScreen === 'gigya-forgot-password-success-screen') {

        // Check if we have a parameter "email" in the variable "context" and if so, prefill the email field
        prefillEmailField(event);

        var originSiteWifi = window.gigya.getUrlParam('originSite');
        var urlWifiRedirectParam = window.gigya.getUrlParam('urlWifiRedirect');
        var isContextWifi = (event.context && event.context.originSite && event.context.originSite === 'wifi');
        var isOriginSiteWifi = (originSiteWifi && originSiteWifi === 'wifi');
        if ((isContextWifi && urlWifiRedirectParam) || (isOriginSiteWifi && urlWifiRedirectParam)){
            var envUrlWifi = [];
            envUrlWifi = urlWifiRedirectParam.split('_');
            var redirectUrlWifi = getWifiEnvironment(envUrlWifi[0]) + envUrlWifi[1] +'/login?email='+ event.context.email;
            setTimeout(function (){ window.location.href = redirectUrlWifi}, 2000);
        }
    }

    /*if(event.nextScreen === "gigya-complete-registration-screen" ||
        event.nextScreen === "gigya-link-account-screen" ||
        event.nextScreen === "gigya-complete-registration-screen"){

        //To show the modal correctly on the captive portal (WIFI)
        var classes = document.querySelectorAll('.gigya-mobile-modal-mode')
        classes.forEach(element => {element.classList.remove("gigya-mobile-modal-mode")})

        //var dialogs = document.querySelectorAll('.gigya-screen-dialog-mobile')
        //dialogs.forEach(element => {element.classList.remove("gigya-screen-dialog-mobile")})
        //dialogs.forEach(element => {element.classList.add("gigya-screen-dialog")})
    }*/

    if(event.currentScreen === 'unable-link-account-screen'){
        var providers = JSON.parse(sessionStorage.getItem('link_'+ event.context.regToken));
        var providersLabel = document.querySelector('.unable-link-account-screen-providers[data-screenset-roles*="instance"]')
        providers = providers.join(", ");
        providers = providers.replace('site','usuario y contraseña');
        providersLabel.innerHTML = providers;
    }
}
/**
 * Event handler function that will be called before a form is submitted.
 * @param  {object} event Form Event object
 * @returns {boolean} Flag that controls if submission must continue or not
 */
function onAfterValidationRegistrationLogin(event) {

    // Log action

    cdcEventsLog("On After Validation for screen: " + event.screen + ". Fields: " + event);

    // Check the registration screen and its fields to validate it
    if (event.screen === "gigya-register-screen" || event.screen === "gigya-lite-account-progression-screen") {

        // Get the country and the zip code
        const country = event.formData['profile.country'];
        const zipCode = event.formData['profile.zip'];
        const phonePrefix = event.formData['data.phones.prefix'];
        const phoneNumber = event.formData['data.phones.number'];
        const province = null; // We don't have province in the registration form
        const IS_EMAIL_FIELD = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='email']") ? true : false;
        const emailField = IS_EMAIL_FIELD ? document.querySelector(".gigya-screen[id] form .gigya-input-text[name='email']") : document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.email']");
        const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");

        // Can be  from the email field or the profile.email field
        const profileEmailSelector = '.gigya-screen[id] form [name="profile.email"]';
        const simpleEmailSelector = '.gigya-screen[id] form [name="email"]';
        const emailSelector = IS_EMAIL_FIELD ? simpleEmailSelector : profileEmailSelector;

        // const currentEmailSelector = document.querySelector(profileEmailSelector) ? profileEmailSelector : emailSelector;


        // Fields and their corresponding checks and selectors
        const fields = [
            { check: () => checkIfNamesAreFilled(event), selector: '.gigya-screen[id] form [name="profile.firstName"]' },
            { check: () => checkIfConfirmationEmailIsFilled(event), selector: emailSelector },
            { check: () => checkIfEmailsAreTheSame(emailField, emailConfirmField), selector: emailSelector },
            { check: () => checkIfPasswordFieldsAreFilled(event), selector: '.gigya-screen[id] form [name="password"]' },
            { check: () => checkZipCodeValidity(country, province, zipCode, 'profile.zip', event), selector: '.gigya-screen[id] form [name="profile.zip"]' },
            { check: () => checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event), selector: '.gigya-screen[id] form [name="data.phones.number"]' },
            { check: () => checkEmailValidity(emailField.value, IS_EMAIL_FIELD ? 'email': 'profile.email', event), selector: emailSelector },
        ];

        // Check if we are in mobile or desktop
        const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        const isPreferences = IS_EMAIL_FIELD ? false : true;
        let distance = isMobile ? 100 : 30;
        distance = isPreferences ? distance + 100 : distance;

        // Variable to keep track of whether any check fails
        let allChecksPassed = true;
        let firstErrorField = null;

        // Check fields in order and log errors without scrolling
        for (let field of fields) {
            try {
                if (!field.check(event)) {
                    cdcLog(field.selector + " is not filled or not valid");
                    allChecksPassed = false; // if any check fails, set this to false
                    if (!firstErrorField) {
                        firstErrorField = field; // record the first field that has an error
                    }
                }
            } catch (error) {
                // Handle or log the error here
                cdcLog(`Error while checking field ${field.selector}: ${error.message}`);
                allChecksPassed = false;
                if (!firstErrorField) {
                    firstErrorField = field;
                }
            }
        }

        // If there was an error, scroll to the first field that had an error
        if (!allChecksPassed && firstErrorField) {
            scrollToElement(firstErrorField.selector, distance);
        }

        // If allChecksPassed is still true after all checks, all checks passed.
        // If it's false, at least one check failed.
        return allChecksPassed;

    }

    // Check the login screen and its fields to validate it
    if (event.screen === "gigya-login-screen") {

        // Check if the password field is filled
        const isPasswordFilled = checkIfPasswordFieldIsFilled(event);

        // Return the result of the validations
        return isPasswordFilled;
    }

    // Check the registration screen and its fields to validate it
    if (event.screen === "gigya-lite-account-progression-screen") {

        // Check password fields. If they are empty, add the error class to the eyes widget
        const arePasswordsFilled = checkIfPasswordFieldsAreFilled(event);

        // If it doesn't pass the validations, move the scroll to the top of the screen
        if (!arePasswordsFilled) {

            cdcLog("Scrolling to the top of the page for screen: " + event.screen);
            // Scroll to the top of the form
            scrollToElement(".gigya-lite-account-progression-screen", 140)
            }

        // Return the result of the validations
        return arePasswordsFilled;
    }
}
/**
 * Event handler function that will be called before a form is submitted.
 * @param  {object} event Form Event object
 * @returns {boolean} Flag that controls if submission must continue or not
 */
function onBeforeSubmitRegistrationLogin(event) {

    // Log action
    cdcLog("X - On Before Submit for screen: " + event.screen + ". Fields: " + event);
    const formData = event.formData;

    // Check the screen and the field to validate it
    if (event.screen === "gigya-register-screen") {

        // Get First Name and Last Name fields
        const firstName = formData['profile.firstName'];
        const lastName = formData['profile.lastName'];

        // Get the email field and the confirmation email field
        const emailField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='email']");
        const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");

        // Get the phone values
        const phonePrefix = formData['data.phones.prefix'];
        const phoneNumber = formData['data.phones.number'];

        // Get the country and the zip code
        const country = formData['profile.country'];
        const zipCode = formData['profile.zip'];


        // Check that they are filled, and they are the same
        const emailsValid = true;
        if (emailField && emailConfirmField && emailField.value !== null && emailConfirmField.value !== null) {
            const theyAreTheSame = checkIfEmailsAreTheSame(emailField, emailConfirmField);
            if (!theyAreTheSame) {
                return false;
            }
        }

        // Check the validity of the components of the form
        const phoneValid = checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event);
        const province = null; // We don't have province in the registration form
        const zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'profile.zip', event);

        // If any of the components is not valid, we stop the submission
        const formValid = emailsValid && phoneValid && zipCodeValid;

        return formValid;
    }
    // Check the screen and the field to validate it
    if (event.screen === "gigya-login-screen") {

        // Get the email field and the confirmation email field
        const emailField = formData['loginID'];
        // Get the password field
        const password = document.querySelector(".gigya-screen[id] form .gigya-input-password[name='password']").value;
        
        // Check the validity of the components of the form
        const emailValid = checkEmailValidity(emailField, 'username', event);
        const isLoginScreen = false;
        // const passwordValid = checkPasswordValidity(password, 'password', isLoginScreen, event);
        const passwordValid = true; // Harversting.
        // If any of the components is not valid, we stop the submission
        const formValid = emailValid && passwordValid;

        return formValid;
    }
    // Check the screen and the field to validate it
    if (event.screen === 'gigya-forgot-password-screen') {
        const value = event.formData.loginID;

        // Check if the email is valid
        const isValidEmail = checkEmailValidity(value, 'username', event);

        return isValidEmail;
    }
    // Check the screen and the field to validate it
    if (event.screen === "gigya-lite-account-progression-screen") {

        // Get the phone values
        const phonePrefix = formData['data.phones.prefix'];
        const phoneNumber = formData['data.phones.number'];

        // Get the country and the zip code
        const country = formData['profile.country'];
        const zipCode = formData['profile.zip'];

        // Check the validity of the components of the form
        const phoneValid = checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event);
        const province = null; // We don't have province in the registration form
        const zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'profile.zip', event);

        // If any of the components is not valid, we stop the submission
        const formValid = phoneValid && zipCodeValid;

        return formValid;
    }

    // Check the screen and the field to validate it
    if (event.screen === "gigya-complete-registration-screen") {
            
            // Get the phone values
            const phonePrefix = formData['data.phones.prefix'];
            const phoneNumber = formData['data.phones.number'];

    
            // Get the country and the zip code
            const country = formData['profile.country'];
            const zipCode = formData['profile.zip'];
            let phoneValid = true;
            let zipCodeValid = true;
            
            // Check the validity of the components of the form
            if (phonePrefix && phoneNumber && phonePrefix.value !== '' && phoneNumber.value !== '') {
                phoneValid = checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event);
            } else {
                phoneValid = true;
            }

            if (country && zipCode && country.value !== '' && zipCode.value !== '') {
                const province = null; // We don't have province in the registration form
                zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'profile.zip', event);
            } else {
                zipCodeValid = true;
            }

            // If any of the components is not valid, we stop the submission
            const formValid = phoneValid && zipCodeValid;
    
            // If form valid, then hide the "communications-checkbox" elements, as they are appearing in the form without any reason
            if (formValid) {
                const communicationsCheckboxes = document.querySelectorAll(".gigya-screen[id] form .gigya-composite-control-communications-checkbox");
                communicationsCheckboxes.forEach(communicationsCheckbox => {
                    communicationsCheckbox.classList.add("is-hidden");
                });
            }
            return formValid;
    }
}
/**
 * Event handler function that will be called when a form is submitted.
 * @param  {object} event Form Event object
 */
function onSubmitRegistrationLogin(event) {
    cdcLog("6 - On Submit for screen: " + event.screen + ". Fields: " + event);


    // Take fields from the form
    const screen = event.screen;

    // Check the screen and the field to validate it
    if (screen === "gigya-register-screen" || screen === "gigya-complete-registration-screen" || screen === "gigya-lite-account-progression-screen") {

        // Add Service and inicioRelacion fields to the form data
        addServiceFieldFieldToFormData(event.formModel, event);
        addInicioRelacionFieldToFormData(event.formModel, event);

        // Add the subscriptionAENA field to the form data if not filled
        if (!event.accountInfo.data.subscriptionAENA) {
            addSubscriptionAENAFieldToFormData(event.formModel);
        }

        // Scroll to the top of the page, with a 30px offset
        scrollToElement(".main__inner-wrapper .gigya-style-modern", 30);

    }


    // Check the not informed communications and add them to the form data as false
    if (screen === "gigya-complete-registration-screen") {

        //Adds the attribute socialTemporary to the formModel to see the changes on webhooks v3
        if(window.socialTemporary != null){
            event.formModel.data.socialTemporary = window.socialTemporary;
            window.socialTemporary = null;
        }

        // Add Service and inicioRelacion fields to the form data
        addNotInformedCommunicationsToFormData(event.formModel);
    }

    // Check if the screen is the account progression screen
    if (screen === "gigya-lite-account-progression-screen") {
    
        // Add data.userType field to the form data as FULL
        event.formModel.data.userType = 'FULL';
    }

    //This behavior is for accounts linking error when account is pending verification
    if (screen === "gigya-verification-pending-screen") {
        if(!event.formModel.regToken){
            event.formModel.regToken = event.context.regToken || null;
        }
    }
}
/**
 * Event handler function that will be called after a form is submitted.
 * 
 * In this case, it controlles if we recieve the 400003 (username or email exists),
 * and if that is the case, it closes automatically the registration screen and shows
 * the Sent Email Verifiation screen (Harvesting mitigation).
 * 
 * @param  {object} event Form Event object
 */
function onAfterSubmitRegistrationLogin(event) {

    cdcLog("onAfterSubmitRegistrationLogin");
    

    if (event.screen === 'gigya-register-screen') {

        // Wait for the user fully populated before Registration Completion
        waitFor(1500);

    }
    if (event.screen === 'gigya-complete-registration-screen' ) {
        
        // Hide all communications checkboxes
        hideCommunicationsCheckboxes(event);
    }
    if (event.screen === 'gigya-forgot-password-screen') {
        // Harvesting mitigation
        showSuccessForgotPasswordEmailScreen(event);

    }

    //This behavior is for accounts that are not verified and try to log with social accounts
    if (event.screen === 'gigya-link-account-screen') {

        if (event.response.errorCode === 206002) {
            var containerID = (event.instanceID === 'screenSet' || event.instanceID === 'screenSet2') ? null : event.instanceID;
            if (!containerID){
                gigya.accounts.hideScreenSet({ screenSet: 'Aena-LinkAccounts' });
                gigya.accounts.hideScreenSet({ screenSet: 'Aena-RegistrationLogin' });
            }
            showVerificationPendingScreen(event, containerID);
        }
    }

    if (event.screen === 'gigya-reset-password-screen') {

        if (event.response.errorCode === 403025 || event.response.errorCode === 403002) {

            // Goto expired link page
            gotoExpiredLinkPage(event);
        }

    }
    return false;
}
/**
 * Event handler function that will be called after a form is submitted.
 * 
 * It controls changes over fields that has changed in the form
 *  
 * @param  {object} event Form Event object
 */
function onFieldChangedRegistrationLogin(event) {

    // 0. Take fields from the form
    const screen = event.screen;
    const field = event.field;
    const value = event.value;

    // 1. Log action
    cdcEventsLog("On Field Changed For screen: " + screen + ". Name: " + field + ", value: " + value, "ON FIELD CHANGED");

    // Check if we are registration or lite account progression screen
    if (screen === "gigya-register-screen" || screen === "gigya-lite-account-progression-screen") {

        const IS_EMAIL_FIELD = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='email']") ? true : false;
        const emailFieldName = IS_EMAIL_FIELD ? "email" : "profile.email";

        const emailField = document.querySelector(`.gigya-screen[id] form .gigya-input-text[name='${emailFieldName}']`);
        const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");

        const currentCountry = document.querySelector('.gigya-screen[id] form select[name="profile.country"]').value;
        const currentZipCode = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="profile.zip"]').value;
        const currentProvinceCode = null; // We don't have province code in this screen

        const currentPhonePrefix = document.querySelector('.gigya-screen[id] form select[name="data.phones.prefix"]').value;
        const currentPhoneNumber = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="data.phones.number"]').value;



        // Check if the field is docID.type or docID.number
        if (field === "profile.country" || field === "profile.zip") {
            // Check Zip Code Validity
            checkZipCodeValidity(currentCountry, currentProvinceCode, currentZipCode, 'profile.zip', event);
        }

        // Check if the field is data.phones.prefix or data.phones.number
        if (field === "data.phones.prefix" || field === "data.phones.number") {

            // Check phone validity
            checkPhoneValidity(currentPhonePrefix, currentPhoneNumber, 'data.phones.number', event);

        }

        if (field === "email" || field === "local.emailConfirm") {

            // Launch email validity control
            checkEmailValidity(emailField.value, emailFieldName, event);


                if (emailField && emailConfirmField && emailField.value !== null && emailConfirmField.value !== null) {
                const theyAreTheSame = checkIfEmailsAreTheSame(emailField, emailConfirmField);
                const theyAreNotEmpty = emailField.value !== "" && emailConfirmField.value !== "";
                if (theyAreTheSame && theyAreNotEmpty) {
                    // Clean general error message for these fields
                    cleanEmailErrorValues();
                } else {
                    // Show error message
                    setTimeout(() => {
                        showEmailErrorValuesOnFields(event);
                    }, 100);
                }
            }
        }

        // In general, recheck the aena specific errors, and repaint the error after CDC cleans the default validation error (which is not the AENA error sometimes)
        // update error message (is not valid)
        setTimeout(() => {
            cdcLog("Rechecking errors");
            const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
            const isValidZipCode = checkZipCodeValidityOnly(currentCountry, currentProvinceCode, currentZipCode);
            recheckField(isValidZipCode, 'profile.zip', event);
            recheckField(isValidPhone, 'data.phones.number', event);
        }, 1);
    }

    //Check if the account of login social is temporal or not
    if (event.screen === "gigya-complete-registration-screen") {
        //Check if the email is available to fill the field data.socialTemporary
        if (event.field === 'profile.email'){
            gigya.accounts.isAvailableLoginID({loginID: event.value, callback: checkEmail});
        }

        function checkEmail(response){
            if(response && response.errorCode !== null && response.errorCode === 0){
                window.socialTemporary = response.isAvailable ? "newUser": "true";
            }
        }
    }

    // Check if we are in update profile screen
    if (screen === "gigya-forgot-password-screen") {

        // Check Email Validity
        checkEmailValidity(value, 'username', event);

    }

    // 2. Check password widget 
    if (screen === "gigya-login-screen") {
        if (field === "password") {

            // Check validity of the password
            const isLoginScreen = true;
            checkPasswordValidity(value, field, isLoginScreen, event);
        }

        if (field === "loginID") {
            // Launch email validity control
            checkEmailValidity(value, "username", event);
        }
    }

    // 2. Check password widget 
    if (screen === "gigya-register-screen" || screen === "gigya-reset-password-screen" || screen === "gigya-lite-account-progression-screen" || screen === "gigya-password-change-required-screen") {
        if (field === "password" || field === "passwordRetype" || field === "newPassword") {


            // If we are in gigya-password-change-required-screen screen, we need to check the password validity only for newPasswrod and passwordRetype fields
            if (screen === "gigya-password-change-required-screen" && field === "password") {
                cdcLog("We are in gigya-password-change-required-screen screen, we need to check the password validity only for newPasswrod and passwordRetype fields");
            } else {

                // Launch password widget updatePasswordWidget
                updatePasswordWidget(field, value);

                // Update eyes state (true for login, bc of harvesting)
                updatePasswordEyeState(event);
            }
        }

        // Check if the field is docID.type or docID.number
        if (field === "data.docID.type" || field === "data.docID.number") {
            // Get the current value of the Fields
            const currentDocIDType = document.querySelector('#gigya-register-screen select[name="data.docID.type"]').value;
            const currentDocIDNumber = document.querySelector('#gigya-register-screen .gigya-input-text[name="data.docID.number"]').value;

            // Check doc ID validity
            const validDoc = checkDocValidity(currentDocIDType, currentDocIDNumber, 'data.docID.number', event);
            if (validDoc) {

                // Clean general error message for these fields
                cleanDocIDErrorValues();
            } else {
                // Show error message
                showDocIDErrorValuesOnFields(event);
            }
        }
    }


    // 3. Check doc ID validation
    if (screen === "gigya-complete-registration-screen") {

        // Check if the field is docID.type or docID.number
        if (field === "data.docID.type" || field === "data.docID.number") {

            // Get the current value of the Fields
            const currentDocIDType = document.querySelector('#gigya-complete-registration-screen select[name="data.docID.type"]').value;
            const currentDocIDNumber = document.querySelector('#gigya-complete-registration-screen .gigya-input-text[name="data.docID.number"]').value;

            // Check doc ID validity
            const validDoc = checkDocValidity(currentDocIDType, currentDocIDNumber, 'data.docID.number', event);

            if (validDoc) {

                // Clean general error message for these fields
                cleanDocIDErrorValues();
            } else {
                // Show error message
                showDocIDErrorValuesOnFields(event);
            }

        }
    }

    if (screen === "gigya-verification-pending-screen") {
        if (field === "email") {

            // Launch email validity control
            checkEmailValidity(value, 'email', event);

        }
    }

    // 5. Launch Communication Checks update function
    if (screen === "gigya-issue-onfieldchange-screen" || screen === "gigya-complete-registration-screen") {

        // Launch communication checks update function
        checkCommunicationsChecks(event, screen, field, value);

    }
}
function onRefreshExternalComponentsEventRegistrationLogin(event) {
        
        // 0. Take fields from the form
        const eventName = event.eventName;
        const instanceID = event.instanceID;
        const screensetID = event.screenSetID;
        const source = event.source;
        const sourceContainerID = event.sourceContainerID;
    
        // 1. Log action
        cdcEventsLog("On Refresh External For Registration Login - Event Name:" + eventName + ", - Instance ID: " + instanceID + ", - Screenset ID: " + screensetID + ", - Source: " + source + ", - Source Container ID: " + sourceContainerID);

        // Check if we are registration or lite account progression screen
        if (source === "showScreenSet" && sourceContainerID === "register-container") {


            const IS_EMAIL_FIELD = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='email']") ? true : false;
            const emailFieldName = IS_EMAIL_FIELD ? "email" : "profile.email";
    
            const emailField = document.querySelector(`.gigya-screen[id] form .gigya-input-text[name='${emailFieldName}']`);


            const currentEmail = emailField.value;
    
            const currentCountry = document.querySelector('.gigya-screen[id] form select[name="profile.country"]').value;
            const currentZipCode = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="profile.zip"]').value;
            const currentProvinceCode = null; // We don't have province code in this screen
    
            const currentPhonePrefix = document.querySelector('.gigya-screen[id] form select[name="data.phones.prefix"]').value;
            const currentPhoneNumber = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="data.phones.number"]').value;
    

            // In general, recheck the aena specific errors, and repaint the error after CDC cleans the default validation error (which is not the AENA error sometimes)
            // update error message (is not valid)
            setTimeout(() => {
                cdcLog("Rechecking errors on Refresh External For Registration Login");
                const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
                const isValidZipCode = checkZipCodeValidityOnly(currentCountry, currentProvinceCode, currentZipCode);
                const isValidEmail = checkEmailValidity(currentEmail,emailFieldName, event );
                recheckField(isValidZipCode, 'profile.zip', event);
                recheckField(isValidPhone, 'data.phones.number', event);
            }, 1)
        }
}
/**
 * Event handler function that will be called after a form is submitted.
 * @param {*} event 
 */
function onHideRegistrationLogin(event) {

    // Remove Id's from the screen if it's closed in popup mode
    const instanceID = event.instanceID;

    // Console log action with instance ID
    cdcEventsLog("On Hide Registration Login - Instance ID: " + instanceID, "HIDE REGISTRATION LOGIN");
}


/** **************************************************/
//  2. Gigya Profile-Update Event Functions
/** **************************************************/
/**
 * Event handler function that will be called Called after a new screen is rendered.
 * @param  {object} event Form Event object
 */
function onBeforeScreenLoadProfileUpdate(event) {

    cdcEventsLog("Profile Update - On Before Screen Load", "PROFILE-UPDATE");

    // Get Screenset language and store it in a session variable to be used in the login screen
    storeScreenSetLanguage(event);

    // Check if we are in the login screen without the gig_ssoToken.
    // If so, we need to redirect to the login screen with the gig_ssoToken, calling the central login function
    if (event.nextScreen === "gigya-view-addresses-screen") {

        // Initialize provinces
        // const provinces = initializeProvincesObject(event);

        // Console log provinces
        //  cdcLog("Provinces", provinces);
    }
}
/**
 * Event handler function that will be called Called after a new screen is rendered.
 * @param  {object} event Form Event object
 */
function onAfterScreenLoadProfileUpdate(event) {

    cdcEventsLog("Update Profile - On After Screen Load", "UPDATE PROFILE");

    // Repeat here for the LPC, that hasn't onBeforeScreenLoad. Get Screenset language and store it in a session variable to be used in the login screen
    storeScreenSetLanguage(event);

    // Fix image domain for profile update screens
    fixImageDomain(event);

    // Eventos para la pantalla de visualización
    if (event.currentScreen === 'gigya-view-addresses-screen') {

        initViewAddressesWithRaaSScreen(event);
    }
    // Eventos para la pantalla de visualización
    if (event.currentScreen === 'gigya-view-vehicles-screen') {

        initViewVehiclesWithRaaSScreen(event);
    }

    // Eventos para la pantalla de creación
    if (event.currentScreen === 'gigya-update-addresses-screen') {

        // Init address screen
        initNewAddressWithRaaSScreen(event);

    }
    // Eventos para la pantalla de creación
    if (event.currentScreen === 'gigya-update-vehicles-screen') {
        initNewVehicleWithRaaSScreen(event);
    }

    // Eventos para las pantallas de consentimientos
    if (event.currentScreen === 'gigya-communication-screen' || event.currentScreen === 'gigya-update-consents-screen') {

        initializeMasterCheck();
    }

    // Check the screen and the field to validate it
    if (event.currentScreen === "gigya-update-profile-screen") {


        // Initialize the fields with the class "half-size" on it
        initializeHalfSizeFields();

        // Initialize all selectors
        initializeSelectors();

        // Initialize country selector
        // initializeCountrySelector();

        // Attach event to cancel button
        const cancelButton = document.querySelector(".update-profile-cancel-button input");
        setTimeout(() => {
            if (cancelButton) {
                cancelButton.addEventListener("click", onCancelProfileUpdate);
            }
        }, 100);

        // Split birthday to get day, month and year
        const year = event.profile.birthYear;
        let month = event.profile.birthMonth;
        let day = event.profile.birthDay;

        // Normalize month and day values
        if (month < 10) {
            month = "0" + month;
        }

        if (day < 10) {
            day = "0" + day;
        }

        // Construct date with hyphens and the previous values
        const date = year + "-" + month + "-" + day;

        // Set birthday info into the local.birthDate field
        const birthDateField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.birthDate']");
        birthDateField.value = date;

    }

    // Change password screen
    if (event.currentScreen === "gigya-change-password-screen") {
        // Start eyes widget on screen
        initializeEyesComponent(event)

        // Attach events to password fields
        setTimeout(() => {
            let passwordFields = document.querySelectorAll(".gigya-screen[id] form .gigya-composite-control-password input");

            // Check if we are on change password screen. If yes, remove the first password field, as it's the current password for the user, and it shouldn't be part of this validation

            if (event.currentScreen === "gigya-change-password-screen") {

                // Make the querySelectorAll again, but removing the input whose name is "password"
                passwordFields = document.querySelectorAll(".gigya-screen[id] form .gigya-composite-control-password input:not([name='password'])");

            }

            const showFields = (valor, indice, array) => {


                cdcLog("showing.....");
                cdcLog("En el índice " + indice + " hay este valor: " + valor);
                // Show password elements if hidden in the screen
                showPasswordElements(true);

            };

            const hideFields = (valor, indice, array) => {

                cdcLog("hiding......");
                cdcLog("En el índice " + indice + " hay este valor: " + valor);

                setTimeout(() => {

                    // Check if the focused field is the password field, if so, don't hide the password elements, and hide in all other cases
                    const focusedField = document.activeElement;
                    cdcLog("Focused Field:" + focusedField);
                    if (focusedField.classList.contains("gigya-input-password")) {
                        cdcLog("focused field is password, don't hide");
                    } else {
                        cdcLog("focused field is not password, hide");
                        // Hide password elements if hidden in the screen
                        showPasswordElements(false);
                    }

                }, 200);

            };

            if (passwordFields) {

                // We are in the registration screen, so we need to hide the password widget and init the events associated to register 

                cdcLog("Initializing password fields....");
                passwordFields.forEach(passwordField => { passwordField.addEventListener("focus", showFields) });
                passwordFields.forEach(passwordField => { passwordField.addEventListener("blur", hideFields) });
            }
        }, 100);

        // Remove asterics from placeholders
        // removeAstericsFromPlaceholders();

    }

    // Check the screen and the field to validate it
    if (event.currentScreen === "gigya-personal-data-screen") {

        // Initialize all selectors
        initializeSelectors();



    }
}
/**
 * Event handler function that will be called after a form is submitted.
 * 
 * It controls changes over fields that has changed in the form
 *  
 * @param  {object} event Form Event object
 */
function onFieldChangedProfileUpdate(event) {

    // Take fields from the form
    const screen = event.screen;
    const field = event.field;
    const value = event.value;

    // Log action
    cdcEventsLog("EVENT:  - On Field Changed For screen: " + screen + ". Name: " + field + ", value: " + value, "ON FIELD CHANGED");

    // Check the screen and the field to validate it
    if (screen === "gigya-change-password-screen") {
        // 1. Check password validity
        if (field === "password") {

            // Check validity of the password
            const isLoginScreen = false;
            checkPasswordValidity(value, field, isLoginScreen, event);
        }

        // 2. Check password widget 
        if (field === "newPassword" || field === "passwordRetype") {

            // Launch password widget updatePasswordWidget (true indicates that it's the login screen)
            updatePasswordWidget(field, value);

            // Update eyes state (true for login, bc of harvesting)
            updatePasswordEyeState(event);

        }
    }

    // Check if we are in update profile screen
    if (screen === "gigya-update-profile-screen") {

        const currentCountry = document.querySelector('.gigya-screen[id] form select[name="profile.country"]').value;
        const currentZipCode = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="profile.zip"]').value;
        const currentProvinceCode = null; // We don't have province code in this screen

        // Check Zip Code Validity
        checkZipCodeValidity(currentCountry, currentProvinceCode, currentZipCode, 'profile.zip', event);
        // }


        // Check if the field is docID.type or docID.number
        // if (field === "data.docID.type" || field === "data.docID.number") {

        // Get the current value of the Fields
        const currentDocIDType = document.querySelector('.gigya-screen[id] form select[name="data.docID.type"]').value;
        const currentDocIDNumber = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="data.docID.number"]').value;

        // Check doc ID validity
        checkDocValidity(currentDocIDType, currentDocIDNumber, 'data.docID.number', event);

        const currentPhonePrefix = document.querySelector('.gigya-screen[id] form select[name="data.phones.prefix"]').value;
        const currentPhoneNumber = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="data.phones.number"]').value;

        // Check phone validity
        checkPhoneValidity(currentPhonePrefix, currentPhoneNumber, 'data.phones.number', event);
        
        // update error message (is not valid)
        setTimeout(() => {
            cdcLog("Rechecking errors profile update on field changed");
            const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
            const isValidDoc = checkDocValidityOnly(currentDocIDType, currentDocIDNumber);
            const isValidZip = checkZipCodeValidityOnly(currentCountry, currentProvinceCode, currentZipCode);

            recheckField(isValidPhone, 'data.phones.number', event);
            recheckField(isValidDoc, 'data.docID.number', event);
            recheckField(isValidZip, 'profile.zip', event);
        }, 1);
    }

    // If screen is 'gigya-view-vehicles-screen' and the field endsWith 'isMainVehicle',
    // then get all the vehicles array, put 'isMainVehicle' to false except for the selected, and call setAccountInfo with the new array of vehicles
    if (screen === "gigya-view-vehicles-screen" && field.endsWith(".isMainVehicle")) {

        // Get all the vehicles array

        // We get the index from the value string, that has this structure 'data.vehicles[0].isMainVehicle'
        const vehiclesIndex = field.split('[')[1].split(']')[0];
        const vehiclesIndexAsNumber = Number(vehiclesIndex);
        // Put 'isMainVehicle' to false except for the selected
        const vehicles = window.__USER_VEHICLES ? window.__USER_VEHICLES : [];
        vehicles.forEach((vehicles, index) => {
            if (index === vehiclesIndexAsNumber) {
                vehicles.isMainVehicle = value;
            } else {
                vehicles.isMainVehicle = false;
            }
        });

        // uncheck all the checkboxes but the selected one
        const checkboxes = document.querySelectorAll('#gigya-view-vehicles-screen .main-vehicles-checkbox input');

        checkboxes.forEach((checkbox, index) => {
            if (index === vehiclesIndexAsNumber) {
                checkbox.checked = value;
            } else {
                checkbox.checked = false;
            }
        });

        // Call setAccountInfo with the new array of vehicles
        gigya.accounts.setAccountInfo({ data: { vehicles: vehicles }, callback: function (response) {

            // cdcLog("Response from setAccountInfo: ", response);
        } });
    }

    if (screen === "gigya-update-vehicles-screen") {

        // Clean previous error messages
        checkNewRegistrationCarValidity(event);
    }

    // If screen is 'gigya-view-vehicles-screen' and the field endsWith 'isMainVehicle',
    // then get all the vehicles array, put 'isMainVehicle' to false except for the selected, and call setAccountInfo with the new array of vehicles
    if (screen === "gigya-update-addresses-screen") {
        validateAddressFields(event);

        // Change provinces selector if needed. Check if field ends with 'country'

        if (field.endsWith(".country")) {


            updateProvincesSelector(event, field, value);
        }

        // update error message (is not valid)
        setTimeout(() => {

            cdcLog("Rechecking errors profile update on field changed");
            // Take fields from the form
            const field = event.field;

            // Take the index of the array from the name of the field. Example: data.addresses[2].id is 2
            const index = field.split('[')[1].split(']')[0];


            // Take prefix and phone values from array
            const currentDocIDType = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].docType']").value;
            const currentDocIDNumber = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].docNumber']").value;

            const currentCountry = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].country']").value;
            const currentProvinceCode = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].province']").value;
            const currentZipCode = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].zipCode']").value;
            // Check the validity of the zip code
            
            
            // Take prefix and phone values from array
            const currentPhonePrefix = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].prefix']").value;
            const currentPhoneNumber = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].phone']").value;
    
    
            const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
            const isValidDoc = checkDocValidityOnly(currentDocIDType, currentDocIDNumber);
            const isValidZip = checkZipCodeValidityOnly(currentCountry, currentProvinceCode, currentZipCode);

            recheckField(isValidPhone, `data.addresses[${index}].phone`, event);
            recheckField(isValidDoc, `data.addresses[${index}].docNumber`, event);
            recheckField(isValidZip, `data.addresses[${index}].zipCode`, event);
        }, 1);


    }

    if (screen === "gigya-update-consents-screen" || screen === "gigya-communication-screen") {
        // Launch communication checks update function
        // checkCommunicationsCheck(event, screen, field, value);

        // Launch communication checks update function
        checkCommunicationsChecks(event, screen, field, value);


    }

    if (screen === "gigya-personal-data-screen") {

        // Get Phone info
        const currentPhonePrefix = document.querySelector('.gigya-screen[id] form select[name="data.phones.prefix"]').value;
        const currentPhoneNumber = document.querySelector('.gigya-screen[id] form .gigya-input-text[name="data.phones.number"]').value;

        // Check phone validity
        checkPhoneValidity(currentPhonePrefix, currentPhoneNumber, 'data.phones.number', event);

        // update error message (is not valid)
        setTimeout(() => {
            cdcLog("Rechecking errors");
            const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
            recheckField(isValidPhone, 'data.phones.number', event);
        }, 1);

    }
}
function onRefreshExternalComponentsEventProfileUpdate(event) {
        
    // 0. Take fields from the form
    const eventName = event.eventName;
    const instanceID = event.instanceID;
    const screensetID = event.screenSetID;
    const source = event.source;
    const sourceContainerID = event.sourceContainerID;

    // 1. Log action
    cdcEventsLog("On Refresh External For Profile Update - Event Name:" + eventName + ", - Instance ID: " + instanceID + ", - Screenset ID: " + screensetID + ", - Source: " + source + ", - Source Container ID: " + sourceContainerID);

    // Check if we are in profile update screen in any commerce. If yes, recheck error for fields that are not validated by CDCS
    if (source === "showScreenSet" && sourceContainerID === "billingInfo-container") {

        // In general, recheck the aena specific errors, and repaint the error after CDC cleans the default validation error (which is not the AENA error sometimes)
        // update error message (is not valid)
        const context = event.context;

        if (!context) {
            return;
        }

        const index = context.id ? context.id[context.id.length - 1] : null;
        
        if (index) {
            setTimeout(() => {
                cdcLog("Rechecking errors on Refresh External For Profile Update (Address)");

    
                // Take prefix and phone values from array
                const isScreenLoaded =document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].docType']") ? true : false;
                
                if (!isScreenLoaded) {
                    cdcLog("Screen not loaded yet");
                    return;
                }
                
                cdcLog("Screen loaded");
                const currentDocIDType = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].docType']").value;
                const currentDocIDNumber = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].docNumber']").value;
    
                const currentCountry = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].country']").value;
                const currentProvinceCode = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].province']").value;
                const currentZipCode = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].zipCode']").value;
                                
                // Take prefix and phone values from array
                const currentPhonePrefix = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].prefix']").value;
                const currentPhoneNumber = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].phone']").value;
                
                // Check the validity of the elements
                const isValidPhone = checkPhoneValidityOnly(currentPhonePrefix, currentPhoneNumber);
                const isValidDoc = checkDocValidityOnly(currentDocIDType, currentDocIDNumber);
                const isValidZip = checkZipCodeValidityOnly(currentCountry, currentProvinceCode, currentZipCode);

                // Recheck the fields in screen
                recheckField(isValidPhone, `data.addresses[${index}].phone`);
                recheckField(isValidDoc, `data.addresses[${index}].docNumber`);
                recheckField(isValidZip, `data.addresses[${index}].zipCode`);

            }, 1)
        }
    }
}
function isValidAddress(event) {
    return false;
}
function onAfterValidationProfileUpdate(event) {

    // Log action

    cdcEventsLog("EVENT:  - On After Validation for screen: " + event.screen + ". Fields: " + event);

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-addresses-screen") {
        // Fields and their corresponding checks and selectors
        const fields = [
            { check: () => checkNewAddressValidity(event), selector: getSelectorForNewAddressValidity(event) },
        ];

        // Check if we are in mobile or desktop
        const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let distance = isMobile ? 100 : 30;

        // Variable to keep track of whether any check fails
        let allChecksPassed = true;
        let firstErrorField = null;

        // Check fields in order and log errors without scrolling
        for (let field of fields) {
            try {
                if (!field.check(event)) {
                    cdcLog(field.selector + " is not filled or not valid");
                    allChecksPassed = false; // if any check fails, set this to false
                    if (!firstErrorField) {
                        firstErrorField = field; // record the first field that has an error
                    }
                }
            } catch (error) {
                // Handle or log the error here
                cdcLog(`Error while checking field ${field.selector}: ${error.message}`);
                allChecksPassed = false;
                if (!firstErrorField) {
                    firstErrorField = field;
                }
            }
        }

        
        // If there was an error, scroll to the first field that had an error
        if (!allChecksPassed && firstErrorField) {
            scrollToElement(firstErrorField.selector, distance);
        }

        // If allChecksPassed is still true after all checks, all checks passed.
        // If it's false, at least one check failed.
        return allChecksPassed;
    }

    // Lite Preferences Center Personal Information Screen
        // Check the registration screen and its fields to validate it
    if (event.screen === "gigya-personal-data-screen") {

        // Get the country and the zip code
        const phonePrefix = event.formData['data.phones.prefix'];
        const phoneNumber = event.formData['data.phones.number'];

        // Fields and their corresponding checks and selectors
        const fields = [
            { check: () => checkIfNamesAreFilled(event), selector: '.gigya-screen[id] form [name="profile.firstName"]' },
            { check: () => checkIfNamesAreGreaterThan40Chars(event), selector: '.gigya-screen[id] form [name="profile.firstName"]' },
            { check: () => checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event), selector: '.gigya-screen[id] form [name="data.phones.number"]' },
        ];

        // Check if we are in mobile or desktop
        const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let distance = isMobile ? 100 : 30;

        // Variable to keep track of whether any check fails
        let allChecksPassed = true;
        let firstErrorField = null;

        // Check fields in order and log errors without scrolling
        for (let field of fields) {
            try {
                if (!field.check(event)) {
                    cdcLog(field.selector + " is not filled or not valid");
                    allChecksPassed = false; // if any check fails, set this to false
                    if (!firstErrorField) {
                        firstErrorField = field; // record the first field that has an error
                    }
                }
            } catch (error) {
                // Handle or log the error here
                cdcError(`Error while checking field ${field.selector}: ${error.message}`);
                allChecksPassed = false;
                if (!firstErrorField) {
                    firstErrorField = field;
                }
            }
        }

        // If there was an error, scroll to the first field that had an error
        if (!allChecksPassed && firstErrorField) {
            scrollToElement(firstErrorField.selector, distance);
        }

        // If allChecksPassed is still true after all checks, all checks passed.
        // If it's false, at least one check failed.
        return allChecksPassed;

    }


    // Check the update profile screen and its fields to validate it
    if (event.screen === "gigya-update-profile-screen") {

        // Get the country and the zip code
        const country = event.formData['profile.country'];
        const zipCode = event.formData['profile.zip'];
        const phonePrefix = event.formData['data.phones.prefix'];
        const phoneNumber = event.formData['data.phones.number'];
        const province = null; // We don't have province in the registration form
       

        // Fields and their corresponding checks and selectors
        const fields = [
            { check: () => checkIfNamesAreFilled(event), selector: '.gigya-screen[id] form [name="profile.firstName"]' },
            { check: () => checkIfNamesAreGreaterThan40Chars(event), selector: '.gigya-screen[id] form [name="profile.firstName"]' },
            { check: () => checkDocValidity(event), selector: '.gigya-screen[id] form [name="data.docID.type"]' },
            { check: () => checkZipCodeValidity(country, province, zipCode, 'profile.zip', event), selector: '.gigya-screen[id] form [name="profile.zip"]' },
            { check: () => checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event), selector: '.gigya-screen[id] form [name="data.phones.number"]' },
        ];

        // Check if we are in mobile or desktop
        const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
        let distance = isMobile ? 100 : 30;

        // Variable to keep track of whether any check fails
        let allChecksPassed = true;
        let firstErrorField = null;

        // Check fields in order and log errors without scrolling
        for (let field of fields) {
            try {
                if (!field.check(event)) {
                    cdcLog(field.selector + " is not filled or not valid");
                    allChecksPassed = false; // if any check fails, set this to false
                    if (!firstErrorField) {
                        firstErrorField = field; // record the first field that has an error
                    }
                }
            } catch (error) {
                // Handle or log the error here
                cdcLog(`Error while checking field ${field.selector}: ${error.message}`);
                allChecksPassed = false;
                if (!firstErrorField) {
                    firstErrorField = field;
                }
            }
        }

        // If there was an error, scroll to the first field that had an error
        if (!allChecksPassed && firstErrorField) {
            scrollToElement(firstErrorField.selector, distance);
        }

        // If allChecksPassed is still true after all checks, all checks passed.
        // If it's false, at least one check failed.
        return allChecksPassed;

    }


}
/**
 * Event handler function that will be called before a form is submitted.
 * @param  {object} event Form Event object
 * @returns {boolean} Flag that controls if submission must continue or not
 */
function onBeforeSubmitProfileUpdate(event) {

    const formData = event.formData;
    const screen = event.screen;

    // Log action
    cdcEventsLog("EVENT:  - On Before Submit for screen: " + screen + ". Fields: " + event);

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-profile-screen") {

        // Get Doc ID values
        const docIdType = formData['data.docID.type'];
        const docIdNumber = formData['data.docID.number'];

        // Get the phone values
        const phonePrefix = formData['data.phones.prefix'];
        const phoneNumber = formData['data.phones.number'];

        // Get the country and the zip code
        const country = formData['profile.country'];
        const zipCode = formData['profile.zip'];
        const province = null; // We have no province here, so we send it as null

        // Check the validity of the components of the form
        const docValid = checkDocValidity(docIdType, docIdNumber, 'data.docID.number', event);
        const phoneValid = checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event);
        const zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'profile.zip', event);

        // If any of the components is not valid, we stop the submission
        const formValid = docValid && phoneValid && zipCodeValid;

        return formValid;
    }

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-addresses-screen") {
        const mustContinue = checkNewAddressValidityOnly(event);
        return mustContinue;
    }

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-vehicles-screen") {

        if (event.context && event.context.action === 'add'){
            if (event.data && event.data.vehicles && event.data.vehicles.length === 3){
                const carRegistrationErrorHTMLElement = document.querySelector('.gigya-array-template:not(.is-hidden) .gigya-error-msg');
                if (carRegistrationErrorHTMLElement){
                    let invalidCarRegistrationMessage = getMessagesTranslationFor ('LABEL_118934104581675520_LABEL', event);
                    invalidCarRegistrationMessage = invalidCarRegistrationMessage ? invalidCarRegistrationMessage : 'Has alcanzado el límite máximo de matrículas';
                    carRegistrationErrorHTMLElement.classList.add("is-error-visible");
                    carRegistrationErrorHTMLElement.innerHTML = invalidCarRegistrationMessage;

                    carRegistrationErrorHTMLElement.classList.add("gigya-error-msg-active");
                    carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.add("gigya-error");
                    carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.remove("gigya-valid");
                }
                return false;
            }
        }
        return checkNewRegistrationCarValidity(event);
    }

    // Check the screen and the field to validate it
    if (event.screen === "gigya-personal-data-screen") {

        // Get the phone values
        const phonePrefix = formData['data.phones.prefix'];
        const phoneNumber = formData['data.phones.number'];

        // Check the validity of the components of the form
        const phoneValid = checkPhoneValidity(phonePrefix, phoneNumber, 'data.phones.number', event);
        const namesValid = checkIfNamesAreGreaterThan40Chars(event);

        // If any of the components is not valid, we stop the submission
        const formValid = phoneValid && namesValid;

        return formValid;
    }

}
/**
 * Event handler function that will be called when a form is submitted.
 * @param  {object} event Form Event object
 */
function onSubmitProfileUpdate(event) {

    // Log action
    cdcEventsLog("EVENT:  - On Submit for screen: " + screen + ". Fields: " + event);

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-profile-screen") {

        // Get Birthday info
        const birthday = event.formModel.local.birthDate;


        // Set birthday info to the profile properly
        if (birthday) {
        // Split birthday to get day, month and year
            const birthDaySplitted = birthday.split('-');
            const year = birthDaySplitted[0];
            const month = birthDaySplitted[1];
            const day = birthDaySplitted[2];

            event.formModel.profile.birthDay = day;
            event.formModel.profile.birthMonth = month;
            event.formModel.profile.birthYear = year;
    
        }
    }

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-addresses-screen") {

        // Make main address if it's the first one     
        makeMainAddressIfFirst(event);
    }

    // Fix the carRegistration field if needed
    if (event.screen === "gigya-update-vehicles-screen") {

        // Make main address if it's the first one     
        fixCarRegistrationIfNeeded(event);
    }

    // Add scroll to the screens that they need it (In Commerce)
    if (
        event.screen === "gigya-update-profile-screen" ||
        event.screen === "gigya-update-consents-screen" ||
        event.screen === "gigya-change-password-screen" ||
        event.screen === "gigya-update-addresses-screen" ||
        event.screen === "gigya-update-vehicles-screen"
    ) {

        cdcLog("Scrolling to the top of the page for screen: " + event.screen);
        // Scroll to the top of the page
        scrollToElement(".main__inner-wrapper");
    }

    // Add scroll to the screens that they need it (In Lite Preferences Center)
    if (
        event.screen === "gigya-personal-data-screen" ||
        event.screen === "gigya-communication-screen"
    ) {

        cdcLog("Scrolling to the top of the page for screen: " + event.screen);
        // Scroll to the top of the page
        scrollToElement(".page-wrapper", 100);
    }
}
/**
 * Event handler function that will be called after a form is submitted.
 * 
 * @param  {object} event Form Event object
 */
function onAfterSubmitProfileUpdate(event) {

    // Log action
    cdcEventsLog("EVENT:  - On After Submit for screen: " + event.screen + ". Fields: " + event);

    // Check the screen and the field to validate it
    if (event.screen === "gigya-update-profile-screen") {
        // viewProfileWithRaaS('edit_profile_placeholder');
    }

    if (event.screen === "gigya-view-profile-screen") {
        // gotoHome();
    }
    // Eventos para la pantalla de visualización
    if (event.screen === 'gigya-update-addresses-screen') {

        // Call to view addresses screen
        // viewAddressesWithRaaS("edit_profile_placeholder");

    }
}
function onHideProfileUpdate(event) {
    // Remove Id's from the screen if it's closed in popup mode
    const instanceID = event.instanceID;

    // Console log action with instance ID
    cdcEventsLog("On Hide Profile Update - Instance ID: " + instanceID, "HIDE PROFILE UPDATE");

    // Check if we are in the screen of the communication preferences (gigya-update-communcations-screen). We need both conditions in the selector!! 
    const communicationsScreen = document.querySelector(".gigya-screen[id][id=gigya-update-communications-screen]");
    if (communicationsScreen) {
        cdcLog("Into communications screen");
        // Check if the empty checkbox is still there
            const communicationsScreenCheck = document.querySelector("#gigya-update-communications-screen form .communications-checkbox input[type='checkbox'][name='preferences.communications_AenaClub.isConsentGranted']");
            const communicationsScreenCheckNotChecked = communicationsScreenCheck ? communicationsScreenCheck.checked === false : false;
            if (communicationsScreenCheckNotChecked) {
                const preferencesAsTrue = {
                    communications_AenaClub: {
                        isConsentGranted: true,
                        tags: ["Set-Communications-AenaClub-1"]
                    }
                };
                const preferencesAsFalse = {
                    communications_AenaClub: {
                        isConsentGranted: false,
                        tags: ["Set-Communications-AenaClub-2"]
                    }
                };
                gigya.accounts.setAccountInfo({preferences: preferencesAsTrue, callback: function (res) {
                    
                        cdcLog("SetAccountInfo callback 1: " + res);
                        gigya.accounts.setAccountInfo({preferences: preferencesAsFalse, callback: function (res) {
                            // Call to view profile screen
                            // viewProfileWithRaaS("edit_profile_placeholder");
                            cdcLog("SetAccountInfo callback 2: " + res);
                        }});
                    }
                });
            }
    }
}
/**
 * Event handler function that will be called Called the update form is cancelled.
 * @param  {object} event Form Event object
 */
function onCancelProfileUpdate() {
    cdcEventsLog("Update Profile - Cancel", "CANCEL UPDATE PROFILE");
    // gigya.accounts.hideScreenSet({ screenSet: 'gigya-update-profile-screen' });
    viewProfileWithRaaS('edit_profile_placeholder');
}
function showPasswordErrorInForm (show, formDataElementName, event) {

    // Look for the field in the form
    const field = document.querySelector(`.gigya-screen[id] form .gigya-input-password[name="${formDataElementName}"]`);
    // Find the associated error message attached to that field
    const errorMessage = field.parentElement.querySelector('.gigya-error-msg');
    const eyeElement = field.parentElement.querySelector(".eye-password-icon");
            
    setTimeout(function () {
        // If the field is not valid, show the error message
        if (show) {

            // Add few error classes in one line
            errorMessage.classList.add("gigya-error-msg-active");

            // Get appropiate error message (from translations)
            let errorMessageText = getMessagesTranslationFor ('LABEL_47653686969524640_LABEL', event);
            if (errorMessageText === 'LABEL_47653686969524640_LABEL') {
                errorMessageText = getMessagesTranslationFor ('PASSWORD_DOES_NOT_MEET_COMPLEXITY_REQUIREMENTS', event);
                if (errorMessageText === 'PASSWORD_DOES_NOT_MEET_COMPLEXITY_REQUIREMENTS') {
                    errorMessageText = getMessagesTranslationFor ('INVALID_FIELDNAME', event);
                }
            }
            
            // Show error message
            errorMessage.innerHTML = errorMessageText ? errorMessageText : "This value is not valid";

            field.classList.add("gigya-error");
            field.classList.remove("gigya-valid");

            // Look for the eye icon and add error class
            eyeElement.classList.add("error-eye");
            eyeElement.classList.remove("success-eye");


        } else {

            // Remove few error classes in one line
            errorMessage.classList.remove("gigya-error-msg-active");

            // Remove error message
            // errorMessage.innerHTML = '';

            // Remove error class to the field
            field.classList.remove("gigya-error");
            field.classList.add("gigya-valid");
    
            // Look for the eye icon and add error class
            eyeElement.classList.remove("error-eye");
            eyeElement.classList.add("success-eye");

        }
    }, 100);
}
function showErrorInForm (show, formDataElementName, event) {

    // Look for the field in the form
    const field = document.querySelector(`.gigya-screen[id] form .gigya-input-text[name="${formDataElementName}"]`);
    // Find the associated error message attached to that field
    const errorMessage = field.parentElement.querySelector('.gigya-error-msg');

    // Take parent element and check if already has this class: gigya-error-code-400027. If it has, we don't show the error message
    const errorMessageElementClasses = errorMessage.classList;
    const errorMessageElementHasError = errorMessageElementClasses.contains("gigya-error-code-400027");

    // Take the adjacent input and check if it is mandatory and if it is filled
    const inputElement = field.parentElement.querySelector('.gigya-input-text');
    const inputElementIsMandatory = inputElement.getAttribute("aria-required") === "true";
    const inputElementIsFilled = inputElement.value !== "";
    const inputElementIsMandatoryAndNotFilled = inputElementIsMandatory && !inputElementIsFilled;

    // if (errorMessageElementHasError) {
    //     cdcLog("This field has error 400027, so we don't show the error message");
    // } else {

        setTimeout(function () {
            // If the field is not valid, show the error message
            if (show) {

                // Add few error classes in one line
                errorMessage.classList.add("gigya-error-msg-active");

                // Get appropiate error message (from translations)
                let errorMessageText = getMessagesTranslationFor ('INVALID_FIELDNAME', event);

                // If field is not filled and is mandatory, we change the error message by the mandatory one
                if (inputElementIsMandatoryAndNotFilled) {
                    errorMessageText = getMessagesTranslationFor ('THIS_FIELD_IS_REQUIRED', event);
                } 

                // Show error message
                errorMessage.innerHTML = errorMessageText ? errorMessageText : "This value is not valid";

                field.classList.add("gigya-error");
                field.classList.remove("gigya-valid");
            } else {

                // Remove few error classes in one line
                errorMessage.classList.remove("gigya-error-msg-active");

                // Remove error message
                errorMessage.innerHTML = '';

                // Remove error class to the field
                field.classList.remove("gigya-error");
                field.classList.add("gigya-valid");

            }
        }, 100);
    //}
}
function showEmailErrorInForm (show, email, formDataElementName, event) {

    // Look for the field in the form
    const field = document.querySelector(`.gigya-screen[id] form .gigya-input-text[name="${formDataElementName}"]`);
    // Find the associated error message attached to that field
    const errorMessage = field.parentElement.querySelector('.gigya-error-msg');

    // Look for "@" symbol inside value
    const atSymbolIndex = email.indexOf("@");
    
    const invalidEmailMessage = getMessagesTranslationFor ('LABEL_167132628471258700_LABEL', event);
    const invalidEmailAtSymbolPendingMessage = getMessagesTranslationFor ('LABEL_43867410600231330_LABEL', event);
    
    let errorMessageText = invalidEmailMessage;
    // If "@" symbol is found, check if there is a "." after it
    if (atSymbolIndex === -1) {

        errorMessageText = errorMessageText + " " + invalidEmailAtSymbolPendingMessage;
    }

    setTimeout(function (event) {
        // If the field is not valid, show the error message
        if (show) {

            // Add few error classes in one line
            errorMessage.classList.add("gigya-error-msg-active");

            // Show error message
            errorMessage.innerHTML = errorMessageText;

            // Add error class to the field
            field.classList.add("gigya-error");

        } else {
            if(event.screen !== "gigya-register-screen"){
            // Remove few error classes in one line
            errorMessage.classList.remove("gigya-error-msg-active");

            // Remove error message
            errorMessage.innerHTML = '';
            // Remove error class to the field
            field.classList.remove("gigya-error");
            }
        }
    }, 100);



}


/** *****************************************************/
//  9. Phone Validity
/** *****************************************************/
function checkPhoneValidityOnly(prefix, phone) {

    let validPhone = true;

    // In function of the prefix, we check the only with specific validation (Spain)
    switch (prefix) {
        case "34":
        case "+34":
            // Check if phone number is valid
            const phoneRegExp = new RegExp("^[0-9]{9}$");
            if (!phoneRegExp.test(phone)) {
                validPhone = false;
            }
            break;
        default:
            // Check if phone number is valid
            const phoneRegGeneralExp = new RegExp("^[0-9]{1,30}$");
            if (!phoneRegGeneralExp.test(phone)) {
                validPhone = false;
            }
            break;
    }
    return validPhone; 
}
function checkPhoneValidity(prefix, phone, formDataElementName, event) {

    // Check if phone is valid
    const validPhone = checkPhoneValidityOnly(prefix, phone);
    cdcLog("X. Check Phone Validity for prefix: " + prefix + " and phone: " + phone + ": " + validPhone);

    // Show error in form if phone is not valid
    showErrorInForm(!validPhone, formDataElementName, event);

    return validPhone; 
}

/** *****************************************************/
//  9. Doc ID Validity
/** *****************************************************/
function checkNIFLetterValidity(nif) {
    var number, let2, letter;

    // Remove hyphen (if exists)
    nif = nif.split("-").join("");

    nif = nif.toUpperCase();

    number = nif.substring(0, nif.length - 1);
    number = number.replace('X', 0);
    number = number.replace('Y', 1);
    number = number.replace('Z', 2);
    let2 = nif.charAt(nif.length - 1);
    number = number % 23;
    letter = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letter = letter.substring(number, number + 1);
    if (letter != let2) {
        cdcLog("incorrect letter");
        return false;
    } else {
        cdcLog("correct letter");
        return true;
    }
}
function checkDocValidityOnly(docType, docNumber) {

    // Doc ID types: (^NIF$)|(^NIE$)|(^CIF$)|(^PASAPORTE$)|(^PASA$)|(^NIF COMUNITARIO$)|(^OTROS$)

    let validDoc = false;
    
    cdcLog("X. Check Doc Validity", "CHECK DOC VALIDITY");

    // Adding regexs for each type of document
    const NIFRegExp = /(\d{8})([-]?)([A-z]{1}$)/;
    const NIERegExp = /^[XYZ]{1}[0-9]{7}[A-Z]{1}$/;
    const CIFRegExp = /^[ABCDEFGHJKLMNPQRSUVW]{1}[0-9]{7}[0-9A-J]{1}$/;
    const PassportRegExp = /\S+/gms;
    // const NIFComunitarioRegExp = /^[A-Z]{2}[A-Z]?\d{1,12}[A-Z]?$/;
    const NIFComunitarioRegExp = /^.{1,16}$/;

    // Otros Regex. We check only if it is not empty
    const OtrosRegExp = /^.{1}$/;

    // const PassportRegExp = /^[A-z0-9]{2,3}[0-9]{6}$/;

    // Check if Doc ID is valid
    if (docType === "NIF" && docNumber.match(NIFRegExp)) {

        // Check NIF Letter validity
        validDoc = checkNIFLetterValidity(docNumber);
        if (validDoc) {
            cdcLog("NIF is valid");
        }
    }
    if (docType === "NIE" && docNumber.match(NIERegExp)) {
        cdcLog("NIE is valid");
        validDoc = true;
    }
    if (docType === "CIF" && docNumber.match(CIFRegExp)) {
        cdcLog("CIF is valid");
        validDoc = true;
    }
    if ((docType === "NIF_COMUNITARIO" || docType === "NIF COMUNITARIO") && docNumber.match(NIFComunitarioRegExp)) {
        cdcLog("NIF COMUNITARIO is valid");
        validDoc = true;
    }
    if ((docType === "PASAPORTE" || docType === "PASA") && docNumber.match(PassportRegExp)) {
        cdcLog("Passport is valid");
        validDoc = true;
    }
    if (docType === "OTROS" && docNumber.match(OtrosRegExp)) {
        cdcLog("Otros is valid");
        validDoc = true;
    }
    if (docType === "" && docNumber === "") {
        cdcLog("Empty is valid");
        validDoc = true;
    }

    return validDoc;
}
/**
 * Check the validity of the doc, either if it's NIF or passport.
 * @param {string} docIdType - the type of doc id
 * @param {string} docIdNumber - the doc id number
 * @returns {boolean} - true if the doc id is valid, false if not
 */
function checkDocValidity(docType, docNumber, formDataElementName, event) {
    
    // Check if doc is valid
    const validDoc = checkDocValidityOnly (docType, docNumber)    
    
    cdcLog("X. Check Doc Validity for docType: " + docType + " and docNumber: " + docNumber + ": " + validDoc);

    // Show errors in form if needed
    showErrorInForm(!validDoc, formDataElementName, event);

    return validDoc;
}
/** *****************************************************/
//  10. Zip Code Validity
/** *****************************************************/
function checkIfSpanishValidZipCodeOnly (provinceCode, zipCode) {

        // Check first two digits of zipCode
        const zipCodeFirstTwoDigits = zipCode.substring(0, 2);

        // Match these two digits with the province
        let match = false;
        if (provinceCode !== null) {
            match = zipCodeFirstTwoDigits === provinceCode;
        } else {

            // We check only the country in this case
            match = true;
        }
            

        // Match this regex ^[0-9]{5}(?:-[0-9]{4})?$
        const regex = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$');
        const matchRegex = regex.test(zipCode);

        cdcLog ("zipCode: " + zipCode + " province: " + provinceCode + " match: " + match + " matchRegex: " + matchRegex);
        return match && matchRegex;
}
function checkZipCodeValidityOnly(country, provinceCode, zipCode) {

    /** 
     * Confirmado logica para España.
     * Pendiente para el resto de paises.Que será como mucho una expresión regular.
     * Ponemos de inicio longitud alfanumerica de 9
    */
    
    
        let validZipCode = false;
    
        // Check if zip code is valid
        switch (country) {
            case "ES":
            case "es":
                const zipCodeRegExp = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
                
                if (zipCodeRegExp.test(zipCode)) {
    
                    // Recheck the validity of the pairs for Spain
                    validZipCode = checkIfSpanishValidZipCodeOnly(provinceCode, zipCode);
                }
                break;
            default:
    
                const zipCodeRegGeneralExp = /^[0-9A-Za-z\s\-]{1,10}$/;
                if (zipCodeRegGeneralExp.test(zipCode)) {
                    validZipCode = true;
                }
                break;
        }
    
        return validZipCode;
}
function checkZipCodeValidity(country, provinceCode, zipCode, formDataElementName, event) {

    /** 
     * Confirmado logica para España.
     * Pendiente para el resto de paises.Que será como mucho una expresión regular.
     * Ponemos de inicio longitud alfanumerica de 9
    */
    let validZipCode = checkZipCodeValidityOnly(country, provinceCode, zipCode);

    // update error message in screen if needed
    showErrorInForm(!validZipCode, formDataElementName, event); 

    return validZipCode;
}

/** *****************************************************/
//  8. Email Validity
/** *****************************************************/
function checkEmailValidityOnly (email) {

    let validEmail = false;
 
    // Regex to check if email is valid
    const emailRegex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{1,63}){0,})(\.[a-zA-Z0-9-]{2,63}){1,})$/g;
    // Check if Doc ID is valid
    if (email.match(emailRegex)) {

        // Check NIF Letter validity
        validEmail = true;
        if (validEmail) {
            cdcLog("Email is valid");
        }
    }

    return validEmail;
}
/**
 * This function checks if the email is valid or not.
 * @param {string} value - the email to check
 */
function checkEmailValidity(email, formDataElementName, event) {

    const validEmail = checkEmailValidityOnly (email);

    cdcLog("X. Check Email Validity for email: " + email + ": " + validEmail);

    // Show errors in form if needed
    showEmailErrorInForm(!validEmail, email, formDataElementName, event);

    return validEmail;
}

/** 
 * 10. Password validity
 */
function checkPasswordValidityOnly (password) {

    let validPassword = false;
    const passwordRegex = /^.*(?=.{8,100})(?=.*[-!#$%&\\\/()?¡_ñÑçÇ*@.])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).*$/;
    if (password.match(passwordRegex)) {
        validPassword = true;
    }
    return validPassword;
}
function checkPasswordValidity (password, formData, isLoginScreen, event) {

    const validPassword = checkPasswordValidityOnly (password);

    // Check if password is valid
    if (validPassword) {
        cdcLog("Password is valid");
    }

    // Show errors in form if needed if we are not in login screen (harvesting)
    if (!isLoginScreen) {
        showPasswordErrorInForm(!validPassword, formData, event);
    }

    return validPassword;
}









/** *****************************************************/
//  9. CAR Validity
/** *****************************************************/
function getCarRegistrationFixed (carRegistration) {
    return carRegistration.replace(/[-\s]/g, '').toUpperCase();
}
function checkNewRegistrationCarValidity(event) {

    let carRegistration = null;
    // Take active element (it's the non-hidden .gigya-array-template)

    // Check if event is a form submission or a single field change
    if (event.formData) {
        const formData = event.formData;
        // Take active element (it's the non-hidden .gigya-array-template)
        const activeElementName = document.querySelector('.gigya-array-template:not(.is-hidden)').getAttribute('data-array-template-id');
    
        // Get the last character from activeElementName
        const indexTrimmed = activeElementName.split('-');
        const indexAsString = indexTrimmed[indexTrimmed.length - 1];

        // Get the key for the car registration
        const keyForObject = 'data.vehicles.' + indexAsString + '.carRegistration';
        carRegistration = formData[keyForObject];
    } else {
        carRegistration = event.value;
    }

    // Remove hyphens and spaces from car registration
    const carRegistrationFixed = getCarRegistrationFixed (carRegistration);

    // Look for this car registration in the array of already registered cars
    let carRegistrationFound = false;
    
    // Check if event is a form submission or a single field change
    if (event.formData) {

        // Look for the car registration in the event
        carRegistrationFound = event.data.vehicles.find((car) => {
            return getCarRegistrationFixed(car.carRegistration) === carRegistrationFixed;   
        });
    } else {
            // Look for the car registration in the cache
            const vehicles = window.__USER_VEHICLES ? window.__USER_VEHICLES : [];
        
            carRegistrationFound = vehicles.find((car) => {
                return getCarRegistrationFixed(car.carRegistration) === carRegistrationFixed;
            });
    }
    const carRegistrationErrorHTMLElement = document.querySelector('.gigya-array-template:not(.is-hidden) .gigya-error-msg');

    // If car registration is found, show error
    if (carRegistrationFound) {
        // Get current language to take the proper translations
        let invalidCarRegistrationMessage = getMessagesTranslationFor ('LABEL_74671088593565860_LABEL', event);
        invalidCarRegistrationMessage = invalidCarRegistrationMessage ? invalidCarRegistrationMessage : 'La matrícula ya existe';

        // Show Error in form
        setTimeout(() => {
            // Set classes for email advice
             if (carRegistrationErrorHTMLElement) {
                carRegistrationErrorHTMLElement.classList.add("is-error-visible");
                carRegistrationErrorHTMLElement.innerHTML = invalidCarRegistrationMessage;
    
                carRegistrationErrorHTMLElement.classList.add("gigya-error-msg-active");
                carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.add("gigya-error");
                carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.remove("gigya-valid");    
            }

        }, 400);

        return false;

    } else {
        // Hide Error in form
        // Set classes for email advice
        carRegistrationErrorHTMLElement.classList.remove("is-error-visible");
        carRegistrationErrorHTMLElement.innerHTML = '';

        // Show Error in form

        carRegistrationErrorHTMLElement.classList.remove("gigya-error-msg-active");
        carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.remove("gigya-error");
        carRegistrationErrorHTMLElement.parentElement.querySelector("input").classList.add("gigya-valid");

        return true;
    }

}
function fixCarRegistrationIfNeeded(event) {

    const formData = event.formModel.data;

    const accountInfo = event.accountInfo;
    const storedVehicles = accountInfo.data && accountInfo.data.vehicles ? accountInfo.data.vehicles : null;


    // Take active element (it's the non-hidden .gigya-array-template)
    const activeElementName = document.querySelector('.gigya-array-template:not(.is-hidden)').getAttribute('data-array-template-id');

    // Get the last character from activeElementName
    const indexTrimmed = activeElementName.split('-');
    const index = indexTrimmed[indexTrimmed.length - 1];

    // Get the car registration
    const carRegistration = formData.vehicles[index].carRegistration;

    // Remove hyphens and spaces from car registration
    const carRegistrationFixed = getCarRegistrationFixed(carRegistration)

    // Set the fixed car registration
    formData.vehicles[index].carRegistration = carRegistrationFixed;

    // If the car had a ECO Label from the previos data, take it as part of the submission
    if (storedVehicles) {
        
        // Take the index of storedVehicles and iterate over it
        for (let i = 0; i < storedVehicles.length; i++) {
            const storedVehicle = storedVehicles[i];
            if (storedVehicle.ECOLabel && formData.vehicles[i]) {
                formData.vehicles[i].ECOLabel = storedVehicle.ECOLabel;
            }
        }
    }

    return formData;
}

/**
 * This function cleans the doc id number error messages.
 */
function cleanDocIDErrorValues() {

    // Clean general error message for these fields
    let errorHTMLElement = document.querySelector('.gigya-screen[id] form .form-error-field');
    let errorHTMLTextElement = document.querySelector('.gigya-screen[id] form .form-error-field .gigya-error-msg');

    // Clean general error message for general error message
    errorHTMLTextElement.innerHTML = '';
    errorHTMLElement.classList.remove("is-error-visible");
    errorHTMLTextElement.classList.remove("is-error-visible");

    // Clean error message for specific error message on document type number
    let currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-update-profile-screen .gigya-input-text[name="data.docID.number"]');

    if (!currentDocIDNumberErrorMsgContainer) {
        currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-register-screen .gigya-input-text[name="data.docID.number"]');
    }
    if (!currentDocIDNumberErrorMsgContainer) {
        currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-complete-registration-screen .gigya-input-text[name="data.docID.number"]');
    }

    // Add few error classes in one line
    const currentDocIDNumberErrorMsg = currentDocIDNumberErrorMsgContainer.nextElementSibling;

    if (currentDocIDNumberErrorMsg) {
        currentDocIDNumberErrorMsg.classList.remove("gigya-error-msg", "gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");
        currentDocIDNumberErrorMsg.innerHTML = '';
    }

}
/**
 * This function shows the error message for the doc id number HTML element.
 */
function showDocIDErrorValuesOnFields(event) {

    setTimeout(function () {

        // Show general error message for these fields
        let currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-register-screen .gigya-input-text[name="data.docID.number"]');

        if (!currentDocIDNumberErrorMsgContainer) {
            currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-complete-registration-screen .gigya-input-text[name="data.docID.number"]');
        }

        if (!currentDocIDNumberErrorMsgContainer) {
            currentDocIDNumberErrorMsgContainer = document.querySelector('#gigya-update-profile-screen .gigya-input-text[name="data.docID.number"]');
        }

        // Add few error classes in one line
        const currentDocIDNumberErrorMsg = currentDocIDNumberErrorMsgContainer.nextElementSibling;

        // Add few error classes in one line
        currentDocIDNumberErrorMsg.classList.add("gigya-error-msg", "gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");

        // Get appropiate error message (from translations)
        const errorMessage = getMessagesTranslationFor ('INVALID_FIELDNAME', event);

        currentDocIDNumberErrorMsg.innerHTML = errorMessage ? errorMessage : "This value is not valid";

    }, 1000);
}

/** *****************************************************/
//  9. Doc ID Validity
/** *****************************************************/

function updatePhoneErrorMessage(isValid, field, event) {

    // Show error message
    setTimeout(function () {


        // Take the index of the array from the name of the field. Example: data.addresses[2].id is 2
        const index = field.split('[')[1].split(']')[0];


        // Take phone error label value
        const phoneErrorLabel = document.querySelector("#gigya-update-addresses-screen .phone-error-label");
        const phoneErrorLabelText = phoneErrorLabel.innerText;

        // Get appropiate error message (from translations)
        const errorMessage = getMessagesTranslationFor ('INVALID_FIELDNAME', event);


        // Take phone error CDC component
        const phoneErrorCDC = document.querySelector("#gigya-update-addresses-screen span[data-bound-to='data.addresses[" + index + "].phone']");
        const phoneErrorCDCInput = document.querySelector("#gigya-update-addresses-screen input[name='data.addresses[" + index + "].phone']");
        


        // Check if valid and update the html
        if (isValid) {
            cdcLog("phone is valid");
            phoneErrorCDC.innerText = '';
            phoneErrorCDC.classList.remove("gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");
            phoneErrorCDCInput.classList.remove("gigya-error", "aena-error");
            phoneErrorCDCInput.classList.add("gigya-show-checkmark", "gigya-valid");
        } else {
            cdcLog("phone is not valid");
            // phoneErrorCDC.innerText = errorMessage + " " + phoneErrorLabelText;
            phoneErrorCDC.innerText = errorMessage;
            phoneErrorCDC.classList.add("gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");
            phoneErrorCDCInput.classList.add("gigya-error", "aena-error");
            phoneErrorCDCInput.classList.remove("gigya-show-checkmark", "gigya-valid");
        }

    }, 100);
}
function updateDocErrorMessage(isValid, field, value, event) {

    // Show error message
    setTimeout(function () {


        // Take the index of the array from the name of the field. Example: data.addresses[2].id is 2
        const index = field.split('[')[1].split(']')[0];

        // Get appropiate error message (from translations)
        const errorMessage = getMessagesTranslationFor ('INVALID_FIELDNAME', event);

        // Take phone error CDC component
        const docErrorCDC = document.querySelector("#gigya-update-addresses-screen span[data-bound-to='data.addresses[" + index + "].docNumber']");
        const docErrorCDCInput = document.querySelector("#gigya-update-addresses-screen input[data-gigya-name='data.addresses[" + index + "].docNumber']");
        


        // Check if valid and update the html
        if (isValid) {
            cdcLog("doc is valid");
            docErrorCDC.innerText = '';
            docErrorCDC.classList.remove("gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");
            docErrorCDCInput.classList.remove("gigya-error", "gigya-show-checkmark");
            docErrorCDCInput.classList.remove("gigya-error");
            docErrorCDCInput.classList.add("gigya-show-checkmark", "gigya-valid");
        } else {
            cdcLog("doc is not valid: %o:", field);
            // docErrorCDC.innerText = errorMessage + " " + docErrorLabelText;
            docErrorCDC.innerText = errorMessage; //  + '(' + value + ')';
            docErrorCDC.classList.add("gigya-error-msg-active", "gigya-error-code-400009", "gigya-error-type-server");
            docErrorCDCInput.classList.add("gigya-error");
            docErrorCDCInput.classList.remove("gigya-show-checkmark", "gigya-valid");
        }

    }, 100);
}

function validateAddressFields(event) {

    // Take fields from the form
    const field = event.field;

    // Take the index of the array from the name of the field. Example: data.addresses[2].id is 2
    const index = field.split('[')[1].split(']')[0];

    // Take prefix and phone values from array
    const docType = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].docType']").value;
    const docNumber = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].docNumber']").value;

    const country = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].country']").value;
    const province = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].province']").value;
    const zipCode = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].zipCode']").value;
    // Check the validity of the zip code
    
    
    // Take prefix and phone values from array
    const prefix = document.querySelector("#gigya-update-addresses-screen select[name='data.addresses[" + index + "].prefix']").value;
    const phone = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].phone']").value;

    let phoneValid = true;
    let docValid = true;
    let zipCodeValid = true;

    // Check the validity of the pairs
    if (field.endsWith(".zipCode") || field.endsWith(".province") || field.endsWith(".country")) {
        zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'data.addresses[' + index + '].zipCode', event);
        cdcLog ("Código postal español. Valores: provincia: " + province + " zipCode: " + zipCode + " match: " + zipCodeValid);
    }   
   
    if (field.endsWith(".prefix") || field.endsWith(".phone")) {
        phoneValid = checkPhoneValidity(prefix, phone, 'data.addresses[' + index + '].phone', event);
        cdcLog ("Teléfono. Valores: prefix: " + prefix + " phone: " + phone + " match: " + phoneValid);
    }

    if (field.endsWith(".docType") || field.endsWith(".docNumber")) {
        docValid = checkDocValidity(docType, docNumber, 'data.addresses[' + index + '].docNumber', event);        
        cdcLog ("Documento . Valores: docType: " + docType + " docNumber: " + docNumber + " match: " + docValid);
    }

    return zipCodeValid && phoneValid && docValid;
}

// TODO: No usada?
function updateZipCodeErrorMessage (isValid, index, event) {

    // Take the error message element
    const zipCodeElement = document.querySelector("#gigya-update-addresses-screen .gigya-input-text[name='data.addresses[" + index + "].zipCode']");
    const errorMessageElement = zipCodeElement.parentElement.querySelector(".gigya-error-msg");
    // Get appropiate error message (from translations)
    const errorMessage = getMessagesTranslationFor ('INVALID_FIELDNAME', event);

    // If it is valid, remove error message and add valid class
    if (isValid) {
        errorMessageElement.classList.remove("gigya-error-msg-active");
        errorMessageElement.innerHTML = "";
        zipCodeElement.classList.remove("gigya-error");
        zipCodeElement.classList.add("gigya-valid");
    } else {
        errorMessageElement.classList.add("gigya-error-msg-active");
        errorMessageElement.innerHTML = errorMessage;
        zipCodeElement.classList.add("gigya-error");
        zipCodeElement.classList.remove("gigya-valid");
    }
}
function updateAddressesInterface(addressIndexAsNumber, checked) {

    const addressElementsInDOM = document.querySelectorAll("#gigya-view-addresses-screen .gigya-array-template");

    // Remove all selected classes dfor addressEleementsInDOM
    addressElementsInDOM.forEach((addressElement, index) => {
        addressElement.classList.remove("selected");

        // Remove checked from children
        const checkbox = addressElement.querySelector(".main-address-checkbox input");
        if (checkbox.checked && index !== addressIndexAsNumber) {
            checkbox.click();
            cdcLog("paso con index:", index);
        }
    });

    // If we are setting a new address, mark it as selected
    if (checked) {

        // Find the main address element in the DOM
        const mainAddressElementInDOM = document.querySelector("#gigya-view-addresses-screen .gigya-array-template[data-array-template-id$='" + addressIndexAsNumber + "']");

        // Add active class
        mainAddressElementInDOM.classList.add("selected");
    }
}

function checkNewAddressValidity(event) {
    const formData = event.formData;
    cdcLog(formData);

    // Get Doc type & and Doc Number value
    const activeElementName = document.querySelector('.gigya-array-template:not(.is-hidden)').getAttribute('data-array-template-id');

    // Get the last character from activeElementName
    const indexTrimmed = activeElementName.split('-');
    const index = indexTrimmed[indexTrimmed.length - 1];

    const docType = formData['data.addresses.' + index + '.docType'];
    const docNumber = formData['data.addresses.' + index + '.docNumber'];

    // Get The phone and the prefix
    const prefix = formData['data.addresses.' + index + '.prefix'];
    const phone = formData['data.addresses.' + index + '.phone'];

    // Check the validity of the zip code
    const country = formData['data.addresses.' + index + '.country'];
    const province = formData['data.addresses.' + index + '.province'];
    const zipCode = formData['data.addresses.' + index + '.zipCode'];
    
    // Check the validity of the pairs
    const docValid = checkDocValidity(docType, docNumber, 'data.addresses[' + index + '].docNumber', event);
    const phoneValid = checkPhoneValidity(prefix, phone, 'data.addresses[' + index + '].phone', event);
    const zipCodeValid = checkZipCodeValidity(country, province, zipCode, 'data.addresses[' + index + '].zipCode', event);
    
     // We get the selector for the first invalid field. The order is important here!!, and Gigya errors take here place as well
    // The order is:

    // 1. Alias
    // 2. Nombre or Razón Social
    // 3. Doc Type
    // 4. Doc Number
    // 5. Address
    // 6. Country
    // 7. Province
    // 8. City
    // 9. Zip Code
    // 10. Prefix
    // 11. Phone

    // Errors from Gigya, if they are, are coming into event.formErrors
    const formErrors = event.formErrors;
    const formErrorKeys = Object.keys(formErrors);
    cdcLog ("formErrors: " + formErrorKeys.toString());
    let selector = null;
    let selectorElement = null
    
    if (formErrorKeys.indexOf('data.addresses[' + index + '].id') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].id';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].fullName') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].fullName';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].docType') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].docType';  
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].docNumber') === 0  || !docValid) {
        selectorElement = 'data.addresses[' + index + '].docNumber';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].address') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].address';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].country') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].country';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].province') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].province';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].city') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].city';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].zipCode') === 0  || !zipCodeValid) {
        selectorElement = 'data.addresses[' + index + '].zipCode';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].prefix') === 0  || !phoneValid) {
        selectorElement = 'data.addresses[' + index + '].prefix';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].phone') === 0  || !phoneValid) {
        selectorElement = 'data.addresses[' + index + '].phone';
    } else {
        selectorElement = null;
    }

    const gigyaElementsValid = selectorElement === null;
    const formValid = gigyaElementsValid && docValid && phoneValid && zipCodeValid;
    return formValid;
}

function checkNewAddressValidityOnly(event) {
    const formData = event.formData;
    // cdcLog(formData);

    // Get Doc type & and Doc Number value
    const activeElementName = document.querySelector('.gigya-array-template:not(.is-hidden)').getAttribute('data-array-template-id');

    // Get the last character from activeElementName
    const indexTrimmed = activeElementName.split('-');
    const index = indexTrimmed[indexTrimmed.length - 1];

    const docType = formData['data.addresses.' + index + '.docType'];
    const docNumber = formData['data.addresses.' + index + '.docNumber'];

    // Get The phone and the prefix
    const prefix = formData['data.addresses.' + index + '.prefix'];
    const phone = formData['data.addresses.' + index + '.phone'];

    // Check the validity of the zip code
    const country = formData['data.addresses.' + index + '.country'];
    const province = formData['data.addresses.' + index + '.province'];
    const zipCode = formData['data.addresses.' + index + '.zipCode'];

    // Check the validity of the pairs
    const isDocValid = checkDocValidityOnly(docType, docNumber, 'data.addresses[' + index + '].docNumber', event);
    const isPhoneValid = checkPhoneValidityOnly(prefix, phone, 'data.addresses[' + index + '].phone', event);
    const isZipCodeValid = checkZipCodeValidityOnly(country, province, zipCode, 'data.addresses[' + index + '].zipCode', event);

    // Check if we have errors from Gigya
    const hasGigyaErrors = event.formErrors !== undefined;

    return isDocValid && isPhoneValid && isZipCodeValid && !hasGigyaErrors;
    

}

function getSelectorForNewAddressValidity(event) {
    const formData = event.formData;

    // Get Doc type & and Doc Number value
    const activeElementName = document.querySelector('.gigya-array-template:not(.is-hidden)').getAttribute('data-array-template-id');

    // Get the last character from activeElementName
    const indexTrimmed = activeElementName.split('-');
    const index = indexTrimmed[indexTrimmed.length - 1];

    const docType = formData['data.addresses.' + index + '.docType'];
    const docNumber = formData['data.addresses.' + index + '.docNumber'];
    
    // Check the validity of the zip code
    const country = formData['data.addresses.' + index + '.country'];
    const province = formData['data.addresses.' + index + '.province'];
    const zipCode = formData['data.addresses.' + index + '.zipCode'];

    // Get The phone and the prefix
    const prefix = formData['data.addresses.' + index + '.prefix'];
    const phone = formData['data.addresses.' + index + '.phone'];

    // Check the validity of the pairs
    const docValid = checkDocValidityOnly(docType, docNumber, 'data.addresses[' + index + '].docNumber', event);
    const zipCodeValid = checkZipCodeValidityOnly(country, province, zipCode, 'data.addresses[' + index + '].zipCode', event);
    const phoneValid = checkPhoneValidityOnly(prefix, phone, 'data.addresses[' + index + '].phone', event);
    
    // We get the selector for the first invalid field. The order is important here!!, and Gigya errors take here place as well
    // The order is:

    // 1. Alias
    // 2. Nombre or Razón Social
    // 3. Doc Type
    // 4. Doc Number
    // 5. Address
    // 6. Country
    // 7. Province
    // 8. City
    // 9. Zip Code
    // 10. Prefix
    // 11. Phone

    // Errors from Gigya, if they are, are coming into event.formErrors
    const formErrors = event.formErrors;
    const formErrorKeys = Object.keys(formErrors);
    cdcLog ("formErrors: " + formErrorKeys.toString());
    let selector = null;
    let selectorElement = null
    
    if (formErrorKeys.indexOf('data.addresses[' + index + '].id') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].id';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].fullName') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].fullName';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].docType') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].docType';  
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].docNumber') === 0  || !docValid) {
        selectorElement = 'data.addresses[' + index + '].docNumber';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].address') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].address';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].country') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].country';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].province') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].province';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].city') === 0 ) {
        selectorElement = 'data.addresses[' + index + '].city';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].zipCode') === 0  || !zipCodeValid) {
        selectorElement = 'data.addresses[' + index + '].zipCode';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].prefix') === 0  || !phoneValid) {
        selectorElement = 'data.addresses[' + index + '].prefix';
    } else if (formErrorKeys.indexOf('data.addresses[' + index + '].phone') === 0  || !phoneValid) {
        selectorElement = 'data.addresses[' + index + '].phone';
    } else {
        selectorElement = null;
    }
    
    // If there are no errors from Gigya, we check the validity of the fields
    if (selectorElement) {
        selector = ".gigya-screen[id] form [name='" + selectorElement + "']"
    }
    return selector;
}



function recheckField(isValid, fieldName, event) {

    cdcLog("recheckField: " + fieldName + " isValid: " + isValid);
    // Look for the field in the DOM
    const fieldElement = document.querySelector(".gigya-screen[id] form [name='" + fieldName + "']");

    // Check if the field is valid for Gigya
    const isGigyaValid = fieldElement.classList.contains("gigya-valid");
    
    // If it's gigya valid, but has aena error, we set it as invalid, removing gigya-valid class and setting gigya-error class
    if (isGigyaValid & !isValid) {
        
        // Remove gigya-valid class
        fieldElement.classList.remove("gigya-valid");

        // Add gigya-error class
        fieldElement.classList.add("gigya-error");

        // Update error message
        // const errorMessage = getMessagesTranslationFor ('INVALID_FIELDNAME', event);
        // errorMessageElement.classList.add("gigya-error-msg-active");
        // errorMessageElement.innerHTML = errorMessage;
    }

    // If it's gigya invalid, but has no aena error, we set it as valid, removing gigya-error class and setting gigya-valid class
    if (isValid) {
            
            // Remove gigya-error class
            fieldElement.classList.remove("gigya-error");
    
            // Add gigya-valid class
            fieldElement.classList.add("gigya-valid");
    
            // Update error message
            // errorMessageElement.classList.remove("gigya-error-msg-active");
            // errorMessageElement.innerHTML = "";
        }
}
/** *****************************************************/
//  7. Password widget
/** *****************************************************/
/**
 * This function shows or hide the password messages for the password field.
 * @param {bool} show - true to show, false to hide
 */
function showPasswordElements(show) {

    // Get HTML password advice elements
    let passwordAdviceElements = document.querySelectorAll(".gigya-screen[id] form .password-advice");


    if (show) {
        // Check in all passwordAdviceElements if hidden, if yes, show
        passwordAdviceElements.forEach(passwordAdviceElement => {
            if (!passwordAdviceElement.classList.contains("is-visible")) {
                passwordAdviceElement.classList.add("is-visible");
            }
        });
    } else {
        // Check in all passwordAdviceElements if visible, if yes, hide
        passwordAdviceElements.forEach(passwordAdviceElement => {
            if (passwordAdviceElement.classList.contains("is-visible")) {
                passwordAdviceElement.classList.remove("is-visible");
            }
        });
    }
}
/**
 * This function updates the password messages for the password widget, to show if the password is strong or not.
 * @param {string} password - the password
 */
function updatePasswordWidget(formDataElementName, password) {

    cdcLog("X. Update Password Widget", "UPDATE PASSWORD WIDGET");

    // Initialize HTML elements
    let minMaxLengthHTMLElement = document.querySelector('.gigya-screen[id] form .password-advice-minimum');
    let hasUpperCaseHTMLElement = document.querySelector('.gigya-screen[id] form .password-advice-uppercase');
    let hasLowerCaseHTMLElement = document.querySelector('.gigya-screen[id] form .password-advice-lowercase');
    let hasNumbersHTMLElement = document.querySelector('.gigya-screen[id] form .password-advice-numbers');
    let hasSpecialCharactersHTMLElement = document.querySelector('.gigya-screen[id] form .password-advice-special-chars');


    // const hasEqualPasswordsHTMLElement = document.querySelector ('.password-advice-equal-passwords');

    // Check password and password confirm to fulfill the rules
    const passwordLength = password.length;
    const hasMinimumMaximumPasswordLength = passwordLength >= 8 && passwordLength <= 100;
    const hasUpperCaseLetterExpression = password.match(/[A-Z]/);
    const hasLowerCaseLetterExpression = password.match(/[a-z]/);
    const hasNumbersExpression = password.match(/[0-9]/);
    const hasSpecialCharactersExpression = password.match(/[-!#$%&\\\/()?¡_ñÑçÇ*@.]/);

    hasUpperCaseLetter = hasUpperCaseLetterExpression ? true : false;
    hasLowerCaseLetter = hasLowerCaseLetterExpression ? true : false;
    hasNumbers = hasNumbersExpression ? true : false;
    hasSpecialCharacters = hasSpecialCharactersExpression ? true : false;


    // Set classes for password advice
    hasMinimumMaximumPasswordLength ? minMaxLengthHTMLElement.classList.add("is-valid") : minMaxLengthHTMLElement.classList.remove("is-valid");
    hasUpperCaseLetter ? hasUpperCaseHTMLElement.classList.add("is-valid") : hasUpperCaseHTMLElement.classList.remove("is-valid");
    hasLowerCaseLetter ? hasLowerCaseHTMLElement.classList.add("is-valid") : hasLowerCaseHTMLElement.classList.remove("is-valid");
    hasNumbers ? hasNumbersHTMLElement.classList.add("is-valid") : hasNumbersHTMLElement.classList.remove("is-valid");
    hasSpecialCharacters ? hasSpecialCharactersHTMLElement.classList.add("is-valid") : hasSpecialCharactersHTMLElement.classList.remove("is-valid");
    // password === passwordConfirm ? hasEqualPasswordsHTMLElement.classList.add("is-valid") : hasEqualPasswordsHTMLElement.classList.remove("is-valid");

    // Check if all password advice are valid
    const isPasswordValid = hasMinimumMaximumPasswordLength && hasUpperCaseLetter && hasLowerCaseLetter && hasNumbers && hasSpecialCharacters;

    // Update eyes icon component with the password status
    // Look for the field in the form
    const field = document.querySelector(`.gigya-screen[id] form .gigya-input-password[name="${formDataElementName}"]`);

    // Find the associated error message attached to that field
    const eyeElement = field.parentElement.querySelector(".eye-password-icon");
                
    if (isPasswordValid) {

        // Look for the eye icon and add success class
        eyeElement.classList.add("success-eye");
        eyeElement.classList.remove("error-eye");
        field.classList.remove("gigya-error");
        field.classList.add("gigya-valid");


    } else {
        // Look for the eye icon and add error class
        eyeElement.classList.add("error-eye");
        eyeElement.classList.remove("success-eye");
        field.classList.add("gigya-error");
        field.classList.remove("gigya-valid");

        
    }
    // Show password elements if hidden in the screen and password is not valid
    showPasswordElements(!isPasswordValid);

}
/** **************************************************/
//  12. Eye Functions
/** **************************************************/
function initializeEyesComponent(event) {

    cdcLog('Initializing eyes component...');

    // Take HTML elements
    const passwordHTMLElements = document.querySelectorAll('.gigya-screen[id] form .gigya-composite-control-password input');

    // Add the eye class to all the password elements
    // Add an id to all the password elements
    passwordHTMLElements.forEach((element, index) => {
        
        const dataScreensetElementId = element.getAttribute('data-screenset-element-id');
        if (dataScreensetElementId != '') {
            element.classList.add('eye-password');
            const eyeIcon = renderEye(dataScreensetElementId);
            element.insertAdjacentElement('afterend', eyeIcon);

        }
    });

    // Preload icon images
    const preloadImage1 = new Image();
    preloadImage1.src = `${_aena_gig_raas_loginUrl}/assets/raas/seen-green.svg`;
    const preloadImage2 = new Image();
    preloadImage2.src = `${_aena_gig_raas_loginUrl}/assets/raas/seen-red.svg`;
    const preloadImage3 = new Image();
    preloadImage3.src = `${_aena_gig_raas_loginUrl}/assets/raas/unseen-green.svg`;
    const preloadImage4 = new Image();
    preloadImage4.src = `${_aena_gig_raas_loginUrl}/assets/raas/unseen-red.svg`;
    


}
function renderEye(dataScreensetElementId) {

    // Creating element
    const eyeElement = document.createElement('div');
    eyeElement.classList.add('eye-password-icon');
    eyeElement.classList.add('disabled-eye');
    eyeElement.id = 'eye_icon_for_' + dataScreensetElementId;
    // eyeElement.src = disabledEyeAsBase64Image;
    eyeElement.onclick = () => {

        const passwordElements = document.querySelectorAll('input[data-screenset-element-id="' + dataScreensetElementId + '"]');

        // Take active html Elements
        const activePasswordHTMLElements = [];
        passwordElements.forEach(element => {
            const idParent = element.parentElement.parentElement.parentElement.parentElement.id;
            if (idParent !== '') {
                activePasswordHTMLElements.push(element);
            }
        });
        const password = activePasswordHTMLElements[0];

        if (password.type === 'password') {
            password.type = 'text';
            eyeElement.classList.add('enabled-eye');
            eyeElement.classList.remove('disabled-eye');
        } else {
            password.type = 'password';
            eyeElement.classList.add('disabled-eye');
            eyeElement.classList.remove('enabled-eye');
        }
    }
    return eyeElement;
}
function updatePasswordEyeState (event) {
    
    // Take correspondent eye
    const field = event.field;
    const value = event.value;

    const htmlField = document.querySelector(`.gigya-screen[id] form [name="${field}"]`);
    const parentHtmlField = htmlField.parentElement;

    // Check the status of the password field
    const parentHtmlFieldStatus = parentHtmlField.classList.contains("error");

    // Take the eye
    const eyeElement = parentHtmlField.querySelector(".eye-password-icon");
    if (event.isValid === false || parentHtmlFieldStatus) {
        
        eyeElement.classList.add("error-eye");

        // Remove success eye if it is a login (harvesting)
        // if (!isLogin) {
            eyeElement.classList.remove("success-eye");
       // }

    } else {
        
        eyeElement.classList.remove("error-eye");
        
        // Add success eye if it is a login (harvesting)
        // if (!isLogin) {
            eyeElement.classList.add("success-eye");
        // }
    }
    

}
/** *****************************************************/
//  6. Selectors & autocomplete
/** *****************************************************/
function sortSelectOptions(elem) {
    var tmpAry = [];
    // Retain selected value before sorting
    var selectedValue = elem[elem.selectedIndex].value;
    // Grab all existing entries
    for (var i=0;i<elem.options.length;i++) tmpAry.push(elem.options[i]);
    // Sort array by text attribute
    tmpAry.sort((a,b) => { return a.text.localeCompare(b.text)});
    // Wipe out existing elements
    while (elem.options.length > 0) elem.options[0] = null;
    // Restore sorted elements
    var newSelectedIndex = 0;
    for (var i=0;i<tmpAry.length;i++) {
        elem.options[i] = tmpAry[i];
        if(elem.options[i].value == selectedValue) newSelectedIndex = i;
    }
    elem.selectedIndex = newSelectedIndex; // Set new selected index after sorting
    return;
}
function closeAllSelectsExcept(clickedElement) {
    var allSelectItems = document.getElementsByClassName("select-items");
    var allSelectedItems = document.getElementsByClassName("select-selected");

    for (var i = 0; i < allSelectedItems.length; i++) {
        if (clickedElement != null && clickedElement == allSelectedItems[i]) {
            continue;
        }
        allSelectedItems[i].classList.remove("select-arrow-active");
    }

    for (var i = 0; i < allSelectItems.length; i++) {
        if (allSelectItems[i] == clickedElement) {
            continue;
        }
        allSelectItems[i].classList.add("select-hide");
    }
}
function removeOldSelectors() {
    var oldSelectItems = document.querySelectorAll('.select-items');
    var oldSelectedItems = document.querySelectorAll('.select-selected');

    for (var i = 0; i < oldSelectItems.length; i++) {
        oldSelectItems[i].remove();
    }

    for (var i = 0; i < oldSelectedItems.length; i++) {
        oldSelectedItems[i].remove();
    }
}
function createCustomSelectOption(originalSelectElement, selectItemsDiv) {
    for (var i = 0; i < originalSelectElement.length; i++) {
        var optionDiv = document.createElement("DIV");
        optionDiv.setAttribute('data-index', i);
        optionDiv.innerHTML = originalSelectElement.options[i].innerHTML;
        if (optionDiv.innerHTML === '') {
            optionDiv.classList.add('empty');
        }

        optionDiv.addEventListener("click", function (e) {
            updateOriginalSelect(this, originalSelectElement);
            selectItemsDiv.classList.toggle("select-hide");
            originalSelectElement.dispatchEvent(new Event('change'));
        });


        optionDiv.addEventListener('keydown', function(e) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                var nextOption = this.nextSibling;
                if (nextOption) {
                    updateOriginalSelect(nextOption, originalSelectElement);
                    nextOption.focus();
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                var prevOption = this.previousSibling;
                if (prevOption) {
                    updateOriginalSelect(prevOption, originalSelectElement);
                    prevOption.focus();
                }
            }
        });

        selectItemsDiv.appendChild(optionDiv);
    }
}
function updateOriginalSelect(clickedOption, originalSelectElement) {
    var selectedDiv = clickedOption.parentNode.previousSibling;
    for (var i = 0; i < originalSelectElement.length; i++) {
        if (originalSelectElement.options[i].innerHTML == clickedOption.innerHTML) {
            originalSelectElement.selectedIndex = i;
            selectedDiv.innerHTML = clickedOption.innerHTML;
            selectedDiv.setAttribute("data-selectedIndex", i);
            var selectedOptions = clickedOption.parentNode.getElementsByClassName("same-as-selected");
            for (var j = 0; j < selectedOptions.length; j++) {
                selectedOptions[j].removeAttribute("class");
            }
            clickedOption.setAttribute("class", "same-as-selected");
            break;
        }
    }
    // Check if the content is empty and add 'empty' class if it is
    if (selectedDiv.innerHTML === '') {
        selectedDiv.classList.add('empty');
    } else {
        selectedDiv.classList.remove('empty');
    }
}
function initializeSelectors() {
    const selectorsInitialized = document.querySelector('.select-items.select-hide') !== null;
    if (selectorsInitialized) {
        removeOldSelectors();
    }

    var dropdownSelectors = document.querySelectorAll('.gigya-screen[id] form .gigya-composite-control-dropdown');
    for (var i = 0; i < dropdownSelectors.length; i++) {
        var originalSelectElement = dropdownSelectors[i].getElementsByTagName("select")[0];
        if (originalSelectElement === undefined) {
            continue;
        }

        // Ordenar las opciones del select original
        sortSelectOptions(originalSelectElement);

        originalSelectElement.style.display = "none";
        var selectedDiv = document.createElement("DIV");
        selectedDiv.setAttribute("class", "select-selected");
        selectedDiv.setAttribute("tabindex", "0");
        selectedDiv.setAttribute("data-selectedIndex", originalSelectElement.selectedIndex);
        selectedDiv.innerHTML = originalSelectElement.options[originalSelectElement.selectedIndex] ? originalSelectElement.options[originalSelectElement.selectedIndex].innerHTML : '';
        if (selectedDiv.innerHTML === '') {
            selectedDiv.classList.add('empty');
        }
        dropdownSelectors[i].appendChild(selectedDiv);

        var selectItemsDiv = document.createElement("DIV");
        selectItemsDiv.setAttribute("class", "select-items select-hide");
        createCustomSelectOption(originalSelectElement, selectItemsDiv);

        // Insert the custom select before the error message span
        var errorMsgSpan = dropdownSelectors[i].getElementsByClassName("gigya-error-msg")[0];
        dropdownSelectors[i].insertBefore(selectedDiv, errorMsgSpan);
        dropdownSelectors[i].insertBefore(selectItemsDiv, errorMsgSpan);
   
        selectedDiv.addEventListener("click", function (e) {
            e.stopPropagation();
            if (!this.nextSibling.classList.contains("select-hide")) {
                closeAllSelectsExcept(null);
            } else {
                closeAllSelectsExcept(this);
                this.nextSibling.classList.toggle("select-hide");
                this.classList.toggle("select-arrow-active");
            }
            if (this.innerHTML === '') {
                this.classList.add('empty');
            } else {
                this.classList.remove('empty');
            }
        });

        selectedDiv.addEventListener("keydown", function(e) {
            if(e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                this.nextSibling.classList.remove("select-hide");
                this.classList.add("select-arrow-active");
                var firstOption = this.nextSibling.querySelector('div');
                if (firstOption) {
                    updateOriginalSelect(firstOption, originalSelectElement);
                    firstOption.focus();
                }
            }
        }, true);
        
    }

    document.addEventListener("click", closeAllSelectsExcept);
}
/** *****************************************************/
//             ADDRESSES & VEHICLES SCREENSETS
/** *****************************************************/

// Actions from the screenSet

// Add
function addNewAddressWithRaaS() {

    // Look for the container where the screen will be shown
    const editProfileContainerElement = document.querySelector("#billingInfo-container");

    let editProfileContainerId = 'billingInfo-container';
    if (!editProfileContainerElement) {
        editProfileContainerId = 'edit_profile_placeholder';
    }

    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-update-addresses-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId, // <-- if we omit this property the screen it's shown in a pop-up
        context: {
            action: 'add'
        }
    });
}
function addNewVehicleWithRaaS() {

    // Look for the container where the screen will be shown
    const editProfileContainerElement = document.querySelector("#vehiclesInfo-container");
    let editProfileContainerId = 'vehiclesInfo-container';

    if (!editProfileContainerElement) {
        editProfileContainerId = 'edit_profile_placeholder';
    }

    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-update-vehicles-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId, // <-- if we omit this property the screen it's shown in a pop-up
        context: {
            action: 'add'
        }
    });
}

// Edit
function editAddressWithRaaS(element, index) {

    // Look for the container where the screen will be shown
    const editProfileContainerElement = document.querySelector("#billingInfo-container");
    let editProfileContainerId = 'billingInfo-container';

    // Demo fallback
    if (!editProfileContainerElement) {
        editProfileContainerId = 'edit_profile_placeholder';
    }

    // Get the parent container
    // const parentContainer = element.parentElement.parentElement.parentElement.parentElement;
    const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

    // Get the id of the element
    const elementToEditTemplateId = parentContainer.getAttribute("data-array-template-id");

    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-update-addresses-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId, // <-- if we omit this property the screen it's shown in a pop-up
        context: {
            action: 'edit',
            id: elementToEditTemplateId, // <-- give the id to the context, so we can use it later to show only this one
        }
    });
}
function editVehicleWithRaaS(element, index) {

    // Look for the container where the screen will be shown
    const editProfileContainerElement = document.querySelector("#vehiclesInfo-container");
    let editProfileContainerId = 'vehiclesInfo-container';
    if (!editProfileContainerElement) {
        editProfileContainerId = 'edit_profile_placeholder';
    }

    // Get the parent container
    // const parentContainer = element.parentElement.parentElement.parentElement.parentElement.parentElement;
    const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

    
    // Get the id of the element
    const elementToEditTemplateId = parentContainer.getAttribute("data-array-template-id");

    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-update-vehicles-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId, // <-- if we omit this property the screen it's shown in a pop-up
        context: {
            action: 'edit',
            id: elementToEditTemplateId, // <-- give the id to the context, so we can use it later to show only this one
        }
    });
}

// Delete
function deleteAddressWithRaaS(element, index) {
    
    // Updating entry in array
    const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

        // Get the id of the element
        const elementToEditTemplateId = parentContainer.getAttribute("data-array-template-id");
        const elementToDeleteIndex = Number(elementToEditTemplateId.slice(-1));

        // Remove the entry from the array
        window.__USER_ADDRESSES = window.__USER_ADDRESSES.filter(function (address, index) {
            return index !== elementToDeleteIndex;
        }
        );

        // If it remains only with one address, make it the main one
        if (window.__USER_ADDRESSES.length === 1) {
            window.__USER_ADDRESSES[0].isMainAddress = true;

            const checkMainAddressElement = document.querySelector(".main-address-checkbox input");
            checkMainAddressElement.checked = true;

        }

        // If there are no main addresses, add it to the first one
        if (window.__USER_ADDRESSES.length > 0 && !window.__USER_ADDRESSES.some(function (address) { return address.isMainAddress; })) {
            window.__USER_ADDRESSES[0].isMainAddress = true;

            const checkMainAddressElement = document.querySelector(".main-address-checkbox input");
            checkMainAddressElement.checked = true;

        }


        // Show the 'add' button again
        const addAddressButton = document.querySelector('#gigya-view-addresses-screen .add-button');

        if (addAddressButton) {
            addAddressButton.parentElement.classList.remove('is-hidden');
        }

        // Call setAccountInfo with the new array of addresses
        gigya.accounts.setAccountInfo({ data: { addresses: window.__USER_ADDRESSES }, callback: refreshViewScreen });



    function refreshViewScreen(event) {

        // cdcLog(event);
        
        // Reload the screen
        viewAddressesWithRaaS();

        // TODO: Remove this code when the bug is fixed

        // // Updating entry in array
        // const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

        // // Remove the element from the DOM
        // parentContainer.remove();
        // // If it remains only with one address, make it the main one
        // if (window.__USER_ADDRESSES.length === 1) {

        //     const checkMainAddressElement = document.querySelector(".main-address-checkbox input");
        //     checkMainAddressElement.checked = true;

        // }
    }

}
function deleteVehicleWithRaaS(element, index) {


        // Updating entry in array
        const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

        // Get the id of the element
        const elementToEditTemplateId = parentContainer.getAttribute("data-array-template-id");
        const elementToDeleteIndex = Number(elementToEditTemplateId.slice(-1));

        // Remove the entry from the array
        window.__USER_VEHICLES = window.__USER_VEHICLES.filter(function (vehicle, index) {
            return index !== elementToDeleteIndex;
        }
        );

        // Show the 'add' button again
        const addVehicleButton = document.querySelector('#gigya-view-vehicles-screen .add-button');

        if (addVehicleButton) {
            addVehicleButton.parentElement.classList.remove('is-hidden');
        }

        // Call setAccountInfo with the new array of vehicles
        gigya.accounts.setAccountInfo({ data: { vehicles: window.__USER_VEHICLES }, callback: refreshViewScreen });


    function refreshViewScreen(event) {

        // cdcLog(event);

    
        // Reload the screen
        viewVehiclesWithRaaS();
    

        // // Updating entry in array
        // const parentContainer = document.querySelectorAll("[data-array-template-id]")[index];

        // // Remove the element from the DOM
        // parentContainer.remove();

        // // If it remains only with one vehicle, make it the main one
        // if (window.__USER_VEHICLES.length === 1) {

        //     const checkMainVehicleElement = document.querySelector(".main-vehicle-checkbox input");
        //     checkMainVehicleElement.checked = true;

        // }
    }

}

// View
function viewVehiclesWithRaaS () {
  // Look for the container where the screen will be shown
  const editProfileContainerElement = document.querySelector("#vehiclesInfo-container");
  let editProfileContainerId = 'vehiclesInfo-container';

  if (!editProfileContainerElement) {
      editProfileContainerId = 'edit_profile_placeholder';
  }


    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-view-vehicles-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId,
        context: {
            action: 'view',
        }
    });
}
function viewAddressesWithRaaS() {

    // Look for the container where the screen will be shown
    const editProfileContainerElement = document.querySelector("#billingInfo-container");

    let editProfileContainerId = 'billingInfo-container';
    if (!editProfileContainerElement) {
        editProfileContainerId = 'edit_profile_placeholder';
    }
    /* Launch Screenset */
    gigya.accounts.showScreenSet({
        screenSet: `Aena-ProfileUpdate`,
        startScreen: 'gigya-view-addresses-screen',
        lang: getCurrentLocale(),
        containerID: editProfileContainerId, // <-- if we omit this property the screen it's shown in a pop-up
        context: {
            action: 'view',
        }
        // Some sample events..
        // onBeforeSubmit,
        // onSubmit,
        // onAfterSubmit,
    });
}


// Init functions for addresses
function initChecksForAddressesWithRaaS() {

    // Enable edit and delete links
    const mainAddressCheckboxes = document.querySelectorAll("#gigya-view-addresses-screen .main-address-checkbox input");

    // Iterate over edit links and assign a click event with an index
    mainAddressCheckboxes.forEach((checkbox, index) => {
        checkbox.addEventListener('click', function (event) {

            // If the address is the main one, don't allow to uncheck it
            if (window.__USER_ADDRESSES[index].isMainAddress) {
                checkbox.checked = true;
            } else {
                updateMainAddressWithRaaS(event.target, index);
            }
        });
    });
}
function initLinksForAddressesWithRaaS(event) {

    // Enable edit and delete links
    
    const editLink = document.querySelectorAll("#gigya-view-addresses-screen .edit-address");
    const deleteLink = document.querySelectorAll("#gigya-view-addresses-screen .delete-address");


    // Iterate over edit links and assign a click event with an index
    editLink.forEach((link, index) => {
        link.addEventListener('click', function (event) {
            editAddressWithRaaS(event.target, index);
        });
    });

    // Iterate over delete links and assign a click event with an index
    deleteLink.forEach((link, index) => {
        link.addEventListener('click', function (clickEvent) {
            // Show a confirm dialog
            showConfirmFor('address', clickEvent, event, index);
        });
    });
}
async function initNewAddressWithRaaSScreen(event) {
   
    const action = event.context ? event.context.action : 'add';
    // Check context 
    if (action === 'add') {
        // show only new address formulary
        showOnlyNewAddressForm(event);
    }
    if (action === 'edit') {
        showOnlyEditAddressForm(event.context);
    }

    initAddressProvinceSelectors(event);
}
async function initViewAddressesWithRaaSScreen(event) {
    

    // Enable edit and delete links
    initLinksForAddressesWithRaaS(event);
    initChecksForAddressesWithRaaS();

    // Check if there is a main address
    checkMainAddress(event);

    // Initialize summary boxes (wait to finish that to apply the next changes)
    await initializeSummaryBoxes();

    // Initialize the province and the country objects and repaint html elements
    turnProvinceCodesIntoNames(event);
    
    // Not used at the moment as it is not internationalized yet
    // turnCountryCodesIntoNames(event);


}

// Init functions for vehicles
function initLinksForVehiclesWithRaaS(event) {

    // Enable edit and delete links
    const editLink = document.querySelectorAll("#gigya-view-vehicles-screen .edit-vehicle");
    const deleteLink = document.querySelectorAll("#gigya-view-vehicles-screen .delete-vehicle");


    // Iterate over edit links and assign a click event with an index
    editLink.forEach((link, index) => {
        link.addEventListener('click', function (event) {
            editVehicleWithRaaS(event.target, index);
        });
    });

    // Iterate over delete links and assign a click event with an index
    deleteLink.forEach((link, index) => {
        link.addEventListener('click', function (clickEvent) {
            // Show a confirm dialog
            showConfirmFor('vehicle', clickEvent, event, index);
        });
    });
}
function initNewVehicleWithRaaSScreen(event) {

    // Check context 
    if (event.context.action === 'add') {
        // show only new address formulary
        showOnlyNewVehicleForm(event);
    }
    if (event.context.action === 'edit') {
        showOnlyEditVehicleForm(event.context);
    }
}
function initViewVehiclesWithRaaSScreen(event) {

    // Enable edit and delete links
    initLinksForVehiclesWithRaaS(event);

    // Store the getAccounInfo information in window to be used later (:S, no other way to store vehicles between the load and a ulterior setAccountInfo)
    const currentVehiclesArray = event.data.vehicles;

    // Store vehicles in window object
    window.__USER_VEHICLES = currentVehiclesArray;

    // If array size is 0, hide the empty entry from the screen
    if (!currentVehiclesArray || currentVehiclesArray.length === 0) {
        const emptyVehicleEntry = document.querySelector('#gigya-view-vehicles-screen .gigya-array-template');
        if (emptyVehicleEntry) {
            emptyVehicleEntry.classList.add('is-hidden');
        }
    }

    // Hide add button if there are 3 vehicles
    if (currentVehiclesArray.length > 2) {
        const addVehiclesButton = document.querySelector('#gigya-view-vehicles-screen .add-button');
        addVehiclesButton.parentElement.classList.add('is-hidden');
    }
}

// Show only functions
function showOnlyNewAddressForm(event) {

    // Check if the global array it's empty. If yes, show the position 0 in the array. If yes, click the hidden button to add the new form.

    if (window.__USER_ADDRESSES && window.__USER_ADDRESSES.length > 0) {
        // Look for the Add button and click it
        const addAddressButton = document.querySelector(".gigya-array-add-btn");

        // If exists, click it 
        if (addAddressButton) {
            addAddressButton.click();
        }

        // Hide the rest
        setTimeout(function () {

            // Then, hide all the rest of the elements in the array list
            const giygaArrayManagerTemplates = document.querySelectorAll('#gigya-update-addresses-screen .gigya-array-template');

            // If exists, hide all but last
            if (giygaArrayManagerTemplates) {
                // Hide all but last
                giygaArrayManagerTemplates.forEach((template, index) => {
                    if (index !== giygaArrayManagerTemplates.length - 1) {
                        template.classList.add('is-hidden');
                    }
                });
            }

        }, 1);
    } else {
        // If the array it's empty, show the first position in the array
        // cdcLog('show 0');

    }
}
function showOnlyNewVehicleForm(event) {

    // Check if the global array it's empty. If yes, show the position 0 in the array. If yes, click the hidden button to add the new form.

    if (window.__USER_VEHICLES && window.__USER_VEHICLES.length > 0) {

        // Look for the Add button and click it
        const addVehiclesButton = document.querySelector(".gigya-array-add-btn");

        // If exists, click it 
        if (addVehiclesButton) {
            addVehiclesButton.click();
        }

        // Hide the rest
        setTimeout(function () {

            // Then, hide all the rest of the elements in the array list
            const giygaArrayManagerTemplates = document.querySelectorAll('#gigya-update-vehicles-screen .gigya-array-template');

            // If exists, hide all but last
            if (giygaArrayManagerTemplates) {
                // Hide all but last
                giygaArrayManagerTemplates.forEach((template, index) => {
                    if (index !== giygaArrayManagerTemplates.length - 1) {
                        template.classList.add('is-hidden');
                    }
                });
            }

        }, 1);
    } else {
        // If the array it's empty, show the first position in the array
        // cdcLog('show 0')
    }
}
function showOnlyEditAddressForm(context) {

    // Then, hide all the rest of the elements in the array list
    const giygaArrayManagerTemplates = document.querySelectorAll('#gigya-update-addresses-screen .gigya-array-template');

    // If exists, hide all but last
    if (giygaArrayManagerTemplates) {
        // Hide all but last
        giygaArrayManagerTemplates.forEach((template, index) => {

            // Take last two characters of context id
            const contextIdIndex = context.id.slice(-2);
            const templateIdIndex = template.getAttribute("data-array-template-id").slice(-2);
            if (contextIdIndex !== templateIdIndex) {
                template.classList.add('is-hidden');
            }
        });
    }


    // Change the default title with the id of the address
    const addressTitle = document.querySelectorAll('#gigya-view-addresses-screen .gigya-screen-caption');

    // If exists, change the title
    if (addressTitle) {
        addressTitle.forEach((title, index) => {
            title.innerHTML = `Address ${index + 1}`;
        });
    }

}
function showOnlyEditVehicleForm(context) {

    // Then, hide all the rest of the elements in the array list
    const giygaArrayManagerTemplates = document.querySelectorAll('#gigya-update-vehicles-screen .gigya-array-template');

    // If exists, hide all but last
    if (giygaArrayManagerTemplates) {
        // Hide all but last
        giygaArrayManagerTemplates.forEach((template, index) => {

            // Take last two characters of context id
            const contextIdIndex = context.id.slice(-2);
            const templateIdIndex = template.getAttribute("data-array-template-id").slice(-2);
            if (contextIdIndex !== templateIdIndex) {
                template.classList.add('is-hidden');
            }
        });
    }


    // Change the default title with the id of the address
    const captionTitle = document.querySelector(".gigya-screen-content .gigya-screenset-form .gigya-composite-control-header");

    // If exists, change the title
    if (captionTitle) {
        captionTitle.innerHTML = `Editar matrícula ${contextIdIndex}`;
    }

}

// other address functions
function makeMainAddressIfFirst(event) {
    // Take the addresses array
    const addresses = event.formModel.data.addresses;

    // If if's only one, mark 'isMainAddress' as true
    if (addresses.length === 1) {
        addresses[0].isMainAddress = true;
    }
}
function updateMainAddressWithRaaS(target, index) {

    // Put 'isMainAddress' to false except for the selected
    const addresses = window.__USER_ADDRESSES ? window.__USER_ADDRESSES : [];
    const checked = target.checked;

    addresses.forEach((address, i) => {
        if (i === index) {
            address.isMainAddress = checked;
        } else {
            address.isMainAddress = false;
        }
    });

    // uncheck all the checkboxes but the selected one
    const checkboxes = document.querySelectorAll('#gigya-view-addresses-screen .main-address-checkbox input');
    
    checkboxes.forEach((checkbox, i) => {
        // uncheck them all
        if (checkbox.checked && i !== index) {
            checkbox.checked = false;
        }
    });


    // Update the inter
    updateAddressesInterface(index, checked);

    // Call setAccountInfo with the new array of addresses
    // gigya.accounts.setAccountInfo({data: {addresses: addresses}, callback: () => updateAddressesInterface(index, checked)});
    gigya.accounts.setAccountInfo({ data: { addresses: addresses }, callback: (response) => { 

        // cdcLog('response', response);
    } });
}
function checkMainAddress(event) {
    // Store the getAccounInfo information in window to be used later (:S, no other way to store addresses between the load and a ulterior setAccountInfo)
    const currentAddressArray = event.data.addresses;

    // Store addresses in window object
    window.__USER_ADDRESSES = currentAddressArray;

    // TODO: Añadir a la doc de confluence
    
    // If array size is 0, hide the empty entry from the screen
    if (!currentAddressArray || currentAddressArray.length === 0) {
        const emptyAddress = document.querySelector('#gigya-view-addresses-screen .gigya-array-template');
        emptyAddress.classList.add('is-hidden');
    }

    // Add 'selected' class to active address

    // Get the active address
    const mainAddress = currentAddressArray ? currentAddressArray.find(address => address.isMainAddress === true) : null;

    if (mainAddress) {
        // Get active adress index
        const mainAddressIndex = currentAddressArray.indexOf(mainAddress);

        // Find the main address element in the DOM
        const mainAddressElementInDOM = document.querySelector("#gigya-view-addresses-screen .gigya-array-template[data-array-template-id$='" + mainAddressIndex + "']");
        mainAddressElementInDOM.classList.add('selected');

        // Hide add button if there are 3 addresses
        if (currentAddressArray.length > 2) {
            const addAddressButton = document.querySelector('#gigya-view-addresses-screen .add-button');
            addAddressButton.parentElement.classList.add('is-hidden');
        }
    }
}
async function initializeSummaryBoxes() {
    
    // Init 'address-summary-box' with the data of the form
    const initAddressSummaryBoxes = document.querySelectorAll(".address-summary-box");

    // Iterate initAddressSumaryBoxes to fill them with the correct data
    initAddressSummaryBoxes.forEach((addressSummaryBox, index) => {

        // Get the content of the address summary box
        let addressSummaryBoxContentUpdated = addressSummaryBox.innerHTML;

        // Get the address summary box elements
        const addressSummaryBoxElements = addressSummaryBox.parentElement.parentElement.parentElement.querySelectorAll(".input-like-label input");
        // Iterate addressSummaryBoxElements to fill them with the correct data
        addressSummaryBoxElements.forEach((addressSummaryBoxElement, index) => {
            // Get the address summary box element data
            const addressSummaryBoxElementData = addressSummaryBoxElement.getAttribute("name");
            const addressAttributeNameTrimmed = addressSummaryBoxElementData.split(".");
            const addressAttributeName = addressAttributeNameTrimmed[addressAttributeNameTrimmed.length - 1];
            const addressAttirbuteValue = addressSummaryBoxElement.value;

            // Substitute the placeholder in the addressSummaryBoxContentUpdated with the obtained value
            addressSummaryBoxContentUpdated = addressSummaryBoxContentUpdated.replace(`$${addressAttributeName}`, addressAttirbuteValue);

        });

        // Fill the address summary box with the updated content
        addressSummaryBox.innerHTML = addressSummaryBoxContentUpdated;

    });

}

// Popup functions
function showConfirmFor(type, clickEvent, event, index) {

    const element = clickEvent.target;

    // Get the translation for the popup
    const addressLabel = getMessagesTranslationFor ('LABEL_144302249166363600_LABEL', event);
    const carLabel =     getMessagesTranslationFor ('LABEL_156561078431958900_LABEL', event);
    const deleteButton = getMessagesTranslationFor ('LABEL_153563727320274180_LABEL', event);
    const cancelButton = getMessagesTranslationFor ('LABEL_114151928011857950_LABEL', event);

    const text = type === 'address' ? addressLabel : carLabel;
    // Create the html element
    const customConfirm = document.createElement('div');
    customConfirm.innerHTML = `    <!-- Deletion modal -->
    <div id="customConfirm" class="custom-confirm is-hiddenn">
        <div class="custom-confirm-content">
            <img class="delete-icon" src="${_aena_gig_raas_loginUrl}/assets/raas/icons/Papelera_T.svg" alt="Delete">
            <p class="question">${text}</p>
            <div class="buttons">
                <button class="confirm-no" id="confirmNo">${cancelButton}</button>
                <button class="confirm-yes" id="confirmYes">${deleteButton}</button>
            </div>
        </div>
    </div>`;
    document.body.appendChild(customConfirm);


    // Get the elements
    // const customConfirm = document.getElementById("customConfirm");
    const confirmYes = customConfirm.querySelector("#confirmYes");
    const confirmNo = customConfirm.querySelector("#confirmNo");

    confirmYes.addEventListener("click", () => {


        // Remove the modal
        customConfirm.remove();


        // cdcLog("User clicked Yes");

        if (type === 'address') {
            setTimeout(deleteAddressWithRaaS(element, index), 500);
        }
        if (type === 'vehicle') {
            setTimeout(deleteVehicleWithRaaS(element, index), 500);
        }

        return true;
    });

    confirmNo.addEventListener("click", () => {

        // Remove the modal
        customConfirm.remove();

        // Your 'no' action here
        // cdcLog("User clicked No");

        return false;
    });
}

// get the default functions
function getDefaultBillingAddress(addressArray) {
    const defaultBillingAddress = addressArray.find(address => address.isBillingAddress === true);
    return defaultBillingAddress;
}
function getDefaultShippingAddress(addressArray) {
    const defaultShippingAddress = addressArray.find(address => address.isShippingAddress === true);
    return defaultShippingAddress;
}
function getDefaultVehicle(vehicleArray) {
    const defaultVehicle = vehicleArray.find(vehicle => vehicle.isMainVehicle === true);
    return defaultVehicle;
}

// Countries (Not used in this release)
async function getCountriesFromAPI (event) {
    const apiKey = gigya.partnerSettings.ssoKey;
    // let lang = event && event.profile && event.profile.locale ? event.profile.locale.toUpperCase() : "ES";

   // const query = `select * from countries where data.lang="${lang}"`;
    const query = `select * from countries`;
   const data = `apiKey=${apiKey}&query=${query}`;

   const response = await fetch(`https://${_aena_gig_raas_applicationDomain}/api-url/getProvinces`, {
       method: "POST",
       headers: {
           "Content-Type": "application/x-www-form-urlencoded",
       },
       body: data,
   });

   if (response.ok) {
       const result = await response.json();
       // cdcLog(result);

         
       // Store the provinces in the window object
       if (!window.__ZT_COUNTRIES) {
           window.__ZT_COUNTRIES = {};
       }
       
       window.__ZT_COUNTRIES = result;
       
       // Store the data in localStorage
       localStorage.setItem('__ZT_COUNTRIES', JSON.stringify(window.__ZT_COUNTRIES));
       
       return result;
   } else {
       cdcError("Request failed with status:", response.status);
       return null;
   }
}
async function getCountries(event) {
   
    // Retrieve the data from localStorage and parse it as JSON
    const storedCountries = localStorage.getItem('__ZT_COUNTRIES');
    let countries = {};
    if (storedCountries) {
       countries = storedCountries ? JSON.parse(storedCountries) : null;
        window.__ZT_COUNTRIES = countries;
    } else {
        countries = await getCountriesFromAPI(event);
        window.__ZT_COUNTRIES = countries;
    }

    return countries;
}
function paintCountriesInBoxes (countriesArray, countryCode, countryHtmlElement, index) {

    const countries = countriesArray.results;

    // Get the address array using the index from the window object
    const currentAddressArray = window.__USER_ADDRESSES;
    const currentAddress = currentAddressArray ?  currentAddressArray[index] : null;

    // Get country code
    // const country = currentAddress ? currentAddress.country : null;

    // Get the country
    const countryObject = countries.find(country => country.data.code === countryCode);

    // Get the country name
    const countryName = countryObject ? countryObject.data.name : null;

    // Paint the country name in the html element
    countryHtmlElement.innerHTML = countryName ? countryName : countryCode;
}
async function turnCountryCodesIntoNames (event) {
    
    // Take countries from API call and paint them in the selector
    const countries = await getCountries(event);

    // Get the country selectors
    const countryCodeHtmlElements = document.querySelectorAll(".gigya-screen[id] form .country-element");
    
    // For each country code input, get the value and store from the API the provinces
    countryCodeHtmlElements.forEach((countryCodeHtmlElement, index) => {
        paintCountriesInBoxes(countries, countryCodeHtmlElement.innerHTML, countryCodeHtmlElement, index);
    });

}

// Provinces
function paintProvincesInSelector (provinces, countryCode, index, eventName) {

    const provincesForCountry = provinces[countryCode];

    // cdcLog(provinces);

    // cdcLog("paintProvincesInSelector");

    // Get the address array using the index from the window object
    const currentAddressArray = window.__USER_ADDRESSES;
    const currentAddress = currentAddressArray ?  currentAddressArray[index] : null;
    
    // Get the province
    const provinceCode = currentAddress ? currentAddress.province : null;


    const provinceSelectors = document.querySelectorAll(".gigya-screen[id] form .address-province-selector select");
    const provinceSelector = provinceSelectors[index];

    // Remove all options
    while (provinceSelector.firstChild) {
        provinceSelector.removeChild(provinceSelector.firstChild);
    }

    const provincesAsArray = provincesForCountry ? provincesForCountry.results : [];

    // If the province selector is empty, turn it into a text input
    if (provincesAsArray.length === 0) {
 
        // Hide the input text (to enable it in second phase)
        provinceSelector.parentElement.classList.add("is-invisible");
        // If options are empty, add a default one
        if (provincesAsArray.length === 0) {
            const option = document.createElement("option");
            option.value = "";
            option.innerHTML = "";
            provinceSelector.appendChild(option);
        }
    } else {

        // Sort the array alphabetically, taking into account the accents
        sortArrayAlphabetically(provincesAsArray);
        
        // Add the provinces
        provincesAsArray.forEach((province) => {
            const option = document.createElement("option");
            option.value = province.data.provinceCode;
            option.innerHTML = province.data.name;
            provinceSelector.appendChild(option);
        });

        // Set the selected option using the province code, if it exists, and matches with one of the options. If not, set it to the first option
        if (provinceCode) {
            const provinceOption = provinceSelector.querySelector(`option[value="${provinceCode}"]`);
            if (provinceOption) {
                provinceSelector.value = provinceCode;
            } else {
                provinceSelector.value = provinceSelector.options[0].value;
            }
        } else {
            provinceSelector.value = provinceSelector.options[0].value;
        }

        provinceSelector.options[0].disabled = false;
        // Hide the input text (to enable it in second phase)
        provinceSelector.parentElement.classList.remove("is-invisible");


    }
}
async function initAddressProvinceSelectors(event) {

    // Take provinces from API call and paint them in the selector
    const provinces = await getProvinces(event);
    
    // Get the province selectors
    const countryCodeInputs = document.querySelectorAll(".gigya-screen[id] form select[name*=country]");
    
    // For each country code input, get the value and store from the API the provinces
    countryCodeInputs.forEach((countryCodeInput, index) => {
        const countryCode = countryCodeInput.value;
        paintProvincesInSelector(provinces, countryCode, index, event.eventName);
    });

    // Repaint the selectors
    initializeSelectors();
}
async function getProvincesFromAPI(event, countryCode) {

    const apiKey = gigya.partnerSettings.ssoKey;
     let lang = event && event.profile && event.profile.locale ? event.profile.locale.toUpperCase() : "ES";

    const query = `select * from provinces where data.lang="${lang}" and data.countryCode="${countryCode}"`;
    const data = `apiKey=${apiKey}&query=${query}`;

    const response = await fetch(`https://${_aena_gig_raas_applicationDomain}/api-url/getProvinces`, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
    });

    if (response.ok) {
        const result = await response.json();
        // cdcLog(result);

          
        // Store the provinces in the window object
        if (!window.__ZT_PROVINCES) {
            window.__ZT_PROVINCES = {};
        }
        
        window.__ZT_PROVINCES[countryCode] = result;
        
        // Store the data in localStorage
        localStorage.setItem('__ZT_PROVINCES', JSON.stringify(window.__ZT_PROVINCES));
        
        return result;
    } else {
        cdcError("Request failed with status:", response.status);
        return null;
    }
}
async function getProvinces(event) {
   
    
    // Store the getAccounInfo information in window to be used later (:S, no other way to store addresses between the load and a ulterior setAccountInfo)
    const currentAddressArray = event && event.data && event.data.addresses ? event.data.addresses : [];
    const currentAddressArrayLength = currentAddressArray.length;
    let countryCodes = currentAddressArray.map(address => address.country);

    // If country codes empty, default to all the countries
    if (countryCodes.length === 0) {
        // Get the country codes from the country selector
        const countrySelectors = document.querySelectorAll(".gigya-screen[id] form select[name*=country]");
        countryCodes = Array.from(countrySelectors).map(countrySelector => countrySelector.value);
    }

    // Add country code "ES" if not present, as it is the default country
    if (!countryCodes.includes("ES")) {
        countryCodes.push("ES");
    }
    
    const provinces = {};
    const lang = event && event.profile && event.profile.locale ? event.profile.locale.toUpperCase() : "ES";
    // Retrieve the data from localStorage and parse it as JSON
    const storedProvinces = localStorage.getItem('__ZT_PROVINCES');
    if (storedProvinces) {
        const storedProvincesAsJSON = storedProvinces ? JSON.parse(storedProvinces) : null;
        window.__ZT_PROVINCES = storedProvincesAsJSON;
    }
    for (const countryCode of countryCodes) {

        if (window.__ZT_PROVINCES && window.__ZT_PROVINCES[countryCode]) {
            provinces[countryCode] = window.__ZT_PROVINCES[countryCode];
        } else {
            cdcLog("getProvinces for lang " + lang + " and countryCode " + countryCode);
            provinces[countryCode] = await getProvincesFromAPI(event, countryCode);
        }
    }

    return provinces;
}
function paintProvincesInBoxes (provincesByCountryArray, countryCode, provinceHtmlElement, index) {

    const provinces = provincesByCountryArray[countryCode];

    // cdcLog("paintProvincesInBoxes");

    // Get the address array using the index from the window object
    const currentAddressArray = window.__USER_ADDRESSES;
    const currentAddress = currentAddressArray ?  currentAddressArray[index] : null;

    // Get province code
    const provinceCode = currentAddress ? currentAddress.province : null;

    // Get the province
    const province = provinces ? provinces.results.find(province => province.data.provinceCode === provinceCode) : null;

    // Get the province name
    const provinceName = province ? province.data.name : null;

    // Paint the province name in the html element
    provinceHtmlElement.innerHTML = provinceName ? provinceName : provinceCode;
}
async function turnProvinceCodesIntoNames (event) {
    
    // Take provinces from API call and paint them in the selector
    const provinces = await getProvinces(event);

    
    // Get the province selectors
    const provinceCodes = document.querySelectorAll(".gigya-screen[id] form .province-element");
    
    const currentAddressArray = event.data.addresses;
    const countryCodes = currentAddressArray ? currentAddressArray.map(address => address.country) : [];
    
    // For each country code input, get the value and store from the API the provinces
    provinceCodes.forEach((provinceCode, index) => {
        paintProvincesInBoxes(provinces,countryCodes[index], provinceCode, index);
    });

}

async function updateProvincesSelector(event, field, countryCode) {

    // Count addresses 
    const currentAddressArray = document.querySelectorAll(".gigya-screen[id] form .gigya-array-template");
    const currentAddressArrayLength = currentAddressArray.length - 1;  

    // Get the index
    const currentCountryId = event.context.id;
    const lastChar = currentCountryId ? currentCountryId[currentCountryId.length - 1] : null;
    const index = lastChar ? parseInt(lastChar) : currentAddressArrayLength;
   
    // Get the event name
    const eventName = event.eventName;
    
    // Get the provinces for the selected country
    const provinces = await getProvinces(event);
    const provincesForCountry = provinces[countryCode];

    // Check if the provinces are available for the country
    if (provincesForCountry && provincesForCountry.results && provincesForCountry.results.length > 0) {
        // Repaint the selector
        paintProvincesInSelector(provinces, countryCode, index, eventName);
    } else {

        // Check if there are results for the country
        if (provincesForCountry && provincesForCountry.results && provincesForCountry.results.length === 0) {
            // Repaint the selector with no results
            paintProvincesInSelector([], countryCode, index, eventName);
        } else {

            // Provinces are not available for the country, so ask for them with the API

            // Get the provinces from the API
            const provincesFromAPI = await getProvincesFromAPI(event, countryCode);
           
            // Repaint the selector
            paintProvincesInSelector(provincesFromAPI, countryCode, index, eventName);
        }
    }

    // Reload the selector
    initializeSelectors();
}
/** *****************************************************/
//          05. Other Gigya Screenset Functions
/** *****************************************************/

/**
 * This function shows the account verification email screen.
 */
function showVerificationEmailScreen(event) {

    // Setting containerID 
    var containerID = event.instanceID === 'screenSet' ? null : event.instanceID;

    var screensetName = '';

    // Switcher between demo and real site :S
    try {
        screensetName = window.config.raas_prefix;
    } catch (e) {
        screensetName = 'Aena';
    }

    // Getting the email from the event
    const email = event.response.requestParams.email;
    // Calling gigya verification email screenset
    gigya.accounts.showScreenSet({
        screenSet: `${screensetName}-RegistrationLogin`,
        startScreen: "gigya-verification-sent-screen",
        lang: getCurrentLocale(),
        context: {
            email: email
        },
        containerID
    });
}

/**
 * This function shows the account verification pending screen.
 */
function showVerificationPendingScreen(event, containerID) {

    var screensetName = '';

    // Switcher between demo and real site :S
    try {
        screensetName = window.config.raas_prefix;
    } catch (e) {
        screensetName = 'Aena';
    }

    // Getting the email from the event
    const email = event.response.requestParams.email;
    const context = {...event.context, ...{email: email, regToken: event.response.regToken}}
    // Calling gigya verification email screenset
    gigya.accounts.showScreenSet({
        screenSet: `${screensetName}-RegistrationLogin`,
        startScreen: "gigya-verification-pending-screen",
        lang: getCurrentLocale(),
        context: context,
        containerID
    });
}

/** *
 * This function shows the reset password email screen.
 */
function showSuccessForgotPasswordEmailScreen(event) {


    // Setting containerID 
    var containerID = event.instanceID === 'screenSet' ? null : event.instanceID;

    var screensetName = '';

    try {
        screensetName = window.config.raas_prefix;
    } catch (e) {
        screensetName = 'Aena';
    }

    // Calling gigya password success screenset
    gigya.accounts.showScreenSet({
        screenSet: `${screensetName}-RegistrationLogin`,
        startScreen: "gigya-forgot-password-success-screen",
        lang: getCurrentLocale(),
        context: {
            email: event.response.requestParams.loginID
        },
        containerID
    });

}
/**
 * This function opens the register screen.
 */
function openRegisterScreen() {
    cdcEventsLog("Opening Register Screen");
    // Open registration screen
    gigya.accounts.showScreenSet({ screenSet: "Aena-RegistrationLogin", startScreen: "gigya-register-screen" });
}



/** **************************************************/
//  10. Harvesting functions
/** **************************************************/
/**
 * This function generates a random callID for the harvesting mitigations.
 * @returns {string} - a random callID
 */
function getCallId() {
    // Get Random hex string for call id
    const randomCallId = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    return randomCallId;
}
/**
 * This function redefines the isAvailableLoginID call as part of the harvesting mitigations.
 */
function redefineIsAvailableLoginId() {
    /**
     * desc
     * @date 2022-08-02
     * @param {params} params
     */
    gigya.accounts.isAvailableLoginID = function (params) {
        return {
            apiVersion: 2,
            callId: getCallId(),
            context: undefined,
            errorCode: 403026,
            errorDetails: "",
            errorMessage: "Unauthorized access error",
            operation: "/accounts.isAvailableLoginID",
            loginID: params.loginID ? params.loginID : undefined,
            status: "FAIL",
            statusMessage: "Unauthorized access error",
            time: new Date().toISOString(),
        }
    }
}
/**
 * This function redefines the getConflictingAccount call as part of the harvesting mitigations.
 */
function redefineGetConflictingAccount() {
    /**
     * desc
     * @date 2022-08-02
     * @param {params} params
     */
    /*gigya.accounts.getConflictingAccount = function (params) {
        return {
            apiVersion: 2,
            callId: getCallId(),
            context: undefined,
            errorCode: 403007,
            errorDetails: 'Invalid namespace &#39;accounts&#39; or method &#3…do not have the required permissions to call it. ',
            errorMessage: 'Permission denied',
            operation: "/accounts.getConflictingAccount",
            status: "FAIL",
            statusMessage: "Permission denied",
            time: new Date().toISOString(),
        }
    }*/
}
/**
 * This function waits for the timeInMS time and then continues the execution.
 * @param {number} timeInMs - the time in milliseconds
 */
function waitFor(timeInMs) {
    /**
     * desc
     * @date 2022-08-02
     * @param {ms} ms
     */
    const syncWait = ms => {
        const end = Date.now() + ms
        while (Date.now() < end) continue
    }

    cdcLog('waiting...')
    syncWait(timeInMs)
    cdcLog('done.')
}
/** **************************************************/
//  11. Other functions
/** **************************************************/
/**
 * Add an id with the name of the screenset to the container popup div.
 */
function addIdsToScreenPopup(event) {

    // Add Id's to the screen if it's opened in popup mode
    const screensetID = event.currentScreen;
    if (screensetID) {
        const screen = document.getElementById(screensetID);
        const parentPopupScreen = screen.parentElement.parentElement.parentElement;
        parentPopupScreen ? parentPopupScreen.id = event.currentScreen + "_popup_container" : '';
    }
}

function storeRegTokenInLocalStorage(event) {

    // Get the regToken from the event
    const regToken = event.response.requestParams.regToken;

    // Store the regToken in the local storage
    localStorage.setItem('gig_regToken', regToken);
}

// Check if we have a parameter "email" in the variable "context" and if so, prefill the email field
function prefillEmailField(event) {

    // Get the email from the event
    const email = event.context && event.context.email ? event.context.email : null;

    // Check if we have an email in the context
    if (email) {

        // Fill the email field
        document.querySelector(".reg-email").innerHTML = email;
    }
}


function prefillFormWithUserData() {

    // Check if we have the token in the url
    const urlParams = new URLSearchParams(window.location.search);
    const gig_regToken = urlParams.get('gig_regToken');

    if (gig_regToken) {

        // Get the user data
        gigya.accounts.getAccountInfo({
            regToken: gig_regToken,
            callback: function (response) {

                cdcLog("User data from token", response);
                if (response.errorCode === 0) {
                    // Fill the form with the user data
                    prefillAccountProgressionHTMLFields(response);
                } else {
                    cdcLog("Error getting user data from token", response);

                    // Go back to the login screen. HARDCODED!!
                    window.location.href = "/lpc/";

                }
            }
        });
    }
}

function checkIfFormCanBeSubmitted() {

    cdcLog("Checking if form can be submitted...");
    // Check if we can submit the form
    const submitButton = document.querySelector("#gigya-login-form .gigya-input-submit");
    if (submitButton) {

        var usernameField = document.querySelector("#gigya-login-form .gigya-input-text[name=username]");
        var passwordField = document.querySelector("#gigya-login-form .gigya-input-password[name=password]");

        if (usernameField.value && passwordField.value) {
            submitButton.disabled = false;

            cdcLog("Form can be submitted");
        } else {
            submitButton.disabled = true;

            cdcLog("Form can't be submitted");
        }
    }
}

function prefillAccountProgressionHTMLFields(userData) {

    // Fill the form with the user data
    const firstNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.firstName']");
    const lastNameField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.lastName']");
    const emailField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='profile.email']");
    const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");
    const passwordField = document.querySelector(".gigya-screen[id] form .gigya-input-password[name='password']");
    // const phoneField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name=phone]");
    // const phonePrefixField = document.querySelector(".gigya-screen[id] form .gigya-input-select[name=phonePrefix]");

    firstNameField.value = userData.profile.firstName;
    lastNameField.value = userData.profile.lastName;
    emailField.value = userData.profile.email;
    emailConfirmField.value = userData.profile.email;
    passwordField.value = '';
    // phoneField.value = userData.phone;
    // phonePrefixField.value = userData.phonePrefix;

    // Check if we can submit the form
    checkIfFormCanBeSubmitted();

}

function initializeCountrySelector() {

    const ztPaises = [
        { codPais: "AF", pais: "Afganistán" },
        { codPais: "AL", pais: "Albania" },
        { codPais: "DE", pais: "Alemania" },
        { codPais: "AD", pais: "Andorra" },
        { codPais: "AO", pais: "Angola" },
        { codPais: "AI", pais: "Anguilla" },
        { codPais: "AG", pais: "Antigua/Barbuda" },
        { codPais: "AQ", pais: "Antártida" },
        { codPais: "SA", pais: "Arabia Saudí" },
        { codPais: "DZ", pais: "Argelia" },
        { codPais: "AR", pais: "Argentina" },
        { codPais: "AM", pais: "Armenia" },
        { codPais: "AW", pais: "Aruba" },
        { codPais: "AU", pais: "Australia" },
        { codPais: "AT", pais: "Austria" },
        { codPais: "AZ", pais: "Azerbaiyán" },
        { codPais: "BS", pais: "Bahamas" },
        { codPais: "BH", pais: "Bahráin" },
        { codPais: "BD", pais: "Bangladesh" },
        { codPais: "BB", pais: "Barbados" },
        { codPais: "BZ", pais: "Belice" },
        { codPais: "BJ", pais: "Benín" },
        { codPais: "BM", pais: "Bermudas" },
        { codPais: "BY", pais: "Bielorrusia" },
        { codPais: "BO", pais: "Bolivia" },
        { codPais: "BQ", pais: "Bonaire, Saba" },
        { codPais: "BA", pais: "Bosnia-Herz." },
        { codPais: "BW", pais: "Botsuana" },
        { codPais: "BR", pais: "Brasil" },
        { codPais: "BN", pais: "Brunéi" },
        { codPais: "BG", pais: "Bulgaria" },
        { codPais: "BF", pais: "Burkina Faso" },
        { codPais: "BI", pais: "Burundi" },
        { codPais: "BT", pais: "Bután" },
        { codPais: "BE", pais: "Bélgica" },
        { codPais: "CV", pais: "Cabo Verde" },
        { codPais: "KH", pais: "Camboya" },
        { codPais: "CM", pais: "Camerún" },
        { codPais: "CA", pais: "Canadá" },
        { codPais: "TD", pais: "Chad" },
        { codPais: "CL", pais: "Chile" },
        { codPais: "CN", pais: "China" },
        { codPais: "CY", pais: "Chipre" },
        { codPais: "VA", pais: "Ciudad Vaticano" },
        { codPais: "CO", pais: "Colombia" },
        { codPais: "KM", pais: "Comoras" },
        { codPais: "KP", pais: "Corea del Norte" },
        { codPais: "KR", pais: "Corea del Sur" },
        { codPais: "CR", pais: "Costa Rica" },
        { codPais: "CI", pais: "Costa de Marfil" },
        { codPais: "HR", pais: "Croacia" },
        { codPais: "CU", pais: "Cuba" },
        { codPais: "CW", pais: "Curaçao" },
        { codPais: "DK", pais: "Dinamarca" },
        { codPais: "DM", pais: "Dominica" },
        { codPais: "AE", pais: "E.A.U." },
        { codPais: "US", pais: "EE.UU." },
        { codPais: "EC", pais: "Ecuador" },
        { codPais: "EG", pais: "Egipto" },
        { codPais: "SV", pais: "El Salvador" },
        { codPais: "ER", pais: "Eritrea" },
        { codPais: "SK", pais: "Eslovaquia" },
        { codPais: "SI", pais: "Eslovenia" },
        { codPais: "ES", pais: "España" },
        { codPais: "EE", pais: "Estonia" },
        { codPais: "ET", pais: "Etiopía" },
        { codPais: "RU", pais: "Federación Rusa" },
        { codPais: "PH", pais: "Filipinas" },
        { codPais: "FI", pais: "Finlandia" },
        { codPais: "FJ", pais: "Fiyi" },
        { codPais: "FR", pais: "Francia" },
        { codPais: "TF", pais: "French S.Territ" },
        { codPais: "GA", pais: "Gabón" },
        { codPais: "GM", pais: "Gambia" },
        { codPais: "GE", pais: "Georgia" },
        { codPais: "GH", pais: "Ghana" },
        { codPais: "GI", pais: "Gibraltar" },
        { codPais: "GD", pais: "Granada" },
        { codPais: "GR", pais: "Grecia" },
        { codPais: "GL", pais: "Groenlandia" },
        { codPais: "GP", pais: "Guadalupe" },
        { codPais: "GU", pais: "Guam" },
        { codPais: "GT", pais: "Guatemala" },
        { codPais: "GF", pais: "Guayana Franc." },
        { codPais: "GG", pais: "Guernsey" },
        { codPais: "GN", pais: "Guinea" },
        { codPais: "GQ", pais: "Guinea Ecuator." },
        { codPais: "GW", pais: "Guinea-Bissau" },
        { codPais: "GY", pais: "Guyana" },
        { codPais: "HT", pais: "Haití" },
        { codPais: "HN", pais: "Honduras" },
        { codPais: "HK", pais: "Hong Kong" },
        { codPais: "HU", pais: "Hungría" },
        { codPais: "IN", pais: "India" },
        { codPais: "ID", pais: "Indonesia" },
        { codPais: "IQ", pais: "Iraq" },
        { codPais: "IE", pais: "Irlanda" },
        { codPais: "IR", pais: "Irán" },
        { codPais: "HM", pais: "Is.Heard/Mcdon." },
        { codPais: "VI", pais: "Is.Vírgenes USA" },
        { codPais: "MP", pais: "Isl.Marianas N." },
        { codPais: "GS", pais: "Isl.S.Sandwich" },
        { codPais: "TC", pais: "Isl.Turcas y C." },
        { codPais: "VG", pais: "Isl.Vírgenes GB" },
        { codPais: "UM", pais: "IslMenAlejEEUU" },
        { codPais: "CX", pais: "Isla Christmas" },
        { codPais: "IM", pais: "Isla de Man" },
        { codPais: "IS", pais: "Islandia" },
        { codPais: "BV", pais: "Islas Bouvet" },
        { codPais: "KY", pais: "Islas Caimán" },
        { codPais: "CC", pais: "Islas Cocos" },
        { codPais: "CK", pais: "Islas Cook" },
        { codPais: "FO", pais: "Islas Feroe" },
        { codPais: "FK", pais: "Islas Malvinas" },
        { codPais: "NU", pais: "Islas Niue" },
        { codPais: "NF", pais: "Islas Norfolk" },
        { codPais: "PN", pais: "Islas Pitcairn" },
        { codPais: "SB", pais: "Islas Salomón" },
        { codPais: "TK", pais: "Islas Tokelau" },
        { codPais: "IL", pais: "Israel" },
        { codPais: "IT", pais: "Italia" },
        { codPais: "JM", pais: "Jamaica" },
        { codPais: "JP", pais: "Japón" },
        { codPais: "JE", pais: "Jersey" },
        { codPais: "JO", pais: "Jordania" },
        { codPais: "KZ", pais: "Kazajistán" },
        { codPais: "KE", pais: "Kenia" },
        { codPais: "KG", pais: "Kirguizistán" },
        { codPais: "KI", pais: "Kiribati" },
        { codPais: "KW", pais: "Kuwait" },
        { codPais: "LA", pais: "Laos" },
        { codPais: "LS", pais: "Lesoto" },
        { codPais: "LV", pais: "Letonia" },
        { codPais: "LR", pais: "Liberia" },
        { codPais: "LY", pais: "Libia" },
        { codPais: "LI", pais: "Liechtenstein" },
        { codPais: "LT", pais: "Lituania" },
        { codPais: "LU", pais: "Luxemburgo" },
        { codPais: "LB", pais: "Líbano" },
        { codPais: "MO", pais: "Macao" },
        { codPais: "MK", pais: "Macedonia" },
        { codPais: "MG", pais: "Madagascar" },
        { codPais: "MY", pais: "Malasia" },
        { codPais: "MW", pais: "Malaui" },
        { codPais: "MV", pais: "Maldivas" },
        { codPais: "MT", pais: "Malta" },
        { codPais: "ML", pais: "Malí" },
        { codPais: "MA", pais: "Marruecos" },
        { codPais: "MQ", pais: "Martinica" },
        { codPais: "MU", pais: "Mauricio (Isl.)" },
        { codPais: "MR", pais: "Mauritania" },
        { codPais: "YT", pais: "Mayotte" },
        { codPais: "FM", pais: "Micronesia" },
        { codPais: "MD", pais: "Moldavia" },
        { codPais: "MN", pais: "Mongolia" },
        { codPais: "ME", pais: "Montenegro" },
        { codPais: "MS", pais: "Montserrat" },
        { codPais: "MZ", pais: "Mozambique" },
        { codPais: "MM", pais: "Myanmar" },
        { codPais: "MX", pais: "México" },
        { codPais: "MC", pais: "Mónaco" },
        { codPais: "NA", pais: "Namibia" },
        { codPais: "NR", pais: "Nauru" },
        { codPais: "NP", pais: "Nepal" },
        { codPais: "NI", pais: "Nicaragua" },
        { codPais: "NG", pais: "Nigeria" },
        { codPais: "NO", pais: "Noruega" },
        { codPais: "NC", pais: "Nueva Caledonia" },
        { codPais: "NZ", pais: "Nueva Zelanda" },
        { codPais: "NE", pais: "Níger" },
        { codPais: "OM", pais: "Omán" },
        { codPais: "PK", pais: "Pakistán" },
        { codPais: "PW", pais: "Palaos" },
        { codPais: "PS", pais: "Palestina" },
        { codPais: "PA", pais: "Panamá" },
        { codPais: "PG", pais: "PapuaNvaGuinea" },
        { codPais: "PY", pais: "Paraguay" },
        { codPais: "NL", pais: "Países Bajos" },
        { codPais: "PE", pais: "Perú" },
        { codPais: "PF", pais: "Polinesia fran." },
        { codPais: "PL", pais: "Polonia" },
        { codPais: "PT", pais: "Portugal" },
        { codPais: "PR", pais: "Puerto Rico" },
        { codPais: "QA", pais: "Qatar" },
        { codPais: "GB", pais: "Reino Unido" },
        { codPais: "CF", pais: "Rep.Centroafr." },
        { codPais: "DO", pais: "Rep.Dominicana" },
        { codPais: "CZ", pais: "República Checa" },
        { codPais: "CG", pais: "República Congo" },
        { codPais: "CD", pais: "República Congo" },
        { codPais: "RE", pais: "Reunión" },
        { codPais: "RW", pais: "Ruanda" },
        { codPais: "RO", pais: "Rumanía" },
        { codPais: "KN", pais: "S.Cris.&amp; Nieves" },
        { codPais: "PM", pais: "S.Pedr.,Miquel." },
        { codPais: "ST", pais: "S.Tomé,Príncipe" },
        { codPais: "WS", pais: "Samoa Occident." },
        { codPais: "AS", pais: "Samoa americana" },
        { codPais: "SM", pais: "San Marino" },
        { codPais: "VC", pais: "San Vicente" },
        { codPais: "SH", pais: "Santa Helena" },
        { codPais: "LC", pais: "Santa Lucía" },
        { codPais: "SN", pais: "Senegal" },
        { codPais: "RS", pais: "Serbia" },
        { codPais: "CS", pais: "Serbia/Monten." },
        { codPais: "SC", pais: "Seychelles" },
        { codPais: "SL", pais: "Sierra Leona" },
        { codPais: "SG", pais: "Singapur" },
        { codPais: "SX", pais: "Sint Maarten" },
        { codPais: "SY", pais: "Siria" },
        { codPais: "SO", pais: "Somalia" },
        { codPais: "LK", pais: "Sri Lanka" },
        { codPais: "SZ", pais: "Suazilandia" },
        { codPais: "ZA", pais: "Sudáfrica" },
        { codPais: "SD", pais: "Sudán" },
        { codPais: "SS", pais: "Sudán del Sur" },
        { codPais: "SE", pais: "Suecia" },
        { codPais: "CH", pais: "Suiza" },
        { codPais: "SR", pais: "Surinam" },
        { codPais: "SJ", pais: "Svalbard" },
        { codPais: "EH", pais: "Sáhara occid." },
        { codPais: "TH", pais: "Tailandia" },
        { codPais: "TW", pais: "Taiwan" },
        { codPais: "TZ", pais: "Tanzania" },
        { codPais: "TJ", pais: "Tayikistán" },
        { codPais: "IO", pais: "Terr.br.Oc.Ind." },
        { codPais: "TL", pais: "Timor Oriental" },
        { codPais: "TP", pais: "Timor oriental" },
        { codPais: "TG", pais: "Togo" },
        { codPais: "TO", pais: "Tonga" },
        { codPais: "TT", pais: "TrinidadyTobago" },
        { codPais: "TM", pais: "Turkmenistán" },
        { codPais: "TR", pais: "Turquía" },
        { codPais: "TV", pais: "Tuvalu" },
        { codPais: "TN", pais: "Túnez" },
        { codPais: "UA", pais: "Ucrania" },
        { codPais: "UG", pais: "Uganda" },
        { codPais: "UY", pais: "Uruguay" },
        { codPais: "UZ", pais: "Uzbekistán" },
        { codPais: "VU", pais: "Vanuatu" },
        { codPais: "VE", pais: "Venezuela" },
        { codPais: "VN", pais: "Vietnam" },
        { codPais: "WF", pais: "Wallis,Futuna" },
        { codPais: "YE", pais: "Yemen" },
        { codPais: "DJ", pais: "Yibuti" },
        { codPais: "ZM", pais: "Zambia" },
        { codPais: "ZW", pais: "Zimbabue" },
        { codPais: "MH", pais: "de Isl.Marshall" }
    ];

    const countrySelector = document.querySelector(".gigya-screenset-form .aena-country-selector select");


    if (!countrySelector) {
        cdcLog("Country selector not found, skipping initialization");
        return;
    }


    // var myHeaders = new Headers();
    // myHeaders.append("4_YxyopV0TUPBCy9Rwpv8QMg","apiKey");
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    /*
    var urlencoded = new URLSearchParams();
    urlencoded.append("query", "select * from provinces where data.lang=\"ES\" and data.countryCode=\"ES\"");
    urlencoded.append("apiKey", gigya.thisScript.APIKey);
    var requestOptions = {
      method: 'POST',
      // headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    cdcLog("requestOptions", requestOptions);
    cdcLog ("requestHeaders", requestOptions.headers);
    fetch("https://aenatest.prod.apimanagement.eu10.hana.ondemand.com/ds/consulta", requestOptions)
      .then(response => response.text())
      .then(result => cdcLog(result))
      .catch(error => cdcLog('error: ' + error.message));
    */


    /*fetch ("https://aenatest.prod.apimanagement.eu10.hana.ondemand.com/Consulta/api/v1/Pais_CDC_dev", {headers: headers}).catch(error => {
        cdcLog("Error fetching countries: " + error);
    }).then(response => {
        return response.json();
    }).then(data => {
        cdcLog("Countries fetched: " + data);

        // Clear the selector
        countrySelector.innerHTML = "";


        // Populate the selector
        data.forEach(country => {
            const option = document.createElement("option");
            option.value = country.Codigo;
            option.text = country.Nombre;
            countrySelector.appendChild(option);
        });
    });*/
}
function removeHelpBoxIfPresent() {

    var helpBox = document.querySelector("#helpBox");

    // If present, remove
    if (helpBox) {
        helpBox.remove();
    }
}



function addNotInformedCommunicationsToFormData(formModel) {

    const allCommunications = [
        'communications_AenaClub',
        'communications_Parking',
        'communications_Terceros',
        'communications_VIP',
        'communications_MarketPlaces'
    ];

    allCommunications.forEach((field) => {

        if (!formModel[field]) {
            formModel[field] = false;
        }
    });

    // Append allCommunications to the form data
    // formModel = Object.assign(formModel, allCommunications);
    formModel = [...formModel, ...allCommunications];
    // cdcLog(formModel);

}


function checkIfEmailsAreTheSame(emailField, emailConfirmField) {
    let emailsAreTheSame = false;
    // Check if emails are the same
    if (emailField && emailConfirmField && emailField.value === emailConfirmField.value) {
        emailsAreTheSame = true;
    }
    return emailsAreTheSame;
}
function cleanEmailErrorValues() {
    const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");

    // Remove error class from email field
    emailConfirmField.classList.remove('gigya-error');

    // Remove emailConfirm error message
    const errorMessageSpan = emailConfirmField.parentElement.querySelector("span.gigya-error-msg");

    errorMessageSpan.innerHTML = "";
    errorMessageSpan.classList.remove("email-confirm-error-message");

}

function showEmailErrorValuesOnFields(event) {
    // Add error class to the confirmation email field
    const emailConfirmField = document.querySelector(".gigya-screen[id] form .gigya-input-text[name='local.emailConfirm']");
    emailConfirmField.classList.add("gigya-error");

    // Add error message
    const errorMessageSpan = emailConfirmField.parentElement.querySelector("span.gigya-error-msg");

    // Get appropiate error message (from translations)
    const errorMessage = getMessagesTranslationFor ('LABEL_17954789497038396_LABEL', event);
    errorMessageSpan.innerHTML = errorMessage;
    errorMessageSpan.classList.add("email-confirm-error-message");
}

/** *****************************************************/
/*          1. STRING/DATE HELPER FUNCTIONS             */
/** *****************************************************/

/**
 * Return a Hex Hash to be used later as a color from the incoming string
 * @param {string} string 
 * @returns {string} the hex hash for that string
 */
function stringToHex(string) {
    var hash = 0;
    if (string.length === 0) return hash;
    for (var i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = "#";
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
    }
    return color;
}
/**
 * Return a Hex Hash to be used later as a color from the incoming string, but softer a 33%
 * @param {string} string 
 * @returns {string} the hex hash for that string
 */
function stringToHexSoft(string) {
    return stringToHex(string) + "33";
}
/**
 * Capitalizes a string
 * @param  {string} s The incoming string
 * @returns {string}   The string capitalized
 */
function capitalize(s) {
    if (typeof s !== "string") {
        return "";
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
}
/**
 * Takes an ISO time and returns a string representing how long ago the date represents.
 * @param  {int} time Unix Time
 * @returns {string} prettifiedDate
 */
function prettyDate(time) {
    var date = new Date((time || "").replace(/-/g, "/").replace(/[TZ]/g, " "));
    date.setHours(date.getHours() + 1);
    var now = new Date();
    var diff = (now.getTime() - date.getTime()) / 1000;
    dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
        return "";
    }

    const prettifiedDate =
        (dayDiff === 0 &&
            ((diff < 60 && "just now") ||
                (diff < 120 && "1 minute ago") ||
                (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
                (diff < 7200 && "1 hour ago") ||
                (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
        (dayDiff === 1 && "Yesterday") ||
        (dayDiff < 7 && dayDiff + " days ago") ||
        (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago");
    return prettifiedDate;
}

function waitFor(timeInMs) {
    const syncWait = ms => {
        const end = Date.now() + ms
        while (Date.now() < end) continue
    }

    cdcLog('waiting...')
    syncWait(timeInMs)
    cdcLog('done.')
}

/** *****************************************************/
/*               2. GIGYA HELPER FUNCTIONS              */
/** *****************************************************/

/**
 * Gets the api key of the site
 * @returns {string} The api key
 */
function getApiKeyFromSite() {
    return gigya.thisScript.APIKey;
}
/**
 * Gets the datacenter of the site
 * @returns {string} The datacenter
 */
function getDatacenterFromSite() {
    return gigya.dataCenter;
}
/**
 * Standarizes the name for some social networks
 * @param  {string} provider The original provider name
 * @returns {string} The standarized name
 */
function sanitizeSocial(provider) {
    // Label identity provi0der
    var identityProviderLabel = query(".provider-label");
    var replaceChars = { ",": ", ", googleplus: "Google", "saml-": "saml-" };
    var identityProviderSanitized = provider.replace(
        /,|googleplus|saml-/g,
        function (match) {
            return replaceChars[match];
        }
    );

    var identityProviderSanitizedAndCapitalized = capitalize(
        identityProviderSanitized
    );
    if (identityProviderSanitized.indexOf("saml-") === 0) {
        return identityProviderSanitized;
    }
    return identityProviderSanitizedAndCapitalized;
}


/** *****************************************************/
/*                  4. SEARCH FUNCTIONS                 */
/** *****************************************************/
/**
 * Returns the value of the variable if exists into the query string in the url of the site.
 * @param {string} variable the variable to search
 * @returns {string} the value of the variable if found and null if not found
 */
function getFromQueryString(variable) {

    // Take query string and make object
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(variable)) {

        if (urlParams.get(variable) !== '') {
            return urlParams.get(variable);
        }
    }

}

/**
 *  It returns the url params of hte navigation bar
 * @returns {string} the url params
 */
function getQueryParamsAsString() {
    return window.location.search;
}
function getQueryParams() {
    var queryParams = {};
    var queryParamsAsString = window.location.search;
    if (queryParamsAsString) {
        queryParamsAsString = queryParamsAsString.replace("?", "");
        var queryParamsArray = queryParamsAsString.split("&");
        for (var i = 0; i < queryParamsArray.length; i++) {
            var param = queryParamsArray[i].split("=");
            queryParams[param[0]] = param[1];
        }
    }
    return queryParams;
}
/**
 * Gets the variable (if exists) from the local storage
 * @param {string} variable the name of the variable
 * @param {object} value the value of the variable
 */
function getFromLocalStorage(variable) {
    return localStorage.getItem(variable);
}
function getObjectFromSessionStorage(variable) {

    var parsedResult = null;

    // Get the item from the session storage
    var result = sessionStorage.getItem(variable);

    // If the result is not null, parse it
    if (result !== null) {
        try {
            parsedResult = JSON.parse(result);
        }
        catch (e) {
            cdcLog('Error parsing session storage item: ' + variable);
        }
    }
    return parsedResult;
}
/**
 * Sets the variable with the value in parameters into the local storage
 * @param {string} variable the name of the variable
 * @param {object} value the value of the variable
 */
function setInLocalStorage(variable, value) {
    localStorage.setItem(variable, value);
}
function setObjectInSessionStorage(variable, value) {

    // If the value is null, remove the item
    if (value === null) {
        sessionStorage.removeItem(variable);
    } else {
        sessionStorage.setItem(variable, JSON.stringify(value));
    }
}
function scrollToTop() {

    cdcLog('scrolling to top');
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

function scrollToElement(elementSelector, offsetY = 0) {
    
    var element = document.querySelector(elementSelector);

    if (!element) {
        cdcLog('Element not found. Scrolling to top');
        scrollToTop();
        return;
    }

    // Check if element is hidden. If yes, take the parent.
    if (element.offsetParent === null) {
        cdcLog('Element is hidden. Scrolling to parent');
        element = element.parentElement;
        offsetY = offsetY - 20;
    }

    cdcLog('scrolling to element ' + elementSelector);
    
    var bodyRect = document.body.getBoundingClientRect(),
        elementRect = element.getBoundingClientRect(),
        offsetPosition = elementRect.top - bodyRect.top - offsetY;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}


function storeScreenSetLanguage(event) {

    // Get the screen set data and store it in the session storage if it is not already stored
    const screensetID = event.screenSetID;
    // const currentScreensetLanguage = gigya.thisScript.lang && gigya.thisScript.lang.langCode ? gigya.thisScript.lang.langCode : 'en';
    const siteLang = getCurrentLocale();
    const translationsSessionVarName = `_aena_gig_raas_screensets_${screensetID}_translations_${siteLang}`;

    // Store the screenset data in the session storage
    const languageFromSessionStorage = getObjectFromSessionStorage(translationsSessionVarName);
    const IS_VALID_LANG_FROM_SESSION = languageFromSessionStorage && languageFromSessionStorage;
    if (!IS_VALID_LANG_FROM_SESSION && screensetID && siteLang) {

        // As a secure, we store first the info from the language taken from the gigya plugin screenset cache
        const currentScreenset = gigya.__screenSetPluginCache[Object.keys(gigya.__screenSetPluginCache)[0]];
        const currentScreensetLanguage = Object.keys(currentScreenset.translations)[0];
        if (currentScreensetLanguage === siteLang) {
            const translations = currentScreenset.translations[currentScreensetLanguage];
            // Store the translations in the session storage
            setObjectInSessionStorage(translationsSessionVarName, translations);
        } else {
            // Get the screen set data (if __screenSetPluginCache is not available, it will be retrieved from the server anyway), but this securizes the process
            gigya.accounts.getScreenSets({screenSetIDs:[screensetID], lang: siteLang, include:'translations', callback: function (result) {

                // Get the translations
                const translations = result.screenSets && result.screenSets[0] && result.screenSets[0].translations ? result.screenSets[0].translations[siteLang] : null;
                
                if (!translations) {
                    cdcLog('Error getting translations for screenset ' + screensetID + ' and language ' + siteLang);
                    return;
                }

                // Store the translations in the session storage
                setObjectInSessionStorage(translationsSessionVarName, translations);

            }});
        }


    } else {
        cdcLog('Screen set languages already stored for screenset ' + screensetID + ' and language ' + siteLang);    
    }
}


function showConditionsBoxIfInPopupMode(event) {

    cdcLog('showConditionsBoxIfInPopupMode');

    // Get user info from event
    const userInfo = event.response;

    // Check fields isActive, isVerified and isRegistered
    const isActive = userInfo.isActive;
    const isVerified = userInfo.isVerified;
    const isRegistered = userInfo.isRegistered;
    const hasMissingRequiredFields = userInfo.errorCode === 206001; // errorCode: 


    // If the user is active, verified and registered, show the conditions box (we are in popup mode)
    if (isActive && isVerified && isRegistered && hasMissingRequiredFields) {
        cdcLog('showing conditions box');
        showConditionsBox(event);
    } else {
        cdcLog('not showing conditions box');
        hideConditionsBox();
    }

}

function showConditionsBox(event) {

    // Get the conditions box content
    let privacyBoxContent = getMessagesTranslationFor('LABEL_159504538570610940_LABEL', event);

    // Substitute the link placeholder <a href="{{privacy_Aena_link}}"> with the link to the conditions
    const privacyLink = getPrivacyLink();
    const privacyBoxContentWithPlaceholders = privacyBoxContent.replace('<a href="{{privacy_Aena_link}}"', '<a href="' + privacyLink + '" target="_blank"');


    // Create a div with the conditions box
    const conditionsBox = document.createElement('div');
    conditionsBox.id = '__gigya_popup_conditionsBox';
    conditionsBox.innerHTML = `
        <div class="conditionsBox">
            <div class="conditionsBox__content">
            ${privacyBoxContentWithPlaceholders}
            </div>
        </div>`;


    // Look for .gigya-screen-dialog-main
    const screenDialogMain = document.querySelector('.gigya-screen-dialog-main .gigya-screen-dialog-inner');

    // Take the link to the conditions
    


    // If it exists, append the conditions box to it
    if (screenDialogMain) {
        setTimeout(function() {
        screenDialogMain.appendChild(conditionsBox);
        }, 100);
    } else {
        // We aren't in popup mode. (We are in CLP, hopefully)
        const screenDialogMain = document.querySelector('#screensetContainer');
        if (screenDialogMain) {
            setTimeout(function() {
            screenDialogMain.appendChild(conditionsBox);
            }, 100);
        } else {
            cdcLog('screenDialogMain not found, nor in CLP');
        }
    } 
}

function hideConditionsBox() {
    
    // Remove the conditions box
    const conditionsBox = document.getElementById('__gigya_popup_conditionsBox');
    if (conditionsBox) {
        conditionsBox.remove();
    }
}   
function getPrivacyLink () {

    // Get the current language
    const currentLanguage = getCurrentLocale();

    // Get the privacy link from the preferences without environment
    const privacyLink = _aena_gig_raas_loginUrl + "politica-privacidad?gig_lang=" + currentLanguage;

    return privacyLink;
}

function hideCommunicationsCheckboxes (event) {

    cdcLog('hideCommunicationsCheckboxes');
    // Take all communications checkboxes
    const communicationsCheckboxes = document.querySelectorAll('.gigya-screen[id] form .gigya-visible-when.gigya-container-enabled');

    // Hide them all
    communicationsCheckboxes.forEach(function(checkbox) {
        checkbox.style.display = 'none';
    });      
}

// Define a logging function
function cdcLog(message) {
    if (showLog) {

        var backgroundColor ="#a7c6e466";
            console.log(
                `%c LOG: %c--> ` + message + "%c at %s",
                `font-weight: bold; color: #333;background-color:${backgroundColor};`,
                "font-weight: normal;color:#aaa",
                "font-weight: bold;color:#333",
                new Date().toLocaleTimeString(),
            );
    }
}

// Define a event logging function
function cdcEventsLog(message) {
    if (showEventLog) {
        var backgroundColor ="#00800033";
            console.log(
                `%c EVENT: %c--> ` + message + "%c at %s",
                `font-weight: bold; color: #333;background-color:${backgroundColor};`,
                "font-weight: normal;color:#aaa",
                "font-weight: bold;color:#333",
                new Date().toLocaleTimeString(),
            );
    }
}

function cdcWarn (message) {
    if (showLog) {
        var backgroundColor ="#e4ae1f33";
            console.warn(
                `%c WARN: %c--> ` + message + "%c at %s",
                `font-weight: bold; color: #333;background-color:${backgroundColor};`,
                "font-weight: normal;color:#aaa",
                "font-weight: bold;color:#333",
                new Date().toLocaleTimeString(),
            );
    }
}

function cdcError (message) {
    if (showLog) {
        var backgroundColor ="#ff000033";

            console.error(
                `%c ERROR: %c--> ` + message + "%c at %s",
                `font-weight: bold; color: #333;background-color:${backgroundColor};`,
                "font-weight: normal;color:#aaa",
                "font-weight: bold;color:#333",
                new Date().toLocaleTimeString(),
            );
    }
}


function enableLogs () {
    showLog = true;
    showEventLog = true;
}

function disableLogs () {
    showLog = false;
    showEventLog = false;
}

function enableEventLogs () {
    showEventLog = true;
}

function disableEventLogs () {
    showEventLog = false;
}

function enableCDCLogs () {
    showLog = true;
}

function disableCDCLogs () {
    showLog = false;
}
// cdcLog("This is a regular log");
// cdcEventsLog("This is an event log");
function sortArrayAlphabetically (array) {
    array.sort((a,b) => { return a.data.name.localeCompare(b.data.name)});
}
/** *****************************************************/
//             11. CENTRAL LOGIN PAGE FUNCTIONS
/** *****************************************************/
/* global gigya */
function getOriginSite(context) {
    let originSite = window.location.origin;

    // If context exists, get the origin site from it
    if (context && context.originSite) {
        originSite = context.originSite;
    }

    // Take subdomain
    const subdomain = originSite.split('//')[1].split('.')[0];

    // If subdomain is not empty, return it
    if (subdomain) {

        switch (subdomain) {

            // Cambios de nombre 2 → to en los subdominios
            case 'clubclientedev':
            case 'clubclienteqa':
            case 'clubcliente':
                    originSite = 'clubCliente';
                break;

            case 'shoptofly':
            case 'shoptoflydev':
            case 'shoptoflyqa':
                originSite = 'shopToFly';
                break;

            case 'foodtofly':
            case 'foodtoflydev':
            case 'foodtoflyqa':
                originSite = 'foodToFly';
                break;

            case 'serviciosvipdev':
            case 'serviciosvipqa':
            case 'serviciosvip':
                originSite = 'serviciosVIP';
                break;
    
            case 'aenatravel':
            case 'traveldev':
            case 'travelqa':
                originSite = 'travel';
                break;

            case 'parkingdev':
            case 'parkingqa':
            case 'parking':
                originSite = 'parking';
                break;
        
            // Subdominios de la CLP
            case 'des-usuarios':
            case 'pre-usuarios':
            case 'pro-usuarios':
            case 'cis-usuarios':

            // ?? 
            case 'usuarios':
                originSite = 'clp';
                break;
            case 'freewifi':
            case 'freewifi-dev':
            case 'freewifi-pre':
                originSite = 'wifi'
                break;
            default:
                originSite = subdomain;
                break;
        }
    }

    return originSite;
}
function ssoAenaLogin(context) {

    // Get the origin site
    let originSite = getOriginSite(context);

    // Get the redirection URL from context if exists
    let redirectionURL = context && context.redirectionURL ? context.redirectionURL : null;
    let urlWifiRedirect = context && context.urlWifiRedirect ? context.urlWifiRedirect : null;
    let useChildContext = context && context.useChildContext ? context.useChildContext : null;
    let enabledProviders = context && context.enabledProviders ? context.enabledProviders : null;
    let socialLoginAuthFlow = context && context.authFlow ? context.authFlow : null;

    // Get Language from context if exists
    let lang = context && context.lang ? context.lang : null;

    // Check origin site. If it's clp, change it to clubcliente and add the redirectionURL to https://clubcliente.aena.es/
    if (originSite === 'clp') {
        originSite = 'clubcliente';
        redirectionURL = _aena_clubcliente_url;
    }

    // Log redirection URL and origin site
    console.log('- originSite: ', originSite);
    console.log('- redirectionURL: ', redirectionURL);

    // Set the params
    const params =
    {
        authFlow: 'redirect',
        context: {
            originSite: originSite,
            lang: lang  
        }
    };

    // If redirectionURL exists, add it to params
    if (redirectionURL) {
        params.redirectURL = redirectionURL;
    }

    if (urlWifiRedirect) {
        params.context.urlWifiRedirect = urlWifiRedirect;
    }

    if (useChildContext) {
        params.useChildContext = useChildContext;
    }

    if (enabledProviders) {
        params.context.enabledProviders = enabledProviders;
    }

    if (socialLoginAuthFlow) {
        params.context.socialLoginAuthFlow = socialLoginAuthFlow;
    }

    // Call the sso login
    gigya.sso.login(params);

}
function gotoSsoAenaLogin() {

    // Take the current language
    const lang = getCurrentLocale();

    // Call the sso login with the current language
    ssoAenaLogin({ lang: lang });
}
